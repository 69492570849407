import React, { Component } from "react";
import PropTypes from "prop-types";
import AddFile from "./AddFile";
import { genIDs } from "../../utils";

export default class SelectStopWords extends Component {
    static propTypes = {
        defaultsAndQuery: PropTypes.object.isRequired,
        updateQuery: PropTypes.func.isRequired,
    };

    static readFile(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = e => resolve(e.target.result);
            reader.onerror = e => reject(e);
            reader.readAsText(file);
        });
    }

    constructor(props) {
        super(props);
        this.state = { uniqueIds: genIDs("stopwords") };
    }

    render() {
        const { uniqueIds } = this.state;
        const { updateQuery, defaultsAndQuery } = this.props;

        return (
            <labels
                htmlFor={uniqueIds.stopwords}
            >
                Stop words
                <input
                    id={uniqueIds.stopwords}
                    type="text"
                    value={defaultsAndQuery.stopWords}
                    onChange={event => updateQuery("stopWords", event.target.value)}
                />
                <AddFile
                    addedFiles={files => {
                        SelectStopWords.readFile(files[0]).then(text => updateQuery("stopWords", text));
                    }}
                />
            </labels>
        );
    }
}
