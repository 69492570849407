import React, { Component } from "react";
import PropTypes from "prop-types";

import { genIDs } from "../../utils";

import searchPageStyles from "../index.module.css";

export default class SelectLinks extends Component {
    static propTypes = {
        defaultsAndQuery: PropTypes.object.isRequired,
        updateQuery: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { uniqueIds: genIDs("links") };
    }

    componentDidMount() {
        console.log("Registering listener1", this.target);


        const observer = new MutationObserver(() => {
            const { updateQuery } = this.props;
            console.log("mutation observer fired");
            updateQuery(
                "links",
                this.target.innerText,
            );
        });

        console.log("Registering listener2", this.target);
        observer.observe(this.target, { attributes: true, childList: true });
    }

    render() {
        const { uniqueIds } = this.state;

        return (
            // eslint-disable-next-line jsx-a11y/label-has-for,jsx-a11y/label-has-associated-control
            <label htmlFor={uniqueIds.links}>
                <h6 className={searchPageStyles.boxedTitle}>Links</h6>
                <div>
                    <small>One link per line</small>
                    <br />
                    <div
                        id={uniqueIds.links}
                        style={{
                            minHeight: "50px",
                            border: "solid black 1px",
                            maxHeight: "300px",
                            overflow: "auto",
                            marginTop: "10px",
                            backgroundColor: "white",
                        }}
                        ref={target => {
                            this.target = target;
                        }}
                        contentEditable
                    />
                </div>
            </label>
        );
    }
}
