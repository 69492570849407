/**
 * Created by macdja38 on 2017-05-31.
 */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import fetch from "isomorphic-fetch";
import "sweetalert/dist/sweetalert.css";
import consts from "../consts";
import "../main.module.css";
import TopicChartTable from "./Components/TopicChartTable";
import TopicChartScatterPlot from "./Components/TopicChartScatterPlot";
import ListOptimalModels from "./Components/OptimalTopicList";

class SearchPage extends Component {
    static propTypes = {
        ids: PropTypes.arrayOf(PropTypes.string).isRequired,
    };

    constructor() {
        super();
        this.state = {};
    }

    componentDidMount() {
        const { ids } = this.props;

        this.updateChart(ids);
    }

    updateChart(cacheIDs) {
        return fetch(`${consts.api}/analyze/check/bulk/`, {
            method: "POST",
            mode: "cors",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({ cacheIDs }),
        }).then((response) => {
            if (response.status === 200) {
                return response.json();
            }
            throw response.error;
        }).then((json) => {
            const { ids } = this.props;
            console.log(json);
            if (json.results.length < cacheIDs.length) {
                this.setState({ data: json.results, done: false });
                setTimeout(() => this.updateChart(ids), 2000);
            } else {
                this.setState({ data: json.results, done: true });
            }
        }).catch(console.error);
    }

    render() {
        const { done, data, redirect } = this.state;
        const { ids } = this.props;

        if (redirect) {
            return (<Redirect to={redirect} push />);
        }

        if (!data) {
            return <div>Loading...</div>;
        }

        return (
            <React.Fragment>
                <div>
                    {done
                        ? ""
                        : `Running Topic Models... ${data.length}/${ids.length}`}
                </div>
                <div>
                    <ListOptimalModels data={data} />
                </div>
                <div>
                    <TopicChartScatterPlot data={data} />
                </div>
                <div>
                    <TopicChartTable data={data} />
                </div>
            </React.Fragment>
        );
    }
}

export default SearchPage;
