/**
 * Created by macdja38 on 2017-06-02.
 */

import React, { Component } from "react";
import PropTypes from "prop-types";
import consts, { NUMBER_OF_KEYWORDS_TO_DISPLAY, issuesProp } from "../../consts";
import IssueSelector from "../IssueSelector";
import ArticleSelector from "../ArticleSelector";
import ArticleInIssueSelector from "../ArticleInIssueSelector";
import ArticleDisplay from "./Components/ArticleDisplay";
import BigTopicInfo from "./Components/BigTopicInfo";
import { round } from "../../utils";
import SmallArticleInfo from "./Components/SmallArticleInfo";

import { contextTable, tableBody } from "./index.module.css";
import { buttonToLink } from "../../Styles/buttons.module.css";
import { offsetTwentyRight } from "../../Styles/misc.module.css";

class ContextTable extends Component {
    static canDrillIntoTopic(articles) {
        return articles.length >= 20;
    }

    constructor(props) {
        super(props);
        this.state = {
            referenceMap: {},
        };
    }

    /**
     * Bubble topic selection up
     * @param {Object} topic
     */
    onTopicSelected(topic) {
        const { onNodeClick, nodesAndLinks } = this.props;

        onNodeClick(nodesAndLinks.nodes.find(nodes => nodes.data === topic));
    }

    /**
     * Bubble article selection up
     * @param {Object} article
     */
    onArticleSelected(article) {
        const { onNodeClick } = this.props;

        onNodeClick(article.id, "article");
    }

    /**
     * Get string to represent the query used to generate the output
     * @param {string} query
     */
    getSearchParams(query) {
        const { data, issues } = this.props;

        const prettyQuery = Object.assign({}, query);

        let issueIdList = query.issues;
        if (issueIdList) {
            issueIdList = issueIdList.split(",");
            const issueList = {
                data: issues.data.filter(issue => issueIdList.includes(issue.id)),
                dataBuckets: issues.dataBuckets
                    .map(b => b.filter(issue => issueIdList.includes(issue.id.toString()))),
            };
            Object.keys(issueList.dataBuckets).forEach(index => {
                if (issueList.dataBuckets.hasOwnProperty(index)) {
                    if (Array.isArray(issueList.dataBuckets[index])) {
                        if (issueList.dataBuckets[index].length < 1) {
                            delete issueList.dataBuckets[index];
                        }
                    } else {
                        delete issueList.dataBuckets[index];
                    }
                }
            });
            prettyQuery.issues = <IssueSelector disabled issues={issueList} />;
        }

        if (query.hasOwnProperty("articleIds")) {
            const articleList = data.submissions
                .filter(article => query.articleIds.includes(article.id));
            prettyQuery.articles = <ArticleSelector articles={articleList} disabled />;
            delete prettyQuery.articleIds;
        }

        let articleIdList = query.articles;
        if (articleIdList) {
            articleIdList = articleIdList.split(",");
            const articleList = data.submissions
                .filter(article => articleIdList.includes(article.id.toString()));
            prettyQuery.articles = (
                <ArticleInIssueSelector articles={articleList} disabled issues={issues} />);
        }

        if (query.articleData) {
            prettyQuery.articleData = "articles submitted by user";
        }

        const authorList = query.authors;
        if (authorList) {
            prettyQuery.authors = authorList.join(", ");
        }

        const date = query.dateRange;
        if (date) {
            // TODO: convert month to english and display it
            prettyQuery.dateRange = `${date.from.year} to ${date.to.year}`;
        }

        const typeList = query.types;
        if (typeList) {
            prettyQuery.types = typeList.join(", ");
        }

        return Object.entries(prettyQuery)
            .map(([key, value]) => {
                if (Array.isArray(value)) {
                    return [key, value.join(", ")];
                }
                return [key, value];
            })
            .filter(([key]) => (
                !["iterations", "bigTopics", "mutualLinks", "topicThreshold", "variableLinks"].includes(key)))
            .map(([key, value]) => {
                if (key === "numOfTopics") {
                    return ["Number of Topics", value];
                }
                return [key, value];
            })
            .map(([key, value]) => (
                <div key={key}>
                    <h6>{key}</h6>
                    <p>{typeof value === "string" ? value.replace(/,(\S)/g, (match, s) => `, ${s}`) : value}</p>
                </div>
            ));
    }

    /**
     * Get's a reference based on an article id
     * @param {number} id
     */
    getReference(id) {
        const { referenceMap } = this.state;

        fetch(`${consts.api}/citation/?ids=${id}&simple=true`, {
            method: "GET",
            mode: "cors",
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
                throw response.error;
            })
            .then((json) => {
                const citation = json.citations[0];
                return {
                    data: json.citations[0],
                    reactElement: (
                        <span>
                            {citation.names} ({citation.year}). {citation.title}. <i>{citation.journal}</i>,
                            {citation.volume}({citation.issue})
                            {citation.pages ? `: ${citation.pages}` : ""}. {citation.url}
                        </span>),
                };
            })
            .then((data) => {
                const newReferenceMap = Object.assign({}, referenceMap, { [id]: data });
                this.setState({ referenceMap: newReferenceMap });
            });
    }

    /**
     * Get overview statistics
     * @returns {string}
     */
    getStatistics() {
        const { data } = this.props;

        const { submissions } = data;

        const correlations = submissions.map(s => s.correlations);

        const { topicThreshold } = data.query;

        const correlatedTopicCount = correlations
            .map(s => s.reduce((accumulator, p) => (p >= topicThreshold ? accumulator + 1 : accumulator), 0));

        const articles = submissions.length;
        const articlesInATopic = correlatedTopicCount.filter(relations => relations > 0).length;
        const articlesInMultipleTopics = correlatedTopicCount.filter(relations => relations > 1).length;
        const articlesInExactlyOneTopic = articlesInATopic - articlesInMultipleTopics;
        const articlesInNoTopics = submissions.length - articlesInATopic;

        return (
            <div>
                Articles associated with at least one topic: {articlesInATopic} (
                {round((articlesInATopic / articles) * 100, 2)})%<br />
                Articles in exactly one topic: {articlesInExactlyOneTopic} (
                {round((articlesInExactlyOneTopic / articles) * 100, 2)})%<br />
                Articles bridging topics: {articlesInMultipleTopics} (
                {round((articlesInMultipleTopics / articles) * 100, 2)})%<br />
                Articles with no association: {articlesInNoTopics} (
                {round((articlesInNoTopics / articles) * 100, 2)})%<br />
            </div>
        );
    }

    /**
     * Get overview statistics
     * @returns {string}
     */
    getDiagnostics() {
        const { data } = this.props;

        const hasAllProps = [
            "CoherenceCNpmi",
            "CoherenceCUci",
            "CoherenceCV",
            "CoherenceUMass",
            "LL/Token",
            "TopFreqWords",
            "TotalTokens",
            "beta",
        ]
            .map(prop => Object.prototype.hasOwnProperty.call(data, prop))
            .reduce((prev, next) => prev && next);

        if (!hasAllProps) {
            return "";
        }
        return (
            <React.Fragment>
                <h5>Diagnostics</h5>
                <div className={offsetTwentyRight}>
                    <div>
                        Coherence C
                        <sub>NPMI</sub>: {data.CoherenceCNpmi}
                        <br />
                        Coherence C
                        <sub>UCI</sub>: {data.CoherenceCUci}
                        <br />
                        Coherence C
                        <sub>V</sub>: {data.CoherenceCV}
                        <br />
                        Coherence C
                        <sub>UMass</sub>: {data.CoherenceUMass}
                        <br />
                        for more info on coherence metrics click &nbsp;
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="http://svn.aksw.org/papers/2015/WSDM_Topic_Evaluation/public.pdf"
                        >
                            here
                        </a>
                        <br />
                        LL/Token: {data["LL/Token"]}
                        <br />
                        TopFreqWords: {data.TopFreqWords.join(", ")}
                        <br />
                        TotalTokens: {data.TotalTokens}
                        <br />
                        beta: {data.beta}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    getCorrectChild() {
        const { selected } = this.props;

        if (Array.isArray(selected)) {
            return this.renderTopics(selected);
        }
        const level = selected ? selected.level : 0;
        if (level === 0) {
            return this.renderOverview();
        }
        if (level === 1) {
            return this.renderTopics([selected]);
        }
        return this.renderArticle(selected);
    }

    /**
     * Small article info
     * @param {Object} article
     * @returns {string}
     */
    smallArticleInfo(article) {
        // previous time review functionality
        // href={`https://timreview.ca/article/${article.id}`}
        // target="_blank"

        return (
            <tr key="small-article-info">
                <td>
                    <button
                        type="button"
                        className={buttonToLink}
                        onClick={this.onArticleSelected.bind(this, article)}
                        rel="noopener noreferrer"
                    >
                        {article.title}
                    </button>
                </td>
            </tr>
        );
    }

    drillIntoTopic(articles) {
        const { data } = this.props;

        const query = Object.assign({}, data.query, { articles: articles.map(a => a.id).join(",") });

        ["cacheId", "autoRun", "articleData"]
            .filter(prop => query.hasOwnProperty(prop))
            .forEach(prop => {
                delete query[prop];
            });

        const queryString = Object.entries(query)
            .filter(([key]) => key !== "issues" && key !== "types" && key !== "search")
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join("&");

        window.open(`/#${queryString}`, "_blank");
    }

    /**
     * Get overview of a topic
     * @param {Object} topics
     * @returns {string}
     */
    topicOverview(topics) {
        return (
            <tr key="topic-overview">
                <td>
                    <h5><span role="img" aria-label="medium circle">&#x26AB;</span> Topics
                        ({topics.length})
                    </h5>
                    <div className={offsetTwentyRight}>
                        <p>Three most-relevant terms shown</p>
                        <ol>
                            {topics.map((topic) => (
                                <li key={topic.id}>
                                    <button
                                        type="button"
                                        className={buttonToLink}
                                        onClick={this.onTopicSelected.bind(this, topic)}
                                    >
                                        {topic.keywordList.slice(0, 3)
                                            .map(k => k.word)
                                            .join(", ")
                                            .replace(/_/g, " ")}
                                    </button>
                                </li>))
                            }
                        </ol>
                    </div>
                </td>
            </tr>);
    }

    /**
     * Small info for multiple articles
     * @param {Array.<Object>} articles
     * @param {string} [text] text to use as the title for the section
     * @param {[number] | number} [topicIndexes]
     * @returns {XML}
     */
    smallArticlesInfo(articles, text = "Articles", topicIndexes) {
        const { data, onNodeClick, citationExport } = this.props;

        return (
            <SmallArticleInfo
                key="small-articles-info"
                data={data}
                onNodeClick={onNodeClick}
                articles={articles}
                text={text}
                topicIndexes={topicIndexes}
                summarize={!!topicIndexes}
                onArticleSelected={this.onArticleSelected.bind(this)}
                citationExport={citationExport}
                drillIntoTopic={this.drillIntoTopic.bind(this)}
                canDrillIntoTopic={ContextTable.canDrillIntoTopic}
            />);
    }

    /* getDrillArticles(selected) {
        const topicIndex = parseInt(selected.data.id, 10);

        return this.props.data.submissions.filter(article =>
            article.correlations[topicIndex] >= this.props.data.query.topicThreshold);
    } */

    /**
     * Big Overview of article information
     * @param {Object} article
     * @returns {string}
     */
    bigArticleCitation(article) {
        const { referenceMap } = this.state;
        const { citationExport } = this.props;

        if (!referenceMap.hasOwnProperty(article.id)) {
            this.getReference(article.id);
        }
        return (
            <tr key="big-article-citation">
                <td>
                    <h5><span role="img" aria-label="small circle">&#x25CF;</span> Article</h5>
                    <div className={offsetTwentyRight}>
                        <a
                            href={`https://timreview.ca/article/${article.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {article.title}
                        </a>
                        <br />
                        <button
                            type="button"
                            onClick={() => citationExport([article])}
                        >
                            Export as citation
                        </button>
                        <p>Views: {article.data.views}</p>
                        <p>Citations: {article.data.citations}</p>
                        <br />
                        {referenceMap.hasOwnProperty(article.id)
                            ? referenceMap[article.id].reactElement
                            : <div>Loading...</div>
                        }
                        <br />
                    </div>
                </td>
            </tr>
        );
    }

    bigArticleContent(article, topics) {
        const { data } = this.props;
        const date = new Date(article.data.createdAt);

        return (
            <tr key="big-article-content">
                <td>
                    <h5><span role="img" aria-label="small circle">&#x25CF;</span> Article</h5>
                    <div className={offsetTwentyRight}>
                        <h5>
                            {article.title}
                        </h5>
                        <br />
                        {!Number.isNaN(date)
                            ? <p>Date: {date.getFullYear()}-{date.getMonth()}-{date.getDate()}</p>
                            : ""
                        }
                        {article.data.views ? <p>Views: {article.data.views}</p> : ""}
                        {article.data.citations ? <p>Citations: {article.data.citations}</p> : ""}
                        {article.data.authors ? <p>Authors: {article.data.authors.join(" | ")}</p> : ""}
                        {!Number.isNaN(date) || article.data.views || article.data.citations ? <br /> : ""}
                        <ArticleDisplay id={article.id} data={data} topics={topics} />
                        <br />
                    </div>
                </td>
            </tr>
        );
    }

    /**
     * Minimal overview of a topic
     * @param {Object} selected
     * @returns {string}
     */
    smallTopicInfo(selected) {
        const { onNodeClick } = this.props;

        return (
            <tr key={`small-topic-info-${selected.index}`}>
                <td>
                    <u>
                        <button
                            type="button"
                            className={buttonToLink}
                            onClick={onNodeClick.bind(this, selected)}
                        >
                            <span role="img" aria-label="medium circle">&#x26AB;</span> Topic:
                        </button>
                    </u>
                    <br />
                    <div className={offsetTwentyRight}>
                        <i>
                            {selected.keywordList
                                .slice(0, NUMBER_OF_KEYWORDS_TO_DISPLAY)
                                .map(k => k.word).join(", ")}
                        </i>
                    </div>
                </td>
            </tr>);
    }

    /**
     * Minimal view of the sample info
     * @returns {string}
     */
    smallSampleInfo() {
        const { data, onNodeClick, nodesAndLinks } = this.props;

        return (
            <tr key="small-sample-info">
                <td>
                    <button
                        type="button"
                        className={buttonToLink}
                        onClick={onNodeClick.bind(this, nodesAndLinks[0])}
                    >
                        &#x2B24; Selection: {data.submissions.length} articles
                    </button>
                </td>
            </tr>
        );
    }

    /**
     * Expanded view of the sample info
     * @returns {string}
     */
    bigSampleInfo() {
        const { data, onNodeClick, nodesAndLinks } = this.props;

        return (
            <tr key="big-sample-info">
                <td>
                    <button
                        type="button"
                        className={buttonToLink}
                        onClick={onNodeClick.bind(this, nodesAndLinks[0])}
                    >
                        &#x2B24; Selection: {data.submissions.length} articles analyzed
                    </button>
                    <div className={offsetTwentyRight}>
                        <h5>Statistics</h5>
                        <div className={offsetTwentyRight}>
                            {this.getStatistics()}
                        </div>
                        {this.getDiagnostics()}
                        <h5>Search Parameters</h5>
                        <div className={offsetTwentyRight}>
                            {this.getSearchParams(data.query)}
                        </div>
                    </div>
                </td>
            </tr>);
    }

    /**
     * Summarise results
     * @returns {string}
     */
    resultsSummery() {
        const { data } = this.props;

        return (
            <div>
                <h4>Results</h4>
                <p>{this.countSubmissionsOverThreshold()} articles found in {data.topics.length}
                    topics
                </p>
                <h5>Search Parameters Used</h5>
                {this.getSearchParams(data.query)}
            </div>
        );
    }

    /**
     * Get's the number of submissions with relationStrengths over the topicThreshold
     * @returns {number}
     */
    countSubmissionsOverThreshold() {
        const { data, nodesAndLinks } = this.props;

        return nodesAndLinks.nodes.length - data.topics.length - 1;
    }

    renderOverview() {
        const { data } = this.props;

        return ([
            this.bigSampleInfo(),
            this.topicOverview(data.topics),
            this.smallArticlesInfo(data.submissions, "Population of Articles"),
        ]);
    }

    renderTopics(topics) {
        const { data, onNodeClick } = this.props;

        const topicIndexes = topics.map(topic => parseInt(topic.data.id, 10));

        let articles;

        if (topicIndexes.includes(-1)) {
            articles = data.submissions.filter(article => article
                .correlations.reduce((include, correlation) => include
                    && correlation < data.query.topicThreshold, true));
        } else if (data.query.mutualLinks) {
            articles = data.submissions.filter(article => article
                .correlations.reduce(
                    (include, correlation, index) => include
                        && (topicIndexes.includes(index)
                            === (correlation >= data.query.topicThreshold)),
                    true,
                ));
        } else {
            articles = data.submissions.filter(article => (
                topicIndexes.reduce(
                    (include, topicIndex) => (
                        include && article.correlations[topicIndex] >= data.query.topicThreshold
                    ),
                    true,
                )));
        }

        return ([
            this.smallSampleInfo(),
            ...topics.map(topic => (
                <BigTopicInfo
                    data={data}
                    onNodeClick={onNodeClick}
                    topic={topic}
                />)),
            this.smallArticlesInfo(articles, "Articles", topicIndexes),
        ]);
    }

    renderArticle(selected) {
        const results = [];

        let topics = [];

        if (Array.isArray(selected.parents)) {
            if (selected.parents[0] && (selected.parents[0].level === 1 || selected.parents[0].level === 0)) {
                results.push(this.smallSampleInfo());
            }

            topics = selected.parents.filter(p => p.level === 1);

            results.push(...topics.map(topic => (this.smallTopicInfo(topic))));
        }
        // TODO: mode switch between citation and article info.
        results.push(this.bigArticleContent(selected, topics));
        return results;
    }

    render() {
        return (
            <table className={contextTable}>
                <tbody className={tableBody}>
                    {this.getCorrectChild()}
                </tbody>
            </table>);
    }
}

ContextTable.propTypes = {
    data: PropTypes.shape({
        submissions: PropTypes.array.isRequired,
        topics: PropTypes.array.isRequired,
        query: PropTypes.object.isRequired,
    }).isRequired,
    nodesAndLinks: PropTypes.shape({
        nodes: PropTypes.array.isRequired,
        links: PropTypes.array.isRequired,
    }).isRequired,
    issues: PropTypes.oneOfType([issuesProp, PropTypes.bool]),
    selected: PropTypes.oneOfType([PropTypes.object, PropTypes.bool, PropTypes.arrayOf(PropTypes.object)]),
    citationExport: PropTypes.func.isRequired,
    onNodeClick: PropTypes.func,
};

ContextTable.defaultProps = {
    onNodeClick: () => {
    },
    selected: false,
    issues: false,
};

export default ContextTable;
