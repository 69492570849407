import React from "react";
import PropTypes from "prop-types";
import { NUMBER_OF_KEYWORDS_TO_DISPLAY } from "../../../consts";
import TwoAxisChart from "../../TwoAxisChart";
import { round } from "../../../utils";

import { buttonToLink } from "../../../Styles/buttons.module.css";
import { offsetTwenty } from "../../../Styles/misc.module.css";


export default function BigTopicInfo(props) {
    const { topic, data, onNodeClick } = props;

    if (topic.index === -1) {
        return (
            <tr key={`big-topic-info-${topic.index}`}>
                <td>
                    Un-Categorized.
                </td>
            </tr>
        );
    }

    const keywordList = topic.keywordList.slice(0, NUMBER_OF_KEYWORDS_TO_DISPLAY);
    const topicStrength = data.submissions
        .reduce((total, article) => total + (article.correlations[topic.index - 1] || 0), 0);

    return (
        <tr key={`big-topic-info-${topic.index}`}>
            <td>
                <u>
                    <button
                        type="button"
                        className={buttonToLink}
                        onClick={onNodeClick.bind(this, topic)}
                    >
                        <span role="img" aria-label="medium circle">&#x26AB;</span> Topic:
                    </button>
                </u>
                <div className={offsetTwenty}>
                    keywords: <i>{keywordList.map(k => k.word).join(", ")}</i>
                    <br />
                    <TwoAxisChart topics={data.topics} keywordList={keywordList} />
                    {/* <WordCloud keywordList={selected.keywordList} /> */}
                    Topic Strength: {round(topicStrength / data.submissions.length, 2)}
                    <br />
                    (relation strength of this topic to all articles)
                </div>
            </td>
        </tr>
    );
}

BigTopicInfo.propTypes = {
    topic: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    onNodeClick: PropTypes.func.isRequired,
};
