/**
 * Created by macdja38 on 2017-05-31.
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { issuesProp, monthDayRange } from "../../consts";

export default class IssueSelector extends Component {
    static propTypes = {
        range: PropTypes.oneOfType([monthDayRange, PropTypes.bool]),
        disabled: PropTypes.bool,
        issues: PropTypes.oneOfType([issuesProp, PropTypes.bool]),
        clearSelector: PropTypes.func,
        onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    };

    static defaultProps = {
        clearSelector: () => {
        },
        onChange: false,
        issues: false,
        disabled: false,
        range: false,
    };

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        const { clearSelector } = this.props;

        clearSelector(() => {
            let optionsArray;
            if (this.multiSelect) {
                optionsArray = [...this.multiSelect.options];
            } else {
                optionsArray = [];
            }
            optionsArray
                .filter((selector) => selector.selected)
                .forEach(option => {
                    // eslint-disable-next-line no-param-reassign
                    option.selected = false;
                });
        });
    }

    onChange() {
        const { onChange } = this.props;

        if (onChange) {
            onChange([...this.multiSelect.options]
                .filter(option => option.selected)
                .map(option => option.value));
        }
    }

    get value() {
        let optionsArray;
        if (this.multiSelect) {
            optionsArray = [...this.multiSelect.options];
        } else {
            optionsArray = [];
        }
        const selectedOptions = optionsArray
            .filter((selector) => selector.selected);

        // if no options are selected return all of them
        return (selectedOptions.length > 0 ? selectedOptions : optionsArray)
            .map((selector) => selector.value);
    }

    render() {
        const { issues, disabled, range } = this.props;

        if (!issues) {
            return <span>Loading...</span>;
        }
        return (
            <select
                ref={multiSelect => {
                    this.multiSelect = multiSelect;
                }}
                id="issueList"
                title="select issues from the list"
                name="selectIssue"
                size="10"
                multiple="multiple"
                disabled={disabled}
                defaultValue={[]}
                onChange={this.onChange}
            >
                {
                    issues.dataBuckets.reduce((accumulator, group, year) => {
                        if (range) {
                            if (year < range.from.year) {
                                // eslint-disable-next-line no-param-reassign
                                delete accumulator[year];
                                return accumulator;
                            }
                            if (year > range.to.year) {
                                // eslint-disable-next-line no-param-reassign
                                delete accumulator[year];
                                return accumulator;
                            }
                            if (year === range.from.year) {
                                // eslint-disable-next-line no-param-reassign
                                accumulator[year] = accumulator[year]
                                    .filter(item => item.month >= (range.from.month - 1));
                            }
                            if (year === range.to.year) {
                                // eslint-disable-next-line no-param-reassign
                                accumulator[year] = accumulator[year]
                                    .filter(item => item.month <= (range.to.month - 1));
                            }
                        }
                        return accumulator;
                    }, issues.dataBuckets.slice(0)).map((group, year) => (
                        // array index is unique to element so this is an ok usage here.
                        // eslint-disable-next-line react/no-array-index-key
                        <optgroup key={year} label={year}>{
                            group.map(item => <option value={item.id} key={item.id}>{item.title}</option>)
                        }
                        </optgroup>
                    ))
                }
            </select>
        );
    }
}
