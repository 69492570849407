import React from "react";
import PropTypes from "prop-types";
import searchPageStyles from "../index.module.css";

export default function SelectDataSource({ defaultsAndQuery, updateQuery }) {
    return (
        // eslint-disable-next-line jsx-a11y/label-has-for,jsx-a11y/label-has-associated-control
        <label htmlFor="dataSourceSelect">
            <h6 className={searchPageStyles.boxedTitle}>Choose your data source</h6>
            <div>
                <select
                    value={defaultsAndQuery.source}
                    id="dataSourceSelect"
                    onChange={event => updateQuery("source", event.target.value)}
                >
                    <option value="obj">
                        OBJ
                    </option>
                    <option value="tim">
                        TIM
                    </option>
                    <option value="gers">
                        GERS
                    </option>
                    <option value="gers2">
                        GERS2
                    </option>
                    <option value="ispim">
                        ISPIM
                    </option>
                    <option value="file">
                        File Upload
                    </option>
                    <option value="link">
                        Link list
                    </option>
                    <option value="sheet">
                        SpreadSheet
                    </option>
                </select>
            </div>
        </label>
    );
}

SelectDataSource.propTypes = {
    defaultsAndQuery: PropTypes.object.isRequired,
    updateQuery: PropTypes.func.isRequired,
};
