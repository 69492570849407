/**
 * Created by macdja38 on 2017-06-02.
 */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { axisBottom, axisLeft, max, min, scaleLinear, select } from "d3";

import styles from "./index.module.css";

class TopicChartScatterPlot extends Component {
    static propTypes = {
        data: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            numOfTopics: PropTypes.number.isRequired,
            CoherenceCNpmi: PropTypes.number.isRequired,
            CoherenceCUci: PropTypes.number.isRequired,
            CoherenceCV: PropTypes.number.isRequired,
            CoherenceUMass: PropTypes.number.isRequired,
        }).isRequired).isRequired,
    };

    static defaultProps = {};

    static add(x, y) {
        return x + y;
    }

    /**
     * Ads two numbers
     * @param {number} previous
     * @param {number} next
     * @returns {number}
     */
    static relationPercentCalculator(previous, next) {
        return previous + next;
    }

    /**
     * When an article is selected bubble up selected article
     * @param {{id: string}} d
     */
    static onArticleSelected(d) {
        window.open(`https://jtool.cugcr.ca/display/${d.id}`);
    }

    constructor(...args) {
        super(...args);
        this.drawChart = this.drawChart.bind(this);
        this.optionSelected = this.optionSelected.bind(this);
        this.state = { yAxis: { key: "CoherenceCV", label: "Model Coherence" } };
    }

    componentDidMount() {
        this.drawChart();
        window.addEventListener("resize", this.drawChart);
    }

    componentDidUpdate() {
        this.drawChart();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.drawChart);
    }

    drawChart() {
        const { data } = this.props;

        Array.from(this.svg.children || this.svg.childNodes).forEach(child => {
            this.svg.removeChild(child);
        });
        this.renderToChart(data);
    }

    optionSelected(event) {
        const { yAxis } = this.state;

        this.setState({ yAxis: { key: event.target.value, label: yAxis.label } });
    }

    /**
     * Renders a keyword list to an html string for use in popups
     * @param {Array<Object>} data
     */
    renderToChart(data) {
        const chartElement = this.svg;
        const xAxisKey = "numOfTopics";
        const xAxisLabel = "Number Of Topics";
        const { yAxis } = this.state;
        const { key: yAxisKey, label: yAxisLabel } = yAxis;

        const boundingRect = chartElement.getBoundingClientRect();

        const margin = {
            top: 20, right: 15, bottom: 60, left: 60,
        };
        const width = boundingRect.width - margin.left - margin.right;
        const height = boundingRect.height - margin.top - margin.bottom;

        const x = scaleLinear()
            .domain([min(data, d => d[xAxisKey]), max(data, d => d[xAxisKey])])
            .range([0, width]);

        const y = scaleLinear()
            .domain([min(data, d => d[yAxisKey], 10), max(data, d => d[yAxisKey], 10)])
            .range([height, 1]);

        const chart = select(chartElement);

        const main = chart.append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`)
            .attr("width", width)
            .attr("height", height)
            .attr("class", "main");

        // draw the x axis
        const xAxis = axisBottom()
            .scale(x);

        main.append("g")
            .attr("transform", `translate(0,${height})`)
            .attr("class", "main axis date")
            .call(xAxis);

        // draw the y axis
        const yAxisScale = axisLeft()
            .scale(y);

        main.append("g")
            .attr("transform", "translate(0,0)")
            .attr("class", "main axis date")
            .call(yAxisScale);

        const g = main.append("svg:g");

        g.selectAll("scatter-dots")
            .data(data)
            .enter()
            .append("svg:circle")
            .on("click", (d) => TopicChartScatterPlot.onArticleSelected(d))
            .attr("cx", d => x(d[xAxisKey]))
            .attr("cy", d => y(d[yAxisKey]))
            .attr("r", 2)
            .append("svg:title")
            .text(d => `Coherence: ${d[yAxisKey]}\nModel ID: ${d.id}`);

        // text label for the x axis
        g.append("text")
            .attr(
                "transform",
                `translate(${width / 2},${height + margin.top + 20})`,
            )
            .attr("fill", "black")
            .style("text-anchor", "middle")
            .text(xAxisLabel)
            .append("svg:title")
            .text("Date of the issue publication.");

        // text label for the y axis
        g.append("text")
            .attr("transform", "rotate(-90)")
            .attr("y", 0 - margin.left)
            .attr("x", 0 - (height / 2))
            .attr("dy", "1em")
            .attr("fill", "black")
            .style("text-anchor", "middle")
            .text(yAxisLabel)
            .append("svg:title")
            .text("Relationship to the category 100 is strongest.");

        return chartElement;
    }

    render() {
        const { yAxis } = this.state;

        return (
            <div className={styles.chartBox}>
                <h6 className={styles.chartTitle}>Degree of association of documents to topic</h6>
                <svg
                    className={styles.chart}
                    ref={svg => {
                        this.svg = svg;
                    }}
                />
                <select onChange={this.optionSelected} value={yAxis.key}>
                    <option value="CoherenceCNpmi">Coherence Cnpmi</option>
                    <option value="CoherenceCUci">Coherence Cuci</option>
                    <option value="CoherenceCV">Coherence Cv</option>
                    <option value="CoherenceUMass">Coherence Cumass</option>
                </select>
            </div>
        );
    }
}

export default TopicChartScatterPlot;
