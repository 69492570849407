import React, { Component } from "react";
import PropTypes from "prop-types";
import Authors from "./Authors";
import Journals from "./Journals";

export default class Chart extends Component {
    static propTypes = {
        close: PropTypes.func.isRequired,
        masterList: PropTypes.arrayOf(PropTypes.object).isRequired,
        data: PropTypes.object.isRequired,
    };

    static getChart(tab, props) {
        switch (tab) {
            case "journals":
                return <Journals masterList={props.masterList} data={props.data} />;
            case "authors":
                return <Authors data={props.data} />;
            default:
                return <span>Error, invalid tab selected</span>;
        }
    }

    constructor(props) {
        super(props);
        this.state = { tab: "journals" };
    }

    changeTab(newTab) {
        this.setState({ tab: newTab });
    }

    render() {
        const { tab } = this.state;
        const { close } = this.props;

        return (
            <div style={{ height: "100%" }}>
                <div style={{ height: "10%" }}>
                    <button
                        type="button"
                        onClick={this.changeTab.bind(this, "journals")}
                    >
                        Journals
                    </button>
                    <button
                        type="button"
                        onClick={this.changeTab.bind(this, "authors")}
                    >
                        Authors
                    </button>
                    <button
                        type="button"
                        onClick={close}
                    >
                        Close
                    </button>
                </div>
                {Chart.getChart(tab, this.props)}
            </div>
        );
    }
}
