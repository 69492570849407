import React, { Component } from "react";
import PropTypes from "prop-types";
import csvParse from "csv-parse/lib/es5";
import AddFile from "../AddFile";
import { genIDs, uploadArticlesAutoBatch } from "../../../utils";

import searchPageStyles from "../../index.module.css";
import checkboxes from "../../../Styles/checkboxes.module.css";

const parse = (...args) => new Promise((resolve, reject) => {
    csvParse(...args, (error, result) => {
        if (error) {
            reject(error);
        } else {
            resolve(result);
        }
    });
});


function readFile(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = e => resolve(e.target.result);
        reader.onerror = e => reject(e);
        reader.readAsText(file);
    });
}

export default class SelectSheet extends Component {
    static propTypes = {
        updateQuery: PropTypes.func.isRequired,
        defaultsAndQuery: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            uniqueIds: genIDs("fileSubmit", "articlePerCell"),
            articlePerCell: false,
            sheet: false,
            columns: "",
            starting: 2,
            date: 1,
            title: 1,
        };
        this.selectArticlePerCell = this.selectArticlePerCell.bind(this);
        this.handleFileAdd = this.handleFileAdd.bind(this);
        this.selectColumns = this.selectColumns.bind(this);
        this.selectStarting = this.selectStarting.bind(this);
        this.selectDate = this.selectDate.bind(this);
        this.selectTitle = this.selectTitle.bind(this);
    }

    selectArticlePerCell(event) {
        this.setState({ articlePerCell: event.target.value });
        this.updateFiles();
    }

    selectColumns(event) {
        this.setState({ columns: event.target.value });
        this.updateFiles();
    }

    selectStarting(event) {
        this.setState({ starting: event.target.value });
        this.updateFiles();
    }

    selectDate(event) {
        this.setState({ date: event.target.value });
        this.updateFiles();
    }

    selectTitle(event) {
        this.setState({ title: event.target.value });
        this.updateFiles();
    }

    updateFiles() {
        const { columns, text, starting, sheet, date: dateColumn, title: titleColumn, articlePerCell } = this.state;
        const { updateQuery } = this.props;

        updateQuery("getFiles", async () => {
            const rows = await parse(text);
            const validColumns = columns.split(",").map(int => parseInt(int, 10));

            const articles = rows.slice(starting - 1, rows.length)
                .map(row => {
                    const rowTexts = row.filter((column, columnIndex) => validColumns.includes(columnIndex + 1));
                    const date = new Date(row[dateColumn - 1]);
                    const title = row[titleColumn - 1];

                    if (articlePerCell) {
                        return rowTexts.map(rowText => ({
                            text: rowText,
                            date,
                            title,
                        }));
                    }
                    return {
                        text: rowTexts.join(" "),
                        date,
                        title,
                    };
                })
                .reduce((acc, element) => {
                    if (Array.isArray(element)) {
                        acc.push(...element);
                    } else {
                        acc.push(element);
                    }
                    return acc;
                }, [])
                .map(({ text: rowText, date, title }) => {
                    if (!/[^\s]/.test(rowText)) {
                        return null;
                    }

                    const result = {
                        text,
                        file: sheet,
                        title,
                        isPDF: false,
                    };

                    if (date) {
                        result.date = date;
                    }

                    return result;
                })
                .filter(row => row !== null);

            return uploadArticlesAutoBatch(articles, 100);
        });
    }

    handleFileAdd(files) {
        const sheet = files[0];
        readFile(sheet).then(text => {
            this.setState({ text, sheet });
        });
    }

    render() {
        const { uniqueIds, columns, articlePerCell, starting, title, sheet } = this.state;

        return (
            // eslint-disable-next-line jsx-a11y/label-has-for
            <label htmlFor={uniqueIds.fileSubmit}>
                <h6 className={searchPageStyles.boxedTitle}>Submit your own files</h6>
                <div>
                    <small>Only include the articles you upload below</small>
                    <br />
                    <small className={searchPageStyles.noticeableGreen}>Accepted formats are .txt, .pdf, and .zip of
                        .txt
                    </small>
                    <br />
                    <AddFile id={uniqueIds.fileSubmit} accept=".csv" addedFiles={this.handleFileAdd} />
                    {sheet
                        ? (
                            <div className={searchPageStyles.spreadsheet}>
                                Select Content columns
                                <input type="text" onChange={this.selectColumns} value={columns} />
                                <small className={searchPageStyles.noticeableBlack}>(comma separated numbers)</small>
                                <br />
                                <label
                                    className={checkboxes.advancedCheckbox}
                                    htmlFor={uniqueIds.articlePerCell}
                                >
                                    Article per cell?
                                    <input
                                        id={uniqueIds.articlePerCell}
                                        type="checkbox"
                                        onChange={this.selectArticlePerCell}
                                        value={articlePerCell}
                                    />
                                </label>
                                <small className={searchPageStyles.noticeableBlack}>(this will process each cell as
                                    it&#39;s own
                                    article
                                    instead of each row as an article)
                                </small>
                                <br />
                                Select starting row
                                <input
                                    type="number"
                                    min="1"
                                    onChange={this.selectStarting}
                                    value={starting}
                                />
                                <small className={searchPageStyles.noticeableBlack}> (Row with the first data)</small>
                                <br />
                                {/* Select Date Column
                            <input type="number" onChange={this.selectDate} value={this.state.date} />
                            (ms since 1970 or timestamp&nbsp;
                            <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href="https://tools.ietf.org/html/rfc2822#section-3.3"
                            >
                                as defined here
                            </a>)
                            <br /> */}
                                Select Label Column
                                <input type="number" min="1" onChange={this.selectTitle} value={title} />
                                <br />
                            </div>)
                        : ""}
                </div>
            </label>
        );
    }
}
