import React, { Component } from "react";
import PropTypes from "prop-types";
import Highlighter from "react-highlight-words";
import { Document, Page } from "react-pdf/build/entry.webpack";
import styles from "./index.module.css";
import { getSummary } from "../../../../utils";
import consts, { topicsProp } from "../../../../consts";

class ArticleDisplay extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        topics: topicsProp,
        id: PropTypes.string.isRequired,
    };

    static defaultProps = {
        topics: [],
    };

    constructor(props) {
        super(props);
        this.state = { numPages: null, contentMap: {}, prettyDisplay: true };
    }

    onDocumentLoadSuccess = ({ numPages }) => this.setState({ numPages });

    /**
     * Get's a reference based on an article id
     * @param {string} id
     */
    getContent(id) {
        const { contentMap, numPages } = this.state;
        const { data } = this.props;

        const articleToLocalMeta = (article) => {
            let element;
            if (article.isPDF) {
                element = (
                    <Document
                        onLoadSuccess={this.onDocumentLoadSuccess}
                        file={article.file}
                    >
                        {Array.from(
                            new Array(numPages),
                            (el, index) => (
                                <Page
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                />
                            ),
                        )}
                    </Document>);
            }
            return ({
                data: article,
                reactElement: element,
            });
        };

        if (data.query.articleData && data.query.articleData.length > 0) {
            const article = data.query.articleData.find(a => a.id === id);
            if (article) {
                return articleToLocalMeta(article);
            }
        }

        fetch(`${consts.api}/article/?id=${id}&simple=true`, {
            method: "GET",
            mode: "cors",
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
                throw response.error;
            })
            .then(articleToLocalMeta)
            .then((articleData) => {
                const contentMapClone = Object.assign({}, contentMap, { [id]: articleData });
                this.setState({ contentMap: contentMapClone });
                console.log(contentMapClone);
                if (!articleData.hasOwnProperty("summary")) {
                    getSummary(id).then(result => {
                        const { contentMap: contentMapLocal } = this.state;
                        const dataWithSummary = Object.assign({}, articleData, { summary: result.sentences.join(" ") });
                        const contentMapWithSummary = Object.assign({}, contentMapLocal, {
                            [id]: dataWithSummary,
                        });
                        console.log(dataWithSummary);
                        this.setState({ contentMap: contentMapWithSummary });
                    });
                }
            });
        return false;
    }

    render() {
        const { contentMap, prettyDisplay } = this.state;
        const { id, topics } = this.props;
        let meta = false;
        if (!contentMap.hasOwnProperty(id)) {
            meta = this.getContent(id);
        } else {
            meta = contentMap[id];
        }
        let contentElement;

        const searchWords = topics.map(topic => topic.keywordList).reduce((acc, b) => {
            acc.push(...b);
            return acc;
        }, []).filter(word => word.exclusivity >= 0.75).map(keyword => keyword.word)
            .map(keyword => ` ${keyword}`);

        if (meta) {
            if (prettyDisplay) {
                if (meta.reactElement) {
                    contentElement = meta.reactElement;
                } else {
                    contentElement = (
                        <div>
                            <br />
                            <Highlighter
                                className={styles.breakOnSpaces}
                                highlightClassName={styles.yourHighlightClass}
                                searchWords={searchWords}
                                autoEscape
                                textToHighlight={meta.data.text}
                            />
                        </div>);
                }
            } else {
                contentElement = meta.data.text;
            }
        } else {
            contentElement = <p>Loading...</p>;
        }

        return (
            <div>
                {meta
                    ? (
                        <div>
                            {meta.data.rating ? <p>Journal quality: {meta.data.rating}</p> : ""}
                            {meta.data.downloadLink || meta.data.downloadlink
                                ? (
                                    <a
                                        href={meta.data.downloadLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Download PDF
                                    </a>)
                                : ""}
                            {meta.data.doi ? <p>DOI: {meta.data.doi}</p> : ""}
                            {meta.data.doi
                                ? (
                                    <a
                                        href={`http://dx.doi.org/${meta.data.doi}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Article Source
                                    </a>)
                                : ""}
                            {meta.data.summary
                                ? (
                                    <div>
                                        <br />Summary:&nbsp;
                                        <Highlighter
                                            className={styles.breakOnSpaces}
                                            highlightClassName={styles.yourHighlightClass}
                                            searchWords={searchWords}
                                            autoEscape
                                            textToHighlight={meta.data.summary}
                                        />
                                    </div>)
                                : ""}
                            {meta.data.text
                                ? (
                                    <button
                                        type="button"
                                        onClick={() => this.setState({ prettyDisplay: !prettyDisplay })}
                                    >
                                        {prettyDisplay ? "Check parsed contents" : "View original file"}
                                    </button>)
                                : ""}
                            {meta.data.text ? contentElement : ""}
                        </div>)
                    : ""}
            </div>
        );
    }
}

export default ArticleDisplay;
