import PDFJS, { GlobalWorkerOptions } from "pdfjs-dist/webpack";

GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";

function randomName() {
    return Math.random();
}

class WorkerPool {
    constructor() {
        this.used = [];
        this.free = [];
    }

    getWorker() {
        if (this.free.length > 0) {
            const worker = this.free.pop();
            this.used.push(worker);
            return worker;
        }
        const worker = new PDFJS.PDFWorker({ name: randomName() });
        this.used.push(worker);
        return worker;
    }

    freeWorker(worker) {
        const index = this.used.indexOf(worker);
        if (index >= 0) {
            this.used.splice(index, 1);
            this.free.push(worker);
        } else {
            console.log("warning, worker freed twice", worker);
        }
    }

    getPoolSize() {
        return this.used.length + this.free.length;
    }
}

export default new WorkerPool();
