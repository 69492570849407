import React, { Component } from "react";
import PropTypes from "prop-types";
import JSZip from "jszip";
import PDFJS, { GlobalWorkerOptions } from "pdfjs-dist/webpack";
import pMap from "../../../es6-libs/p-map";
import workerPool from "./workerPool";
import StudentFiles from "./Components/StudentFiles";
import AddFile from "../AddFile";
import { flat, uploadArticle } from "../../../utils";
import searchPageStyles from "../../index.module.css";

GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";

function readFile(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = e => resolve(e.target.result);
        reader.onerror = e => reject(e);
        reader.readAsText(file);
    });
}

function readFileAsBuffer(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = e => resolve(e.target.result);
        reader.onerror = e => reject(e);
        reader.readAsArrayBuffer(file);
    });
}

function getPageText(pageNum, PDFDocumentInstance) {
    return PDFDocumentInstance.getPage(pageNum)
        .then((pdfPage) => pdfPage.getTextContent())
        .then((textContent) => textContent.items.map(c => c.str).join(" "));
}


function pdf2text(pdffile) {
    const worker = workerPool.getWorker();
    return readFileAsBuffer(pdffile)
        .then((buffer) => PDFJS.getDocument({ data: buffer, worker }))
        .then((pdf) => Promise.all(
            new Array(pdf.pdfInfo.numPages)
                .fill(0)
                .map((value, index) => getPageText(index + 1, pdf)),
        ))
        .then((pagesText) => pagesText.join(""))
        .then(pagesText => {
            workerPool.freeWorker(worker);
            return pagesText;
        })
        .catch((reason) => {
            console.error(reason);
            throw reason;
        });
}

async function pdfuint8array2Text(data) {
    const worker = workerPool.getWorker();
    const pdfTask = PDFJS.getDocument({ data, worker });
    const pdf = await pdfTask;
    const pagesText = await Promise
        .all(new Array(pdf.pdfInfo.numPages)
            .fill(0)
            .map((value, index) => getPageText(index + 1, pdf)));
    const joinedPages = pagesText.join("");
    pdfTask.destroy();
    workerPool.freeWorker(worker);
    return joinedPages;
}

function removeLeadingSlash(name) {
    return name.replace(/^.*\//g, "");
}

function mapToTitleID(object) {
    return {
        id: object.id,
        title: object.title,
    };
}

export default class SelectFiles extends Component {
    static propTypes = {
        updateQuery: PropTypes.func.isRequired,
        defaultsAndQuery: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { filePromises: [] };
        this.handleFileAdd = this.handleFileAdd.bind(this);
        this.checkFiles = this.checkFiles.bind(this);
    }

    checkFiles() {
        const { filePromises } = this.state;
        return Promise.all(filePromises);
    }

    handleFileAdd(files) {
        const { updateQuery, defaultsAndQuery } = this.props;

        const newFiles = [...files].map(async (file) => {
            const fileExtension = file.name.replace(/^.*\./, "").toLowerCase();
            if (fileExtension === "zip") {
                const data = await JSZip.loadAsync(await readFileAsBuffer(file), { createFolders: false });

                return pMap(
                    Object.values(data.files).filter(f => !f.dir),
                    async (unZippedFile) => {
                        if (unZippedFile.name.endsWith(".pdf")) {
                            return mapToTitleID(await uploadArticle(
                                removeLeadingSlash(unZippedFile.name),
                                await pdfuint8array2Text(await unZippedFile.async("uint8array")),
                            ));
                        }
                        return mapToTitleID(await uploadArticle(
                            removeLeadingSlash(unZippedFile.name),
                            await unZippedFile.async("string"),
                        ));
                    }, { concurrency: (window.navigator && window.navigator.hardwareConcurrency) || 8 },
                );
            }
            if (fileExtension === "txt") {
                return mapToTitleID(await uploadArticle(file.name, await readFile(file)));
            }
            if (fileExtension === "pdf") {
                return mapToTitleID(await uploadArticle(file.name, await pdf2text(file)));
            }
            throw new Error("Unsupported file extension.");
        });

        const newFilesReduced = Promise.all(newFiles).then(newFilesResolved => flat(newFilesResolved));

        this.setState(({ filePromises }) => ({
            filePromises: filePromises.concat(newFilesReduced
                .then(newFilesResolved => Promise.all(newFilesResolved))),
        }));
        updateQuery("checkFiles", this.checkFiles);

        newFilesReduced.then(newFilesResolved => {
            const fs = defaultsAndQuery.files.concat(newFilesResolved);
            updateQuery("files", fs);
        });
    }

    render() {
        const { defaultsAndQuery } = this.props;

        return (
            // eslint-disable-next-line jsx-a11y/label-has-for
            <label htmlFor="fileSubmit">
                <h6 className={searchPageStyles.boxedTitle}>Submit your own files</h6>
                <div>
                    <small>Only include the articles you upload below</small>
                    <br />
                    <small className={searchPageStyles.noticeableGreen}>
                        Accepted formats are .txt, .pdf, and .zip of .txt
                    </small>
                    <br />
                    <AddFile id="fileSubmit" multiple addedFiles={this.handleFileAdd} />
                    {defaultsAndQuery.files.length > 0
                        ? <StudentFiles files={defaultsAndQuery.files} />
                        : ""}
                </div>
            </label>
        );
    }
}
