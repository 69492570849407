/**
 * Created by macdja38 on 2017-08-08.
 */

import React from "react";
import PropTypes from "prop-types";
import { button } from "./index.module.css";

function FullScreenButton(props) {
    const { isFullScreen } = props;

    return (
        <button
            type="button"
            onClick={() => props.onClick(!props.isFullScreen)}
            className={button}
        >
            {
                isFullScreen
                    ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 8 8">
                            <path
                                d={"M1 0l-1 1 1.5 1.5-1.5 1.5h4v-4l-1.5 1.5-1.5-1.5zm3 "
                                + "4v4l1.5-1.5 1.5 1.5 1-1-1.5-1.5 1.5-1.5h-4z"}
                            />
                        </svg>)
                    : (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 8 8">
                            <path
                                d={"M0 0v4l1.5-1.5 1.5 1.5 1-1-1.5-1.5 1.5-1.5h-4zm5 "
                                + "4l-1 1 1.5 1.5-1.5 1.5h4v-4l-1.5 1.5-1.5-1.5z"}
                            />
                        </svg>)
            }
        </button>
    );
}

FullScreenButton.propTypes = {
    isFullScreen: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};

FullScreenButton.defaultProps = {
    isFullScreen: false,
};

export default FullScreenButton;
