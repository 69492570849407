import React, { Component } from "react";
import PropTypes from "prop-types";
import { genIDs } from "../../utils";
import searchPageStyles from "../index.module.css";

function updateJournalQuality(defaultsAndQuery, updateQuery, type, event) {
    return updateQuery(
        "journalQuality",
        Object.assign({}, defaultsAndQuery.journalQuality || {}, { [type]: event.target.checked }),
    );
}

export default class SelectJournalQuality extends Component {
    static propTypes = {
        defaultsAndQuery: PropTypes.object.isRequired,
        updateQuery: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { uniqueIds: genIDs("AStar", "A", "B") };
    }

    render() {
        const { uniqueIds } = this.state;
        const { defaultsAndQuery, updateQuery } = this.props;

        return (
            <div>
                <h6 className={searchPageStyles.boxedTitle}>By Journal Quality</h6>
                <div className={searchPageStyles.journalQuality}>
                    <label htmlFor={uniqueIds.AStar}>
                        <input
                            id={uniqueIds.AStar}
                            type="checkbox"
                            onChange={event => updateJournalQuality(defaultsAndQuery, updateQuery, "A*", event)}
                            checked={defaultsAndQuery.journalQuality["A*"]}
                        />
                        -
                        A*
                    </label>
                    <label htmlFor={uniqueIds.A}>
                        <input
                            id={uniqueIds.A}
                            type="checkbox"
                            onChange={event => updateJournalQuality(defaultsAndQuery, updateQuery, "A", event)}
                            checked={defaultsAndQuery.journalQuality.A}
                        /> -
                        A
                    </label>
                    <label htmlFor={uniqueIds.B}>
                        <input
                            id={uniqueIds.B}
                            type="checkbox"
                            onChange={event => updateJournalQuality(defaultsAndQuery, updateQuery, "B", event)}
                            checked={defaultsAndQuery.journalQuality.B}
                        /> -
                        B
                    </label>
                    <br />
                </div>
            </div>
        );
    }
}
