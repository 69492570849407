import React, { Component } from "react";
import PropTypes from "prop-types";
import Chart from "./Components/Chart";
import { fetchJSON } from "../../utils";
import { backdropBlur } from "./index.module.css";

export default class StatsButton extends Component {
    static propTypes = {
        data: PropTypes.shape({
            submissions: PropTypes.array.isRequired,
            topics: PropTypes.array.isRequired,
            query: PropTypes.object.isRequired,
        }).isRequired,
        className: PropTypes.string,
    };

    static defaultProps = {
        className: "",
    };

    constructor(props) {
        super(props);
        this.inputId = Math.random();
        this.state = { showPopUp: false, masterList: false };
    }

    maybeFetchData() {
        fetchJSON("journals/list").then(json => {
            this.setState({ masterList: json.items });
        });
    }

    render() {
        const { showPopUp, masterList } = this.state;
        const { className, data } = this.props;

        return (
            <div className={className}>
                <div
                    style={{}}
                >
                    <div
                        className={backdropBlur}
                        style={{
                            display: showPopUp ? "block" : "none",
                            position: "fixed",
                            top: "0%",
                            bottom: "0%",
                            left: "0%",
                            right: "0%",
                            zIndex: "999",
                            // backgroundColor: "rgba(127, 127, 127, .9)",
                        }}
                    />
                    <div
                        style={{
                            display: showPopUp ? "block" : "none",
                            position: "fixed",
                            top: "5%",
                            bottom: "5%",
                            left: "5%",
                            right: "5%",
                            background: "#ffffff",
                            zIndex: "1000",
                            boxShadow: "10px 10px #111111",
                        }}
                    >
                        {masterList
                            ? (
                                <Chart
                                    masterList={masterList}
                                    data={data}
                                    close={() => {
                                        this.setState({ showPopUp: !showPopUp });
                                    }}
                                />)
                            : "Loading..."}
                    </div>
                </div>
                <button
                    style={{}}
                    type="button"
                    onClick={() => {
                        this.maybeFetchData();
                        this.setState({ showPopUp: !showPopUp });
                    }}
                >
                    Show Corpus Stats
                </button>
            </div>
        );
    }
}
