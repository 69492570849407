import React from "react";
import PropTypes from "prop-types";

import { chartTable } from "../index.module.css";

export default function Journals({ masterList, data }) {
    console.log(masterList, data);
    const ISSNToJournal = masterList.reduce((acc, journal) => {
        acc[journal.ISSN] = journal;
        return acc;
    }, {});
    return (
        <div style={{ height: "90%", overflow: "auto", display: "flex" }}>
            <table className={chartTable}>
                <thead>
                    <tr>
                        <th>Journals by Rating</th>
                        <th>&nbsp;</th>
                    </tr>
                    <tr>
                        <th>Rating</th>
                        <th>Articles</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Object.entries(
                            data.submissions
                                .map(article => {
                                    if (ISSNToJournal.hasOwnProperty(article.issueId)) {
                                        return ISSNToJournal[article.issueId].ABDCRating;
                                    }
                                    throw new Error("Article not in master list");
                                })
                                .reduce((acc, journalRating) => {
                                    acc[journalRating] = (acc[journalRating] || 0) + 1;
                                    return acc;
                                }, {}),
                        )
                            .sort(([, countA], [, countB]) => countB - countA)
                            .map(([journalRating, count]) => (
                                <tr key={journalRating}>
                                    <td>{journalRating}</td>
                                    <td>{count}</td>
                                </tr>))}
                    <tr>
                        <td>Grand Total</td>
                        <td>{data.submissions.length}</td>
                    </tr>
                </tbody>
            </table>
            <table className={chartTable}>
                <thead>
                    <tr>
                        <th>Articles by Year</th>
                        <th>&nbsp;</th>
                    </tr>
                    <tr>
                        <th>Year</th>
                        <th>Articles</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(
                        data.submissions
                            .map(article => new Date(article.createdAt).getFullYear())
                            .reduce((acc, year) => {
                                acc[year] = (acc[year] || 0) + 1;
                                return acc;
                            }, {}),
                    )
                        .sort(([yearA], [yearB]) => yearB - yearA)
                        .map(([year, count]) => (
                            <tr key={year}>
                                <td>{year}</td>
                                <td>{count}</td>
                            </tr>))}
                    <tr>
                        <td>Grand Total</td>
                        <td>{data.submissions.length}</td>
                    </tr>
                </tbody>
            </table>
            <table className={chartTable}>
                <thead>
                    <tr>
                        <th>Journals by Number of Articles</th>
                        <th>&nbsp;</th>
                    </tr>
                    <tr>
                        <th>Journals</th>
                        <th>Articles</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Object.entries(
                            data.submissions
                                .map(article => {
                                    if (ISSNToJournal.hasOwnProperty(article.issueId)) {
                                        return ISSNToJournal[article.issueId].journalTitle;
                                    }
                                    throw new Error("Article not in master list");
                                })
                                .reduce((acc, journalTitle) => {
                                    acc[journalTitle] = (acc[journalTitle] || 0) + 1;
                                    return acc;
                                }, {}),
                        )
                            .sort(([, countA], [, countB]) => countB - countA)
                            .map(([journalTitle, count]) => (
                                <tr key={journalTitle}>
                                    <td>{journalTitle}</td>
                                    <td>{count}</td>
                                </tr>))}
                    <tr>
                        <td>Grand Total</td>
                        <td>{data.submissions.length}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

Journals.propTypes = {
    data: PropTypes.shape({
        submissions: PropTypes.array.isRequired,
        topics: PropTypes.array.isRequired,
        query: PropTypes.object.isRequired,
    }).isRequired,
    masterList: PropTypes.arrayOf(PropTypes.object).isRequired,
};
