function getTopicList(topicThreshold, article) {
    return article.correlations
        .reduce((acc, entry, index) => {
            if (entry >= topicThreshold) {
                acc.push(index);
            }
            return acc;
        }, []);
    // .filter(([key, value]) => value >= topicThreshold)
    // .map(([key]) => parseInt(key, 10));
}

/*
function getRelationStrength(article, topics) {
    return article.correlations
        .filter((item, index) => topics.includes(index))
        .reduce((acc, cor) => acc + (cor || 0), 0);
}
*/

export function getTopicWords(topics, n, property, threshold) {
    return topics
        .map(topic => topic.keywordList.slice(0, n)
            .map(keyword => Object.assign(
                {},
                keyword,
                { [property]: parseFloat(keyword[property]) },
            ))
            .filter(keyword => keyword[property] > threshold))
        .reduce((acc, keywordList) => [...acc, ...keywordList], [])
        .sort((a, b) => b[property] - a[property])
        .map(keyword => keyword.word)
        .join(", ");
}

/**
 * Get's the string used to represent a topic
 * @param topics
 * @param {number) keywordLimit
 * @returns {string}
 */
export function getTopicDisplay(topics, keywordLimit) {
    const topicMeta = this.props.data.topics;

    if (topics.length === 1) {
        return `Topic ${topics.map(topic => topic + 1).join(" & ")}:${
            topics.map(t => topicMeta[t].keywordList.slice(0, keywordLimit).map(k => k.word).join(", ")).join(" & ")}`;
    }
    return `Topics ${topics.map(topic => topic + 1).join(" & ")}`;
}

function addToOrCreateList(topicThreshold, listHolder, article, mutualLinks) {
    const topics = getTopicList(topicThreshold, article);

    // express intention of this is to mutate the function param, so no re-assign is turned off
    article.correlations.forEach((correlation, topic) => {
        // eslint-disable-next-line no-param-reassign
        listHolder[topic.toString()].relationStrength += correlation || 0;
    });

    if (mutualLinks) {
        const group = topics.join(" & ");

        if (listHolder.hasOwnProperty(group)) {
            listHolder[group].articles.push(article);
        } else {
            // eslint-disable-next-line no-param-reassign
            listHolder[group] = { topics, relationStrength: 0, articles: [article] };
        }
    } else if (topics.length > 0) {
        topics.forEach(topic => {
            listHolder[topic.toString()].articles.push(article);
        });
    } else {
        listHolder[""].articles.push(article);
    }
}

export function reduceStats(stats) {
    const statSum = stats.reduce((acc, stat) => {
        acc.articles.push(...stat.articles);
        acc.relationStrength += stat.relationStrength;
        acc.normalizedRelationStrength += stat.normalizedRelationStrength;
        return acc;
    }, { articles: [], relationStrength: 0, normalizedRelationStrength: 0 });

    return statSum;
}

export default function assembleTopics(data, mutualLinks) {
    const { topicThreshold } = data.query;
    const { topics } = data;

    const articleLength = data.submissions.length;


    const groups = {
        "": { topics: [], relationStrength: 0, articles: [] },
    };

    for (let i = 0; i < topics.length; i += 1) {
        groups[i.toString()] = { topics: [i], relationStrength: 0, articles: [] };
    }

    data.submissions.forEach(article => addToOrCreateList(topicThreshold, groups, article, mutualLinks));

    return Object.values(groups).map((stat) => {
        // faster to modify then creating a new object.
        // eslint-disable-next-line no-param-reassign
        stat.normalizedRelationStrength = stat.relationStrength / articleLength;
        // eslint-disable-next-line no-param-reassign
        stat.topicData = [];
        stat.topics.forEach((topicIndex) => {
            stat.topicData.push(topics[topicIndex]);
        });
        return stat;
    });
}
