import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import App from "./App";
import { unregister } from "./registerServiceWorker";

import consts from "./consts";

if (consts.trackingID !== false) {
    ReactGA.initialize(consts.trackingID);
}

ReactGA.pageview("/");

ReactDOM.render(<App />, document.getElementById("root"));
unregister();
