import React, { Component } from "react";
import PropTypes from "prop-types";
import { genIDs } from "../../utils";
import searchPageStyles from "../index.module.css";

export default class SelectDocumentType extends Component {
    static propTypes = {
        defaultsAndQuery: PropTypes.object.isRequired,
        updateQuery: PropTypes.func.isRequired,
    };

    static updateDocumentType(defaultsAndQuery, updateQuery, type, event) {
        return updateQuery(
            "types",
            Object.assign({}, defaultsAndQuery.types || {}, { [type]: event.target.checked }),
        );
    }

    constructor(props) {
        super(props);
        this.state = { uniqueIds: genIDs("articles", "editorials", "timLectures", "qAndA") };
    }

    render() {
        const { uniqueIds } = this.state;
        const { defaultsAndQuery, updateQuery } = this.props;

        return (
            <div>
                <h6 className={searchPageStyles.boxedTitle}>By Document Type</h6>
                <div className={searchPageStyles.documentType}>
                    <label htmlFor={uniqueIds.articles}>
                        <input
                            id={uniqueIds.articles}
                            type="checkbox"
                            onChange={event => SelectDocumentType
                                .updateDocumentType(defaultsAndQuery, updateQuery, "article", event)}
                            defaultChecked
                        />
                        -
                        Articles
                    </label>
                    <label htmlFor={uniqueIds.editorials}>
                        <input
                            id={uniqueIds.editorials}
                            type="checkbox"
                            onChange={event => SelectDocumentType
                                .updateDocumentType(defaultsAndQuery, updateQuery, "editorials", event)}
                        /> -
                        Editorials
                    </label>
                    <label htmlFor={uniqueIds.timLectures}>
                        <input
                            id={uniqueIds.timLectures}
                            type="checkbox"
                            onChange={event => SelectDocumentType
                                .updateDocumentType(defaultsAndQuery, updateQuery, "timLecture", event)}
                        /> - TIM Lectures
                    </label>
                    <label htmlFor={uniqueIds.qAndA}>
                        <input
                            id={uniqueIds.qAndA}
                            type="checkbox"
                            onChange={event => SelectDocumentType
                                .updateDocumentType(defaultsAndQuery, updateQuery, "QAndA", event)}
                        /> - Q&A
                    </label>
                    <br />
                </div>
            </div>
        );
    }
}
