import React, { Component } from "react";
import PropTypes from "prop-types";
import MonthYearSelector from "./Components/MonthYearSelector";
import { genIDs } from "../../../utils";

import searchPageStyles from "../../index.module.css";

export default class SelectMonthYear extends Component {
    static propTypes = {
        defaultsAndQuery: PropTypes.object.isRequired,
        updateQuery: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { uniqueIds: genIDs("monthYearSelector") };
    }

    render() {
        const { uniqueIds } = this.state;
        const { defaultsAndQuery, updateQuery } = this.props;

        return (
            // eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for
            <label htmlFor={uniqueIds.monthYearSelector}>
                <h6 className={searchPageStyles.boxedTitle}>By Date Range</h6>
                <div>
                    <small>Only include articles that were published in this date range</small>
                    <br />
                    <MonthYearSelector
                        id={uniqueIds.monthYearSelector}
                        range={defaultsAndQuery.dateRange}
                        onChange={dateRange => updateQuery("dateRange", dateRange)}
                    />
                </div>
            </label>
        );
    }
}
