/**
 * Created by macdja38 on 2017-06-02.
 */

import React, { Component } from "react";
import PropTypes from "prop-types";
import NodeGraph from "../NodeGraph";
import ScatterPlot from "../ScatterPlot";
import ContextTable from "../ContextTable";
import CitationExport from "../CitationExport";
import StatisticsTable from "../StatisticsTable";
import StatsButton from "../StatsButton";
import FullScreenButton from "../FullScreenButton";
import DisplayOptions from "../DisplayOptions";

import { issuesProp } from "../../consts";

import { grid, nodeGraph, contextTable, barGraph, authorAudit } from "./index.module.css";

class DisplayTable extends Component {
    static propTypes = {
        data: PropTypes.shape({
            submissions: PropTypes.array.isRequired,
            topics: PropTypes.array.isRequired,
            query: PropTypes.object.isRequired,
        }).isRequired,
        nodesAndLinks: PropTypes.shape({
            nodes: PropTypes.array.isRequired,
            links: PropTypes.array.isRequired,
        }).isRequired,
        issues: PropTypes.oneOfType([issuesProp, PropTypes.bool]),
        authorAudit: PropTypes.bool,
        changeDisplayOptions: PropTypes.func.isRequired,
    };

    static defaultProps = {
        issues: false,
        authorAudit: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            showExport: false,
            exportArticles: [],
            exportTopicIndex: false,
            selected: props.nodesAndLinks.nodes[0],
            fullScreenElement: false,
        };
        this.onNodeClicked = this.onNodeClicked.bind(this);
        this.citationExport = this.citationExport.bind(this);
        this.onComplete = this.onComplete.bind(this);
        this.setFullScreen = this.setFullScreen.bind(this);
        this.escapeListener = this.escapeListener.bind(this);
    }

    /**
     * Selects a node that the user clicks
     * @param {number | Object} node
     * @param {string} [type]
     */
    onNodeClicked(node, type) {
        const { data, nodesAndLinks } = this.props;

        let nodeClicked;

        if (typeof type === "string" && (typeof node === "number" || typeof node === "string" || Array.isArray(node))) {
            switch (type) {
                case "article": {
                    const article = data.submissions.find(a => a.id === node);
                    nodeClicked = nodesAndLinks.nodes.find(n => n.data === article)
                        || Object.assign({
                            level: 2,
                            parents: [nodesAndLinks.nodes[0]],
                            data: article,
                        }, article);
                    break;
                }
                case "topic": {
                    if (node === -1) {
                        nodeClicked = {
                            type: "TOPIC",
                            level: 1,
                            parents: [nodesAndLinks.nodes[0]],
                            index: -1,
                            data: {
                                id: "-1",
                            },
                        };
                        break;
                    }

                    if (Array.isArray(node)) {
                        nodeClicked = node.map(topicIndex => {
                            const topic = data.topics.find(t => parseInt(t.id, 10) === topicIndex);
                            return nodesAndLinks.nodes.find(n => n.data === topic);
                        });
                    } else {
                        const topic = data.topics.find(t => parseInt(t.id, 10) === node);
                        nodeClicked = nodesAndLinks.nodes.find(n => n.data === topic);
                    }

                    break;
                }
                case "root": {
                    [nodeClicked] = nodesAndLinks.nodes;
                    break;
                }
                default:
                    throw new Error("Invalid node type");
            }
        } else if (node) {
            nodeClicked = node;
        } else {
            [nodeClicked] = nodesAndLinks.nodes;
        }

        if (nodeClicked.data && nodeClicked.data.hasOwnProperty("link")) {
            window.open(nodeClicked.data.link, "_blank");
        } else {
            this.setState({ selected: nodeClicked });
        }
    }

    onComplete() {
        this.setState({ showExport: false });
    }

    /**
     * Sets the full screen state of a sub component
     * @param {boolean} fullScreen true for full screen
     * @param {string} source ScatterPlot, StatisticsTable, NodeGraph or ContextTable
     */
    setFullScreen(source, fullScreen) {
        this.setState({ fullScreenElement: fullScreen && source });
        if (fullScreen) {
            document.onkeydown = this.escapeListener;
        } else {
            document.onkeydown = () => {
            };
        }
    }

    citationExport(articleList) {
        const exportTopicIndex = false;

        this.setState({
            showExport: true,
            exportArticles: articleList,
            exportTopicIndex,
        });
    }

    /**
     * Listens to the escape key and exits full screen
     * @param evt
     */
    escapeListener(evt) {
        const event = evt || window.event;
        let isEscape = false;
        if ("key" in event) {
            isEscape = (event.key === "Escape" || event.key === "Esc");
        } else {
            isEscape = (event.keyCode === 27);
        }
        if (isEscape) {
            this.setFullScreen("Escape", false);
        }
    }

    render() {
        const { selected, fullScreenElement, exportTopicIndex, exportArticles, showExport } = this.state;
        const { data, nodesAndLinks, issues, changeDisplayOptions } = this.props;

        const trendsOverTime = selected && selected.level === 1
            && data.query.source
            && (data.query.source === "tim" || data.query.source === "gers")
            && data.query.trendsOverTime;
        const fullScreenStyle = {
            gridColumnStart: "1",
            gridColumnEnd: "3",
            gridRowStart: "1",
            gridRowEnd: "3",
        };

        return (
            <div
                style={{
                    width: "100%", height: "calc(100vh - 70px)", display: "block", overflow: "hidden",
                }}
            >
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "min-content 1fr min-content",
                        gridTemplateRows: "min-content 1fr min-content",
                        height: "100%",
                        width: "100%",
                        padding: "0 2px 0 4px",
                    }}
                >
                    <div style={{ gridColumn: "1 / 4", gridRow: "1 / 4" }} className={grid}>
                        <div
                            className={nodeGraph}
                            style={Object.assign(
                                { verticalAlign: "top" },
                                fullScreenElement === "NodeGraph" ? fullScreenStyle : {},
                            )}
                        >
                            <NodeGraph
                                data={data}
                                selected={selected}
                                nodesAndLinks={nodesAndLinks}
                                onNodeClick={this.onNodeClicked}
                            />
                            <FullScreenButton
                                onClick={this.setFullScreen.bind(this, "NodeGraph")}
                                isFullScreen={fullScreenElement === "NodeGraph"}
                            />
                        </div>
                        <div
                            className={contextTable}
                            style={Object.assign(
                                { gridRowStart: trendsOverTime ? "span 1" : "span 2" },
                                fullScreenElement === "ContextTable" ? fullScreenStyle : {},
                            )}
                        >
                            <ContextTable
                                data={data}
                                nodesAndLinks={nodesAndLinks}
                                selected={selected}
                                citationExport={this.citationExport}
                                onNodeClick={this.onNodeClicked}
                                issues={issues}
                            />
                            <FullScreenButton
                                onClick={this.setFullScreen.bind(this, "ContextTable")}
                                isFullScreen={fullScreenElement === "ContextTable"}
                            />
                        </div>
                        <div
                            className={authorAudit}
                            style={Object.assign(
                                {},
                                fullScreenElement === "StatisticsTable" ? fullScreenStyle : {},
                            )}
                        >
                            <StatisticsTable
                                data={data}
                                selected={selected}
                                onNodeClick={this.onNodeClicked}
                                nodesAndLinks={nodesAndLinks}
                                displayOptions={fullScreenElement === "StatisticsTable"
                                    ? (
                                        <DisplayOptions
                                            query={data.query}
                                            changeDisplayOptions={changeDisplayOptions}
                                        />)
                                    : false}
                            />
                            <FullScreenButton
                                onClick={this.setFullScreen.bind(this, "StatisticsTable")}
                                isFullScreen={fullScreenElement === "StatisticsTable"}
                            />
                        </div>
                        {trendsOverTime
                            ? (
                                <div
                                    className={barGraph}
                                    style={Object.assign(
                                        {},
                                        fullScreenElement === "ScatterPlot" ? fullScreenStyle : {},
                                    )}
                                >
                                    <ScatterPlot
                                        topicIndexToGraph={selected.index}
                                        onNodeClick={this.onNodeClicked}
                                        data={data}
                                        nodesAndLinks={nodesAndLinks}
                                    />
                                    <FullScreenButton
                                        onClick={this.setFullScreen.bind(this, "ScatterPlot")}
                                        isFullScreen={fullScreenElement === "ScatterPlot"}
                                    />
                                </div>)
                            : ""}
                    </div>
                    {fullScreenElement !== "StatisticsTable"
                        ? (
                            <div
                                style={{
                                    gridArea: (`1 / ${fullScreenElement
                                        ? "2" : "1"} / 2 / ${fullScreenElement
                                        ? "4" : "3"}`),
                                    position: "relative",
                                    zIndex: 5,
                                    width: "50%",
                                    padding: "10px",
                                    justifySelf: (`${fullScreenElement ? "flex-end" : "flex-start"}`),
                                }}
                            >
                                <DisplayOptions
                                    query={data.query}
                                    changeDisplayOptions={changeDisplayOptions}
                                />
                                {data.query.source === "gers"
                                    ? (
                                        <StatsButton
                                            data={data}
                                        />)
                                    : ""}
                            </div>)
                        : ""}
                </div>
                <CitationExport
                    exportTopicIndex={exportTopicIndex}
                    articles={exportArticles}
                    topics={data.topics}
                    show={showExport}
                    done={this.onComplete}
                />
            </div>);
    }
}

export default DisplayTable;
