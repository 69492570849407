import React, { Component } from "react";
import PropTypes from "prop-types";
import staticParticles from "./static-particles.png";
import Particles from "./Particles/Particles";

import styles from "./index.module.css";

import searchPageStyles from "../../index.module.css";
import buttons from "../../../Styles/buttons.module.css";
import mAndP from "../../../Styles/marginsAndPaddings.module.css";


export default class HeaderParticles extends Component {
    static propTypes = {
        scrollHandler: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { broken: false };
    }

    componentDidCatch(error, info) {
        this.setState({ broken: true });
        console.log(info);
        console.error(error);
    }

    render() {
        const { broken } = this.state;
        const { scrollHandler } = this.props;

        const content = (
            <div className={searchPageStyles.heroCaption}>
                <button
                    type="button"
                    className={[buttons.btn, buttons.btnActionLightgray, mAndP["mt-2"]].join(" ")}
                    onClick={scrollHandler}
                >
                    Explore
                </button>
                <h3 className={styles.headerParticles}>J-tool</h3>
            </div>);

        console.log("broken", broken);

        if (broken) {
            return (
                <div
                    style={{ backgroundImage: `url(${staticParticles})` }}
                    className={[searchPageStyles.header, mAndP["no-m-p"], searchPageStyles.particlesJs].join(" ")}
                >
                    {content}
                </div>
            );
        }
        return (
            <Particles>
                {content}
            </Particles>
        );
    }
}
