import React, { Component } from "react";
import SweetAlert from "sweetalert-react";
import PropTypes from "prop-types";
import { generateShareLink } from "../utils";
import copy from "../es6-libs/copy-text-to-clipboard";

export default class ShareLinkButton extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        className: PropTypes.string,
    };

    static defaultProps = {
        className: "",
    };

    constructor(props) {
        super(props);
        this.state = { showShareLink: false };
    }

    render() {
        const { showShareLink } = this.state;
        const { id, className } = this.props;

        const shareLink = generateShareLink(id);

        return (
            <div className={className}>
                <SweetAlert
                    show={showShareLink}
                    title="Share Link"
                    text={
                        `<div style="word-break: break-all">${shareLink}</div>`
                    }
                    html
                    type="info"
                    showCancelButton
                    cancelButtonText="Not now"
                    confirmButtonText="Copy"
                    onCancel={() => this.setState({ showShareLink: false })}
                    onConfirm={() => {
                        copy(shareLink);
                        this.setState({ showShareLink: false });
                    }}
                />
                <button
                    style={{ width: "100%" }}
                    type="button"
                    onClick={() => {
                        this.setState({ showShareLink: true });
                    }}
                >
                    Generate
                    share
                    link
                </button>
            </div>
        );
    }
}
