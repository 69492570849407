/**
 * Created by macdja38 on 2017-06-02.
 */

import React, { Component } from "react";
import { axisBottom, axisLeft, max, min, scaleLinear, scaleOrdinal, select } from "d3";
import { keywordsProp, topicsProp } from "../../consts";

import { box, svg, selectCSS, selectYContainer, selectY, selectX, legend, moveSecondDown } from "./index.module.css";

function string2Int(string) {
    return parseInt(string, 10);
}

function string2Float(string) {
    return parseFloat(string);
}

// TODO: add better labels and titles
// TODO: make title text show on mouse over of select
export const axises = Object.entries({
    count: {
        type: "number",
        preProcessor: string2Int,
        label: "Count",
        title: "Number of occurrences of word in articles related to this topic",
        axises: ["x", "y"],
    },
    coherence: {
        type: "number",
        preProcessor: string2Float,
        label: "Coherence",
        title: "How often the word appears near other words of the same topic",
        axises: ["x", "y"],
    },
    word: {
        type: "string",
        getScaleX: (keywordList, width) => {
            const count = keywordList.length;
            return scaleOrdinal()
                .domain(keywordList.map(k => k.word))
                .range(keywordList.map(k => k.rank).map(n => (n - 1) * (width / count)));
        },
        getScaleY: (keywordList, height) => {
            const count = keywordList.length;
            return scaleOrdinal()
                .domain(keywordList.map(k => k.word))
                .range(keywordList.map(k => k.rank).map(n => (n) * (height / count)));
        },
        preProcessor: (string) => string,
        yWidth: 80,
        xHeight: 40,
        label: "Word",
        title: "The word itself",
        xCssClass: [moveSecondDown],
        axises: ["x", "y"],
    },
    docs: {
        type: "number",
        preProcessor: string2Int,
        label: "Documents",
        title: "Number of documents the word occurs in",
        axises: ["x", "y"],
    }, /*
    cumulative: {
        type: "number",
        preProcessor: string2Float,
        label: "cumulative",
        title: "cumulative",
        axises: ["x", "y"],
    }, */
    rank: {
        type: "number",
        preProcessor: string2Int,
        label: "Rank",
        title: "Relationship to the category vs other related words. 1 is strongest.",
        axises: ["x", "y"],
    },
    /* tokenDocDiff: {
            type: "number",
            preProcessor: string2Float,
            label: "tokenDocDiff",
            title: "tokenDocDiff",
            axises: ["x", "y"],
        },
        uniformDist: {
            type: "number",
            preProcessor: string2Float,
            label: "uniformDist",
            title: "uniformDist",
            axises: ["x", "y"],
        },
        corpusDist: {
            type: "number",
            preProcessor: string2Float,
            label: "Corpus Distinctiveness",
            title: "The distinctiveness of the word from the over-all body.",
            axises: ["x", "y"],
        }, */
    exclusivity: {
        type: "number",
        preProcessor: string2Float,
        label: "Exclusivity",
        title: "The extent to which the top words for this topic do not appear as top words in other topics.",
        axises: ["x", "y"],
    },
    prob: {
        type: "number",
        preProcessor: string2Float,
        label: "Prob",
        title: "Probability that a given word in an article related to the topic is this word",
        axises: ["x", "y"],
    },
    wordLength: {
        type: "number",
        preProcessor: string2Int,
        label: "Word length",
        title: "The length of the word",
        axises: ["x", "y"],
    },
    aliStrength: {
        type: "number",
        getScale: (keywordList, width, topicList) => {
            const largest = Math.max(...keywordList.map((keyword) => topicList
                .reduce((accum, topic) => {
                    const word = topic.keywordList.find(w => w.word === keyword.word);
                    if (word) {
                        return accum + string2Int(word.count);
                    }
                    return accum;
                }, 0)));

            return scaleLinear()
                .domain([0, largest])
                .range([0, width]);
        },
        preProcessor: string2Int,
        getBarParts: (topics, d) => {
            const topicWordCount = string2Int(d.count);

            return [
                {
                    style: "fill:rgb(0,176,240)",
                    value: topicWordCount,
                },
                {
                    style: "fill:rgb(180,216,229)",
                    value: topics.reduce(
                        (accumulator, topic) => accumulator
                            + string2Int((topic.keywordList.find(k => k.word === d.word) || { count: "0" }).count),
                        0,
                    ) - topicWordCount,
                }];
        },
        title: "this relevance word in topic in bar chart",
        label: "Relevance of word to topic",
        chartType: "bar",
        companionAxis: "word",
        axises: ["y", "x"],
        legend: (
            <div>
                dark blue: relevance to topic<br />
                light blue: relevance to other topics
            </div>),
    },
}).reduce((object, [key, value]) => {
    // eslint-disable-next-line no-param-reassign
    value.key = key;
    // eslint-disable-next-line no-param-reassign
    object[key] = value;
    return object;
}, {});

const axisEntries = Object.entries(axises);

class TwoAxisChart extends Component {
    constructor(...args) {
        super(...args);
        const { keywordList } = this.props;

        this.state = {
            xAxisMeta: axises.word,
            yAxisMeta: axises.exclusivity,
            xAxisMetaLock: false,
            yAxisMetaLock: false,
        };

        this.keys = Object.keys(keywordList[0]);
        this.redrawSVG = this.redrawSVG.bind(this);
        this.onAxisChange = this.onAxisChange.bind(this);
    }

    componentDidMount() {
        this.redrawSVG();
        window.addEventListener("resize", this.redrawSVG);
        setTimeout(this.redrawSVG, 0);
    }

    componentDidUpdate() {
        this.redrawSVG();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.redrawSVG);
    }

    onAxisChange(key, event) {
        const { target } = event;
        const { value } = target;
        const otherIndex = key === "xAxisMeta" ? "yAxisMeta" : "xAxisMeta";
        const newAxis = axises[value];
        const newState = { [key]: newAxis };
        if (newAxis.hasOwnProperty("companionAxis")) {
            newState[otherIndex] = axises[newAxis.companionAxis];
            newState[`${otherIndex}Lock`] = true;
        } else {
            newState[`${otherIndex}Lock`] = false;
        }
        this.setState(newState);
    }

    redrawSVG() {
        if (!this.svg) return;
        const { topics, keywordList } = this.props;

        this.svg.innerHTML = "";
        const { xAxisMeta, yAxisMeta } = this.state;

        const boundingRect = this.svg.getBoundingClientRect();

        const margin = {
            top: 15,
            right: 15,
            bottom: xAxisMeta.hasOwnProperty("xHeight") ? xAxisMeta.xHeight : 20,
            left: yAxisMeta.hasOwnProperty("yWidth") ? yAxisMeta.yWidth : 60,
        };
        const width = boundingRect.width - margin.left - margin.right;
        const height = boundingRect.height - margin.top - margin.bottom;

        function thingify(axisMeta, d) {
            if (axisMeta.hasOwnProperty("getBarParts")) {
                return axisMeta.getBarParts(topics, d);
            }
            return axisMeta.preProcessor(d[axisMeta.key]);
        }

        const thingX = thingify.bind(this, xAxisMeta);
        const thingY = thingify.bind(this, yAxisMeta);

        let x;
        if (xAxisMeta.hasOwnProperty("getScaleX")) {
            x = xAxisMeta.getScaleX(keywordList, width, topics);
        } else if (xAxisMeta.hasOwnProperty("getScale")) {
            x = xAxisMeta.getScale(keywordList, width, topics);
        } else {
            x = scaleLinear()
                .domain([min(keywordList, thingX), max(keywordList, thingX)])
                .range([0, width]);
        }

        let y;
        if (yAxisMeta.hasOwnProperty("getScaleY")) {
            y = yAxisMeta.getScaleY(keywordList, height, topics);
        } else if (yAxisMeta.hasOwnProperty("getScale")) {
            y = yAxisMeta.getScale(keywordList, height, topics);
        } else {
            y = scaleLinear()
                .domain([min(keywordList, thingY), max(keywordList, thingY)])
                .range([height, 1]);
        }

        const chart = select(this.svg)
            .attr("width", width + margin.right + margin.left)
            .attr("height", height + margin.top + margin.bottom)
            .attr("class", svg);

        const main = chart.append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`)
            .attr("width", width)
            .attr("height", height)
            .attr("class", "main");

        const g = main.append("svg:g");

        if (xAxisMeta.chartType === "bar" || yAxisMeta.chartType === "bar") {
            if (xAxisMeta.chartType === "bar") {
                const bars = keywordList.map(thingX);
                const bar = g.selectAll("g").data(bars).enter();
                const barHeight = height / keywordList.length;
                bar.append("g")
                    .attr("transform", (d, i) => `translate(0,${i * barHeight})`)
                    .append("rect")
                    .attr("width", (d) => x(d[0].value))
                    .attr("height", barHeight - 1)
                    .attr("style", (d) => d[0].style);

                bar.append("g")
                    .attr("transform", (d, i) => `translate(${x(d[0].value)},${i * barHeight})`)
                    .append("rect")
                    .attr("width", (d) => x(d[1].value))
                    .attr("height", barHeight - 1)
                    .attr("style", (d) => d[1].style);
            } else {
                const bars = keywordList.map(thingY);
                const bar = g.selectAll("g").data(bars).enter();
                const barHeight = width / keywordList.length;
                y = y.range([height, 1]);

                bar.append("g")
                    .attr("transform", (d, i) => `translate(${(i * barHeight) + 1},${y(d[0].value)})`)
                    .append("rect")
                    .attr("height", (d) => height - y(d[0].value))
                    .attr("width", barHeight - 1)
                    .attr("style", (d) => d[0].style);

                bar.append("g")
                    .attr("transform", (d, i) => `translate(${(i * barHeight) + 1},${y(d[1].value + d[0].value)})`)
                    .append("rect")
                    .attr("height", (d) => height - y(d[1].value))
                    .attr("width", barHeight - 1)
                    .attr("style", (d) => d[1].style);
            }
        } else {
            g.selectAll("scatter-dots")
                .data(keywordList)
                .enter()
                .append("svg:circle")
                .attr("cx", d => x((thingX(d))))
                .attr("cy", d => y(thingY(d)))
                .attr("r", 4)
                .append("svg:title")
                .text(d => d.word);
        }

        // draw the x axis
        const xAxis = axisBottom()
            .scale(x);

        const xAxisRender = main.append("g")
            .attr("transform", `translate(0,${height})`)
            .attr("class", "main axis date");
        if (xAxisMeta.hasOwnProperty("xCssClass") && xAxisMeta.xCssClass.length > 0) {
            xAxisRender.attr("class", `main axis date ${xAxisMeta.xCssClass.join(" ")}`);
        }
        xAxisRender.call(xAxis);

        // draw the y axis
        const yAxis = axisLeft()
            .scale(y);

        const yAxisRender = main.append("g")
            .attr("transform", "translate(0,0)")
            .attr("class", "main axis date");
        if (yAxisMeta.hasOwnProperty("yCssClass") && yAxisMeta.yCssClass.length > 0) {
            yAxisRender.attr("class", `main axis date ${yAxisMeta.xCssClass.join(" ")}`);
        }
        yAxisRender.call(yAxis);
    }

    render() {
        const { yAxisMeta, xAxisMeta, yAxisMetaLock, xAxisMetaLock } = this.state;

        const options = axisEntries.map(([key, axis]) => ({
            meta: axis,
            element: <option value={key} key={key}>{axis.label}</option>,
        }));
        const xOptions = options.filter(option => option.meta.axises.includes("x")).map(option => option.element);
        const yOptions = options.filter(option => option.meta.axises.includes("y")).map(option => option.element);
        return (
            <div className={box}>
                <svg
                    className={svg}
                    ref={svgElement => {
                        this.svg = svgElement;
                    }}
                />
                <div className={selectYContainer}>
                    <select
                        className={[selectCSS, selectY].join(" ")}
                        value={yAxisMeta.key}
                        title={yAxisMeta.title}
                        onChange={this.onAxisChange.bind(this, "yAxisMeta")}
                        disabled={yAxisMetaLock}
                    >
                        {yOptions}
                    </select>
                </div>
                <select
                    className={[selectCSS, selectX].join(" ")}
                    value={xAxisMeta.key}
                    title={xAxisMeta.title}
                    onChange={this.onAxisChange.bind(this, "xAxisMeta")}
                    disabled={xAxisMetaLock}
                >
                    {xOptions}
                </select>
                <div className={legend}>
                    {xAxisMeta.legend || yAxisMeta.legend ? <h6>Legend</h6> : null}
                    {xAxisMeta.legend}
                    {xAxisMeta.legend && yAxisMeta.legend ? <br /> : null}
                    {yAxisMeta.legend}
                </div>
            </div>
        );
    }
}

TwoAxisChart.propTypes = {
    // eslint-disable-next-line react/no-typos
    topics: topicsProp.isRequired,
    // eslint-disable-next-line react/no-typos
    keywordList: keywordsProp.isRequired,
};

export default TwoAxisChart;
