/* eslint-disable no-bitwise */
import queryString from "query-string";
import consts from "./consts";

let id = 0;

/**
 * Generate an incremental ID using.
 * @returns {number}
 */
export function genID() {
    // eslint-disable-next-line no-return-assign
    return id += 1;
}

/**
 * Generate an object containing the properties passed in as keys, and an incremental id as a value
 * @param {[string]} args
 * @returns {{number}}
 */
export function genIDs(...args) {
    return args.reduce((accumulator, key) => {
        // eslint-disable-next-line no-param-reassign
        accumulator[key] = genID();
        return accumulator;
    }, {});
}

/**
 * Parse a query string converting "true" and "false" to booleans.
 * @param string
 * @returns {{}}
 */
export function parseQueryString(string) {
    return Object.entries(queryString.parse(string))
        .map(([key, value]) => [key, value === "true" ? true : (value === "false" ? false : value)])
        .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
        }, {});
}

/**
 * Round a value to
 * @param {number} value
 * @param {number} [decimals = 2]
 * @returns {number}
 */
export function round(value, decimals = 2) {
    const multiple = 10 ** decimals;
    return Math.round(value * multiple) / multiple;
}

/**
 * Recursive flatten implementation till browsers implement it.
 * @param {[*]} array
 * @param {number} [depth = 1] depth to flatter, 1 will flatten arrays nested one deep, zero will do nothing.
 * @returns {[*]}
 */
export function flat(array, depth = 1) {
    return array.reduce((acc, item) => acc.concat(Array.isArray(item)
        ? (depth > 1 ? flat(item, depth - 1) : item)
        : [item]), []);
}

/**
 * Create chunks out of an array
 * @param {[*]} array
 * @param {number} chunkSize
 * @returns {[[*]]}
 */
export function chunk(array, chunkSize) {
    return array.reduce((accumulator, item, index) => {
        const chunkIndex = Math.floor(index / chunkSize);

        if (!accumulator[chunkIndex]) {
            // eslint-disable-next-line no-param-reassign
            accumulator[chunkIndex] = [];
        }

        // eslint-disable-next-line no-param-reassign
        accumulator[chunkIndex].push(item);

        return accumulator;
    }, []);
}

/**
 * Convert api response to json and check status code
 * @param response
 * @returns {Promise<Object>}
 */
function responseToJson(response) {
    if (response.status !== 200) {
        console.log(`Looks like there was a problem. Status Code: ${response.status}`);
        return response.text().then(text => {
            throw text;
        });
    }

    // Examine the text in the response
    return response.json();
}


export function uploadArticle(title, text = false, link = false, tries = 3) {
    let options;
    if (text) {
        options = { title, text };
    } else if (link) {
        options = { title, link };
    } else {
        throw new Error("Please supply either a link or article text");
    }
    return fetch(`${consts.api}/article/`, {
        method: "POST",
        mode: "cors",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(options),
    })
        .then(responseToJson)
        .catch(error => {
            if (tries > 0) {
                return uploadArticle(title, text, tries - 1);
            }
            console.error(error);
            throw error;
        });
}

export function fetchJSON(route, options = { tries: 3 }) {
    const fetchOptions = {
        method: options.method || "GET",
        mode: "cors",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    };
    if (options.hasOwnProperty("body")) {
        fetchOptions.body = JSON.stringify(options.body);
    }
    return fetch(`${consts.api}/${route}`, fetchOptions)
        .then(responseToJson)
        .catch(error => {
            if (options.hasOwnProperty("tries") ? options.tries >= 0 : true) {
                return fetchJSON(route, Object.assign({}, options.body, { tries: (options.tries || 3) - 1 }));
            }
            console.error(error);
            throw error;
        });
}

export function uploadArticles(articles, tries = 3) {
    return fetch(`${consts.api}/article/`, {
        method: "POST",
        mode: "cors",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ articles }),
    })
        .then(responseToJson)
        .then(response => response.articles)
        .catch(error => {
            if (tries > 0) {
                return uploadArticles(articles, tries - 1);
            }
            console.error(error);
            throw error;
        });
}

export function getSummary(articles) {
    return fetch(`${consts.api}/summarize/articles/?articles=${articles}`, {
        method: "GET",
        mode: "cors",
    })
        .then((response) => {
            if (response.status === 200) {
                return response.json();
            }
            throw response.error;
        });
}

export function uploadArticlesAutoBatch(articles, batchSize = 100, tries = 3) {
    return Promise.all(chunk(articles, batchSize).map(articlesChunk => uploadArticles(articlesChunk, tries)))
        .then(articlesChunks => flat(articlesChunks));
}

export function generateShareLink(cacheId) {
    return `https://jtool.cugcr.ca/display/${cacheId}`;
}

export function lightenDarkenColor(col, amount) {
    let usePound = false;

    let colour = col;

    if (colour[0] === "#") {
        colour = colour.slice(1);
        usePound = true;
    }

    const num = parseInt(colour, 16);

    let red = (num >> 16) + amount;

    if (red > 255) red = 255;
    else if (red < 0) red = 0;

    let blue = ((num >> 8) & 0x00FF) + amount;

    if (blue > 255) blue = 255;
    else if (blue < 0) blue = 0;

    let green = (num & 0x0000FF) + amount;

    if (green > 255) green = 255;
    else if (green < 0) green = 0;

    return `${usePound ? "#" : ""}${(green | (blue << 8) | (red << 16)).toString(16)}`;
}
