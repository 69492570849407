import React, { Component } from "react";
import PropTypes from "prop-types";
import { genIDs } from "../../utils";

import searchPageStyles from "../index.module.css";

export default class SelectTopicCount extends Component {
    static propTypes = {
        defaultsAndQuery: PropTypes.object.isRequired,
        updateQuery: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { uniqueIds: genIDs("topicCount") };
    }

    render() {
        const { uniqueIds } = this.state;
        const { defaultsAndQuery, updateQuery } = this.props;

        return (
            <label
                htmlFor={uniqueIds.topicCount}
            >
                <h6 className={searchPageStyles.boxedTitle}>Select your topic count</h6>
                <div>
                    <input
                        id={uniqueIds.topicCount}
                        type="number"
                        min="1"
                        max="100"
                        value={defaultsAndQuery.topicCount}
                        onChange={event => updateQuery("topicCount", event.target.value)}
                    />
                </div>
            </label>
        );
    }
}
