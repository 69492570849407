import React, { Component } from "react";
import PropTypes from "prop-types";
import stringify from "csv-stringify/lib/es5/sync";

import fileDownload from "js-file-download";
import { getTopicDisplay, getTopicWords } from "../statBuilder";
import { generateShareLink, round } from "../../../utils";

export default class CSVExport extends Component {
    static propTypes = {
        bridgingArticles: PropTypes.bool.isRequired,
        singleTopicStats: PropTypes.array.isRequired,
        multiTopicStats: PropTypes.array.isRequired,
        stats: PropTypes.array.isRequired,
        data: PropTypes.object.isRequired,
        keywordLimit: PropTypes.number.isRequired,
        noThemeStats: PropTypes.object.isRequired,
    };

    /**
     * Get's a table row for a specific set of articles (eg a topic, or all of them)
     * @param {string} title
     * @param {string} id
     * @param {Object} stats
     * @param {boolean} displayStrength toggle for displaying the topic Strength or N/A in it's place
     * @returns {Array<String>}
     */
    static getTableRow(title, id, stats, displayStrength) {
        return [
            title,
            stats.articles.length,
            displayStrength ? round(stats.normalizedRelationStrength, 2) : "",
            displayStrength ? round(stats.relationStrength, 2) : "",
            getTopicWords(stats.topicData, 10, "exclusivity", 0.75),
            getTopicWords(stats.topicData, 100, "exclusivity", 0.75),
            getTopicWords(stats.topicData, 10, "coherence", -0.25),
            getTopicWords(stats.topicData, 100, "coherence", -0.25),
        ];
    }

    /**
     * Get stats for articles in no theme
     * @param noThemeStats
     * @returns {Array<String>}
     */
    static getNoThemeStats(noThemeStats) {
        return CSVExport.getTableRow("Un-categorized", "un-categorized", noThemeStats, false);
    }

    constructor(props) {
        super(props);
        this.export = this.export.bind(this);
        this.getTopicDisplay = getTopicDisplay;
    }

    /**
     * Get Stats for articles in a set of themes
     * @param {[{topics: [number], articles: [Object}]} stats
     * @param {boolean} displayStrength
     * @returns {Array<string>}
     */
    getTopicRows(stats, displayStrength) {
        const { keywordLimit } = this.props;

        return stats.map((stat) => CSVExport.getTableRow(
            this.getTopicDisplay(stat.topics, keywordLimit),
            stat.topics.join("-"),
            stat,
            displayStrength,
        )).filter(r => r !== "");
    }

    /**
     * Get stats for articles in no theme
     * @param noThemeStats
     * @returns {Array<String>}
     */
    getAtLeastOneThemeStats(noThemeStats) {
        const { data } = this.props;

        const totalArticleCount = data.submissions.length;

        return [
            "Categorized",
            totalArticleCount - noThemeStats.articles.length,
        ];
    }

    export() {
        const { data, singleTopicStats, multiTopicStats, bridgingArticles, noThemeStats } = this.props;

        const table = [
            [
                generateShareLink(data.id),
                "Count",
                "Normalized Topic Strength",
                "Topic Strength",
                "Exclusive words from top 10 by count",
                "Exclusive words",
                "Coherent words from top 10 by count",
                "Coherent words",
            ],
            ["Topics"],
            ...this.getTopicRows(singleTopicStats, true),
        ];

        if (bridgingArticles) {
            table.push(["Bridging Articles"]);
            table.push(...this.getTopicRows(multiTopicStats, false));
        }

        table.push(["Other"]);

        table.push(this.getAtLeastOneThemeStats(noThemeStats));
        table.push(CSVExport.getNoThemeStats(noThemeStats));

        fileDownload(`\uFEFF${stringify(table)}`, "TopicStatistics.csv");
    }

    render() {
        return (
            <button type="button" onClick={this.export}>
                Export as CSV
            </button>
        );
    }
}
