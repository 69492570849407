/**
 * Created by macdja38 on 2017-07-13.
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { articlesProp, issuesProp, monthDayRange } from "../../consts";

class ArticleSelector extends Component {
    static propTypes = {
        range: PropTypes.oneOfType([monthDayRange, PropTypes.bool]),
        disabled: PropTypes.bool,
        articles: articlesProp.isRequired,
        issues: PropTypes.oneOfType([issuesProp, PropTypes.bool]),
    };

    static defaultProps = {
        range: false,
        disabled: false,
        issues: false,
    };

    get value() {
        let optionsArray;
        if (this.multiSelect) {
            optionsArray = [...this.multiSelect.options];
        } else {
            optionsArray = [];
        }
        const selectedOptions = optionsArray.filter((selector) => selector.selected);

        // if no options are selected return all of them
        return (selectedOptions.length > 0 ? selectedOptions : optionsArray)
            .map((selector) => selector.value);
    }

    render() {
        const { issues, disabled, range, articles } = this.props;

        if (!issues) {
            return <span>Loading...</span>;
        }
        return (
            <select
                ref={multiSelect => {
                    this.multiSelect = multiSelect;
                }}
                id="issueList"
                title="select issues from the list"
                name="selectIssue"
                size="10"
                multiple="multiple"
                disabled={disabled}
            >
                {
                    issues.dataBuckets.reduce((accumulator, group, year) => {
                        if (range) {
                            if (year < range.from.year) {
                                // eslint-disable-next-line no-param-reassign
                                delete accumulator[year];
                                return accumulator;
                            }
                            if (year > range.to.year) {
                                // eslint-disable-next-line no-param-reassign
                                delete accumulator[year];
                                return accumulator;
                            }
                            if (year === range.from.year) {
                                // eslint-disable-next-line no-param-reassign
                                accumulator[year] = accumulator[year]
                                    .filter(item => item.month >= (range.from.month - 1));
                            }
                            if (year === range.to.year) {
                                // eslint-disable-next-line no-param-reassign
                                accumulator[year] = accumulator[year]
                                    .filter(item => item.month <= (range.to.month - 1));
                            }
                        }
                        return accumulator;
                    }, issues.dataBuckets.slice(0))
                        .map((group, year) => {
                            const issuesForBucket = group.map(issue => {
                                const articlesInIssue = articles.filter(a => a.issue_id === issue.id);
                                if (articlesInIssue.length === 0) return null;
                                return articlesInIssue.map(article => (
                                    <option key={article.id} label={article.title}>{article.title}</option>));
                            }).filter(thing => thing !== null).reduce((accumulator, elem) => {
                                accumulator.push(...elem);
                                return accumulator;
                            }, []);
                            if (issuesForBucket.length === 0) {
                                return null;
                            }
                            return (
                                // in this case the array key is actually a consistent value for the entry so it's ok.
                                // eslint-disable-next-line react/no-array-index-key
                                <optgroup key={year} label={year}>
                                    {issuesForBucket}
                                </optgroup>
                            );
                        }).filter(thing => thing !== null)
                }
            </select>);
    }
}

export default ArticleSelector;
