import React, { Component } from "react";
import PropTypes from "prop-types";

// eslint-disable-next-line react/prefer-stateless-function
class FileItem extends Component {
    static propTypes = {
        file: PropTypes.shape({
            title: PropTypes.string.isRequired,
        }).isRequired,
    };

    render() {
        const { file } = this.props;

        return (
            <tr>
                <td>{file.title}</td>
            </tr>
        );
    }
}

export default FileItem;
