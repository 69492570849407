import React, { Component } from "react";
import PropTypes from "prop-types";
import { genIDs } from "../../utils";

import { ul } from "./index.module.css";
import { advancedCheckbox } from "../../Styles/checkboxes.module.css";


class DisplayOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uniqueIds: genIDs("topicThreshold", "bridgingArticles", "bigTopics", "variableLinks", "trendsOverTime"),
        };
        this.changeTopicThreshold = this.changeKey.bind(this, "topicThreshold");
        this.changeMutualLinks = this.changeKey.bind(this, "mutualLinks");
        this.changeBigTopics = this.changeKey.bind(this, "bigTopics");
        this.changeVariableLinks = this.changeKey.bind(this, "variableLinks");
        this.changeTrendsOverTime = this.changeKey.bind(this, "trendsOverTime");
    }

    changeKey(key, event) {
        const { changeDisplayOptions } = this.props;
        const { target } = event;

        changeDisplayOptions({ [key]: target.type === "checkbox" ? target.checked : target.value });
    }

    render() {
        const { uniqueIds } = this.state;
        const { query } = this.props;

        return (
            <ul className={ul}>
                <li>
                    <label htmlFor={uniqueIds.topicThreshold} className={advancedCheckbox}>
                        Topic threshold
                        <input
                            id={uniqueIds.topicThreshold}
                            type="number"
                            min="10"
                            max="99"
                            defaultValue={query.topicThreshold}
                            onChange={this.changeTopicThreshold}
                            style={{ marginLeft: "8px", marginRight: "8px" }}
                        />
                    </label>
                </li>

                <li>
                    <label htmlFor={uniqueIds.bridgingArticles} className={advancedCheckbox}>
                        Bridging articles
                        <input
                            id={uniqueIds.bridgingArticles}
                            type="checkbox"
                            title="display articles that meet the criteria to be in 2 topics as linked to both topics"
                            defaultChecked={query.mutualLinks}
                            onChange={this.changeMutualLinks}
                        />
                    </label>
                </li>

                <li>
                    <label htmlFor={uniqueIds.bigTopics} className={advancedCheckbox}>
                        Big Topics
                        <input
                            id={uniqueIds.bigTopics}
                            type="checkbox"
                            title="display topics as large circles with first keyword inside topic circle"
                            defaultChecked={query.bigTopics}
                            onChange={this.changeBigTopics}
                        />
                    </label>
                </li>
                <li>
                    <label htmlFor={uniqueIds.variableLinks} className={advancedCheckbox}>
                        Variable link length
                        <input
                            id={uniqueIds.variableLinks}
                            type="checkbox"
                            title="link length based on relation strength"
                            defaultChecked={query.variableLinks}
                            onChange={this.changeVariableLinks}
                        />
                    </label>
                </li>
                <li>
                    <label htmlFor={uniqueIds.trendsOverTime} className={advancedCheckbox}>
                        Trends over time
                        <input
                            id={uniqueIds.trendsOverTime}
                            type="checkbox"
                            title="if time data is available display trends of topics over time"
                            defaultChecked={query.trendsOverTime}
                            onChange={this.changeTrendsOverTime}
                        />
                    </label>
                </li>
            </ul>);
    }
}

DisplayOptions.propTypes = {
    query: PropTypes.object.isRequired,
    changeDisplayOptions: PropTypes.func.isRequired,
};

export default DisplayOptions;
