import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactList from "react-list";
import { getSummary, round } from "../../../utils";
import consts from "../../../consts";

import { buttonToLink } from "../../../Styles/buttons.module.css";
import { offsetTwentyRight } from "../../../Styles/misc.module.css";


export default class SmallArticleInfo extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        onNodeClick: PropTypes.func.isRequired,
        articles: consts.articles.isRequired,
        topicIndexes: PropTypes.oneOf([PropTypes.array, PropTypes.number, PropTypes.bool]),
        citationExport: PropTypes.func.isRequired,
        text: PropTypes.string.isRequired,
        onArticleSelected: PropTypes.func.isRequired,
        canDrillIntoTopic: PropTypes.func.isRequired,
        drillIntoTopic: PropTypes.func.isRequired,
        summarize: PropTypes.bool,
    };

    static defaultProps = {
        topicIndexes: undefined,
        summarize: false,
    };

    constructor() {
        super();
        this.state = {
            summaryMap: {},
        };
    }

    /**
     * Get's a reference based on an article id
     * @param {string} articles
     */
    getSummary(articles) {
        const articleToLocalMeta = (response) => (
            <p className={offsetTwentyRight} style={{ paddingRight: "20px" }}>
                {response.sentences.join(" ")}
            </p>);

        getSummary(articles)
            .then(articleToLocalMeta)
            .then((data) => {
                this.setState(({ summaryMap }) => (
                    { summaryMap: Object.assign({}, summaryMap, { [articles]: data }) }
                ));
            });

        return false;
    }

    render() {
        const { summaryMap } = this.state;
        const {
            summarize,
            articles,
            topicIndexes,
            canDrillIntoTopic,
            text,
            citationExport,
            drillIntoTopic,
            onArticleSelected,
        } = this.props;

        let meta = false;
        if (summarize) {
            const idString = articles.map(a => a.id).slice(0, 50).join(",");

            if (!summaryMap.hasOwnProperty(idString)) {
                this.getSummary(idString);
            } else {
                meta = summaryMap[idString];
            }
        }

        let sortedArticles = articles;
        if (topicIndexes !== undefined
            && (Array.isArray(topicIndexes) || topicIndexes !== -1)) {
            sortedArticles = articles
                .map(article => ({
                    article,
                    correlation: Array.isArray(topicIndexes)
                        ? topicIndexes.map(topicIndex => article
                            .correlations[topicIndex]).reduce((a, b) => a + b)
                        : topicIndexes,
                }))
                .sort((a, b) => b.correlation - a.correlation)
                .map(group => group.article);
        }
        const isAbleToDrillIntoTopic = canDrillIntoTopic(articles);

        return (
            <tr key="small-articles-info">
                <td>
                    <h5>
                        <span role="img" aria-label="small circle">&#x25CF;</span>
                        {text} ({articles.length})
                    </h5>
                    {summarize
                        ? (
                            <div className={offsetTwentyRight}>
                                <div>
                                    <h6>Summary:</h6>
                                    {meta || (
                                        <div style={{ height: "300px" }}>
                                            <p className={offsetTwentyRight}>Loading...</p>
                                        </div>)}
                                </div>
                            </div>)
                        : ""}
                    <div className={offsetTwentyRight}>
                        <button
                            type="button"
                            onClick={() => citationExport(articles, topicIndexes)}
                        >
                            Export all as citations
                        </button>
                        <button
                            type="button"
                            onClick={drillIntoTopic.bind(this, articles)}
                            disabled={!isAbleToDrillIntoTopic}
                            title={isAbleToDrillIntoTopic
                                ? "Drill down into topic"
                                : "Not enough articles to drill down"}
                        >
                            Drill down into topic
                        </button>
                        <br />
                        <ul style={{ overflow: "auto", maxHeight: 400 }}>
                            <ReactList
                                itemRenderer={(index, key) => {
                                    const article = sortedArticles[index];
                                    return (
                                        <li key={key}>
                                            <button
                                                type="button"
                                                className={buttonToLink}
                                                onClick={onArticleSelected.bind(this, article)}
                                                href={`https://timreview.ca/article/${article.id}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {(topicIndexes !== undefined
                                                    ? `[${Array.isArray(topicIndexes)
                                                        ? topicIndexes
                                                            .map(topicIndex => round(
                                                                article.correlations[topicIndex], 2,
                                                            ))
                                                            .join(",")
                                                        : round(article.correlations[topicIndexes], 2)}] `
                                                    : "") + article.title}
                                            </button>
                                        </li>);
                                }}
                                length={sortedArticles.length}
                                type="uniform"
                            />
                        </ul>
                    </div>
                </td>
            </tr>);
    }
}
