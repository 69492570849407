/**
 * Created by macdja38 on 2017-07-13.
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { articlesProp, monthDayRange } from "../../consts";

class ArticleSelector extends Component {
    static propTypes = {
        range: PropTypes.oneOfType([monthDayRange, PropTypes.bool]),
        disabled: PropTypes.bool,
        articles: articlesProp.isRequired,
    };

    static defaultProps = {
        range: false,
        disabled: false,
    };

    get value() {
        let optionsArray;
        if (this.multiSelect) {
            optionsArray = [...this.multiSelect.options];
        } else {
            optionsArray = [];
        }
        const selectedOptions = optionsArray.filter((selector) => selector.selected);

        // if no options are selected return all of them
        return (selectedOptions.length > 0 ? selectedOptions : optionsArray)
            .map((selector) => selector.value);
    }

    render() {
        const { disabled, articles } = this.props;

        return (
            <select
                ref={multiSelect => {
                    this.multiSelect = multiSelect;
                }}
                id="articleList"
                title="select articles from the list"
                name="selectArticles"
                size="10"
                multiple="multiple"
                disabled={disabled}
                className="selector"
            >
                {
                    articles.map(article => (
                        <option key={article.id} label={article.title}>{article.title}</option>))
                }
            </select>);
    }
}

export default ArticleSelector;
