import React, { Component } from "react";
import SweetAlert from "sweetalert-react";
import PropTypes from "prop-types";

export default class ChangeTitleButton extends Component {
    static propTypes = {
        className: PropTypes.string,
    };

    static defaultProps = {
        className: "",
    };

    constructor(props) {
        super(props);
        this.inputId = Math.random();
        this.state = { showPopUp: false };
    }

    render() {
        const { showPopUp } = this.state;
        const { className } = this.props;

        return (
            <div className={className}>
                <SweetAlert
                    show={showPopUp}
                    title="Change tab title"
                    text={
                        `<input style="display: block" id=${this.inputId} type="text" />`
                    }
                    html
                    type="info"
                    showCancelButton
                    cancelButtonText="Cancel"
                    confirmButtonText="Apply"
                    onCancel={() => this.setState({ showPopUp: false })}
                    onConfirm={() => {
                        const input = document.getElementById(this.inputId);
                        const title = document.getElementById("title");
                        title.innerText = input.value;
                        this.setState({ showPopUp: false });
                    }}
                />
                <button
                    style={{ width: "100%" }}
                    type="button"
                    onClick={() => {
                        this.setState({ showPopUp: true });
                    }}
                >
                    Change Tab Title
                </button>
            </div>
        );
    }
}
