import React, { Component } from "react";
import PropTypes from "prop-types";
import { genIDs } from "../../utils";

import searchPageStyles from "../index.module.css";

export default class SelectSearchTerm extends Component {
    static propTypes = {
        defaultsAndQuery: PropTypes.object.isRequired,
        updateQuery: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { uniqueIds: genIDs("searchTerm") };
    }

    render() {
        const { uniqueIds } = this.state;
        const { updateQuery } = this.props;

        return (
            <label htmlFor={uniqueIds.searchTerm}>
                <h6 className={searchPageStyles.boxedTitle}>By Search Term</h6>
                <div>
                    <small>Only include articles that contain the following words</small>
                    <br />
                    <small>Comma separated words</small>
                    <br />
                    <input
                        id={uniqueIds.searchTerm}
                        type="text"
                        onChange={event => updateQuery("search", event.target.value)}
                        placeholder="Enter search term"
                        style={{ marginTop: "10px", backgroundColor: "white" }}
                    />
                </div>
            </label>
        );
    }
}
