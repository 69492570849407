import React, { Component } from "react";
import PropTypes from "prop-types";
import "particles.js";
import particlesConfig from "../../../particles";

import searchPageStyles from "../../../index.module.css";
import mAndP from "../../../../Styles/marginsAndPaddings.module.css";

export default class Particles extends Component {
    static propTypes = {
        children: PropTypes.element.isRequired,
    };

    componentDidMount() {
        window.particlesJS("particles-js", particlesConfig);
    }

    componentWillUnmount() {
        if (window.pJSDom) {
            window.pJSDom.forEach(pJS => pJS.pJS.fn.vendors.destroypJS());
        }
    }

    render() {
        const { children } = this.props;

        return (
            <div
                id="particles-js"
                className={[searchPageStyles.header, mAndP["no-m-p"], searchPageStyles.particlesJs].join(" ")}
            >
                {children}
            </div>
        );
    }
}
