/**
 * Created by macdja38 on 2017-06-02.
 */

import React from "react";
import PropTypes from "prop-types";

export default function OptimalTopicList({ data }) {
    return (
        <div>
            <h6>Optimal topic numbers</h6>
            {data
                .sort((d1, d2) => d2.CoherenceCV - d1.CoherenceCV)
                .slice(0, 3)
                .map(modelDiagnostics => (
                    <a
                        style={{ padding: "5px" }}
                        key={modelDiagnostics.id}
                        href={`https://jtool.cugcr.ca/display/${modelDiagnostics.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {modelDiagnostics.numOfTopics}
                    </a>))}
        </div>
    );
}

OptimalTopicList.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        numOfTopics: PropTypes.number.isRequired,
        CoherenceCNpmi: PropTypes.number.isRequired,
        CoherenceCUci: PropTypes.number.isRequired,
        CoherenceCV: PropTypes.number.isRequired,
        CoherenceUMass: PropTypes.number.isRequired,
    }).isRequired).isRequired,
};
