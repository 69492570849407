/**
 * Created by macdja38 on 2017-06-02.
 */

import React from "react";
import PropTypes from "prop-types";

export default function TopicChartTable({ data }) {
    return (
        <div>
            <h6>Optimal topic numbers</h6>
            <table>
                <thead>
                    <tr>
                        <th>Number of Topics</th>
                        <th>
                            Coherence C
                            <sub>NPMI</sub>
                        </th>
                        <th>
                            Coherence C
                            <sub>UCI</sub>
                        </th>
                        <th>
                            Coherence C
                            <sub>V</sub>
                        </th>
                        <th>
                            Coherence C
                            <sub>UMass</sub>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data
                        .sort((d1, d2) => d2.CoherenceCV - d1.CoherenceCV)
                        .map(modelDiagnostics => (
                            <tr key={modelDiagnostics.numOfTopics}>
                                <td>{modelDiagnostics.numOfTopics}</td>
                                <td>{modelDiagnostics.CoherenceCNpmi}</td>
                                <td>{modelDiagnostics.CoherenceCUci}</td>
                                <td>{modelDiagnostics.CoherenceCV}</td>
                                <td>{modelDiagnostics.CoherenceUMass}</td>
                            </tr>))}
                </tbody>
            </table>
        </div>
    );
}

TopicChartTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        numOfTopics: PropTypes.number.isRequired,
        CoherenceCNpmi: PropTypes.number.isRequired,
        CoherenceCUci: PropTypes.number.isRequired,
        CoherenceCV: PropTypes.number.isRequired,
        CoherenceUMass: PropTypes.number.isRequired,
    }).isRequired).isRequired,
};
