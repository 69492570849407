/**
 * Created by macdja38 on 2017-06-01.
 */

import PropTypes from "prop-types";

// noinspection JSUnusedLocalSymbols
/**
 * get
 * @param {String} host
 * @param {Number} port
 * @param {String} path
 * @param {boolean} [https]
 */
// eslint-disable-next-line no-unused-vars
function getRelativeAPI(host, port, path, https) {
    const hostWithoutPort = host.replace(/:\d{1,6}\/?$/, "");
    const httpStart = https == null ? "//" : (https ? "https://" : "http://");
    return `${httpStart}${hostWithoutPort}${port ? `:${port}` : ""}${path || ""}`;
}

export const keywordProp = PropTypes.shape({
    count: PropTypes.string.isRequired,
    coherence: PropTypes.string.isRequired,
    word: PropTypes.string.isRequired,
    docs: PropTypes.string.isRequired,
    cumulative: PropTypes.string.isRequired,
    rank: PropTypes.string.isRequired,
    tokenDocDiff: PropTypes.string.isRequired,
    uniformDist: PropTypes.string.isRequired,
    corpusDist: PropTypes.string.isRequired,
    exclusivity: PropTypes.string.isRequired,
    prob: PropTypes.string.isRequired,
    wordLength: PropTypes.string.isRequired,
});

export const monthDayType = PropTypes.shape({
    month: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
});

export const monthDayRange = PropTypes.shape({
    from: monthDayType,
    to: monthDayType,
});

export const keywordsProp = PropTypes.arrayOf(keywordProp);

const issueProp = PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
});

export const articleProp = PropTypes.shape({
    authors: PropTypes.arrayOf(PropTypes.string).isRequired,
    correlations: PropTypes.arrayOf(PropTypes.number).isRequired,
    id: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    views: PropTypes.number,
    citations: PropTypes.number,
    title: PropTypes.string.isRequired,
    issueId: PropTypes.string,
});

export const authorProp = PropTypes.shape({
    articles: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
});
export const authorsProp = PropTypes.arrayOf(authorProp);

export const topicProp = PropTypes.shape({
    keywordList: keywordsProp.isRequired,
});

export const topicsProp = PropTypes.arrayOf(topicProp);
const issueArray = PropTypes.arrayOf(issueProp);

export const NUMBER_OF_KEYWORDS_TO_DISPLAY = 10;

const today = new Date();

const consts = {
    api: process.env.NODE_ENV === "production" ? "/api/v2" : getRelativeAPI(window.location.host, 3000, "/api/v2"),
    // api: "https://localhost:3000/api/v2",
    // api: "https://jtool.cugcr.ca/api/v2",
    issues: PropTypes.shape({
        data: issueArray.isRequired,
        dataBuckets: PropTypes.arrayOf(issueArray).isRequired,
    }),
    defaultDate: {
        from: { year: 2007, month: 7 },
        to: { year: today.getYear() + 1900, month: today.getMonth() + 1 },
    },
    defaultThreshold: 70,
    articles: PropTypes.arrayOf(articleProp),
    drillDownMinArticles: 20,
    articleRoot: "https://timreview.ca/article/",
    issueRoot: "https://timreview.ca/issue/",
    months: ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"],
    sentryDSN: "https://7e46bf2d9c0d4242bcaec5ac059c8d26@sentry.io/196110",
    trackingID: "UA-108628273-1",
};

export default consts;
export const issuesProp = consts.issues;
export const articlesProp = consts.articles;
