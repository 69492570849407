/**
 * Created by macdja38 on 2017-05-31.
 */

import React, { Component } from "react";
import "react-month-picker/css/month-picker.css";
import PropTypes from "prop-types";
import Picker from "react-month-picker";
import MonthBox from "./MonthBox";
import searchPageStyles from "../../../../index.module.css";

const monthDayType = PropTypes.shape({
    month: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
});

const today = new Date();

class MonthYearSelector extends Component {
    static propTypes = {
        range: PropTypes.shape({
            from: monthDayType,
            to: monthDayType,
        }),
        onChange: PropTypes.func,
    };

    static defaultProps = {
        range: {
            from: { year: 2007, month: 1 },
            to: { year: today.getYear() + 1900, month: today.getMonth() + 1 },
        },
        onChange: () => {
        },
    };

    constructor(props) {
        super(props);
        this.state = { from: props.range.from, to: props.range.to };
        this.pickerLang = {
            months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            from: "From",
            to: "To",
        };
        this.onChange = this.onChange.bind(this);
        this.handleClickMonthBox = this.handleClickMonthBox.bind(this);
    }

    onChange(year, month, field) {
        const { onChange } = this.props;

        let target;
        let notTarget;
        if (field === 0) {
            target = "from";
            notTarget = "to";
        } else {
            target = "to";
            notTarget = "from";
        }
        const newState = {
            [target]: { year, month },
            // eslint-disable-next-line react/destructuring-assignment
            [notTarget]: this.state[notTarget],
        };
        this.setState(newState);
        onChange(newState);
    }

    handleClickMonthBox() {
        this.pickRange.show();
    }

    makeText(m) {
        if (m && m.year && m.month) return (`${this.pickerLang.months[m.month - 1]}. ${m.year}`);
        return "?";
    }

    render() {
        const { from, to } = this.state;
        const { range } = this.props;

        return (
            <Picker
                className={searchPageStyles.datepicker}
                ref={pickRange => {
                    this.pickRange = pickRange;
                }}
                years={{ min: range.from, max: range.to }}
                range={{ from, to }}
                lang={this.pickerLang}
                theme="dark"
                onChange={this.onChange}
            >
                <MonthBox
                    value={`${this.makeText(from)} - ${this.makeText(to)}`}
                    onClick={this.handleClickMonthBox}
                />
            </Picker>
        );
    }
}

export default MonthYearSelector;
