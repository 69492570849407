/* eslint-disable no-param-reassign */
/**
 * Created by macdja38 on 2017-06-02.
 */

import React, { Component } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";

import throttle from "raf-throttle";

import styles from "./index.module.css";

class NodeGraph extends Component {
    static dragged() {
        d3.event.subject.fx = d3.event.x;
        d3.event.subject.fy = d3.event.y;
    }

    static drawLink(context, d) {
        context.moveTo(d.source.x, d.source.y);
        context.lineTo(d.target.x, d.target.y);
    }

    /**
     * Calculates the mouse position based on canvas element and mouse move event.
     * @param {canvas} canvas
     * @param {Event} event
     * @returns {{x: number, y: number}}
     */
    static getMousePos(canvas, event) {
        const rect = canvas.getBoundingClientRect();
        return {
            x: (event.clientX - rect.left),
            y: (event.clientY - rect.top),
        };
    }

    /**
     * Calculates distance between two sets of points using square root of sum of squares.
     * @param {number} x1
     * @param {number} y1
     * @param {number} x2
     * @param {number} y2
     * @returns {number} distance between the sets of points
     */
    static calcDistance(x1, y1, x2, y2) {
        const xDiff = x1 - x2;
        const yDiff = y1 - y2;
        return Math.sqrt((xDiff * xDiff) + (yDiff * yDiff));
    }

    static getClosestPointToEvent(event, radius, nodes, canvas, width, height) {
        const mousePos = NodeGraph.getMousePos(canvas, event);
        const tree = d3.quadtree().x(d => d.x).y(d => d.y).addAll(nodes);

        const x = (mousePos.x - (width / 2));
        const y = (mousePos.y - (height / 2));

        const node = tree.find(x, y, radius);
        return { node, distance: node ? NodeGraph.calcDistance(x, y, node.x, node.y) : Math.POSITIVE_INFINITY };
    }

    constructor(...args) {
        super(...args);
        const { nodesAndLinks } = this.props;

        this.ticked = this.ticked.bind(this);
        this.throttledTick = throttle(this.ticked);
        this.drawNode = this.drawNode.bind(this);
        this.drawNodeBig = this.drawNodeBig.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseClick = this.onMouseClick.bind(this);
        this.dragSubject = this.dragSubject.bind(this);
        this.dragStarted = this.dragStarted.bind(this);
        this.dragEnded = this.dragEnded.bind(this);
        this.newCanvasReceived = this.newCanvasReceived.bind(this);
        this.updateNodesAndLinks(nodesAndLinks);
        this.bigTopics = nodesAndLinks.bigTopics;
        this.variableLength = nodesAndLinks.variableLength;
        this.mutualLinks = nodesAndLinks.mutualLinks;
        this.mounted = new Promise(resolve => {
            this.resolveMounted = resolve;
        });
    }

    componentDidMount() {
        this.resolveMounted();
        this.updateSelectedParents(this.props);
    }

    componentWillReceiveProps(newProps) {
        this.updateSelectedParents(newProps);
        this.stopSimulation();
        this.updateNodesAndLinks(newProps.nodesAndLinks);
        this.bigTopics = newProps.nodesAndLinks.bigTopics;
        this.mounted.then(() => {
            this.renderWeb(this.renderTarget, { nodes: this.nodes, links: this.links });
        });
    }

    componentWillUnmount() {
        this.stopSimulation();
    }

    onMouseMove(event) {
        const { node: newClosestPoint, distance } = NodeGraph.getClosestPointToEvent(
            event,
            100,
            this.nodes,
            this.renderTarget,
            this.visibleWidth,
            this.visibleHeight,
        );
        if (newClosestPoint !== this.closestPoint || distance !== this.closestPointDistance) {
            this.closestPoint = newClosestPoint;
            this.closestPointDistance = distance;
            if (Date.now() - this.lastTicked > 25) {
                this.ticked();
            }
        }
    }

    onMouseClick(event) {
        const { onNodeClick } = this.props;

        const { node: closestPoint } = NodeGraph.getClosestPointToEvent(
            event,
            100,
            this.nodes,
            this.renderTarget,
            this.visibleWidth,
            this.visibleHeight,
        );
        if (!closestPoint) return;
        onNodeClick(closestPoint);
    }

    updateNodesAndLinks({ nodes, links }) {
        this.nodes = nodes;
        this.links = links;
    }

    correctForZoom(context) {
        const devicePixelRatio = window.devicePixelRatio || 1;
        const backingStoreRatio = context.webkitBackingStorePixelRatio
            || context.mozBackingStorePixelRatio
            || context.msBackingStorePixelRatio
            || context.oBackingStorePixelRatio
            || context.backingStorePixelRatio
            || 1;

        const ratio = devicePixelRatio / backingStoreRatio;

        const oldWidth = parseFloat(this.renderTarget.style.width)
            || this.renderTarget.innerWidth || this.renderTarget.offsetWidth || this.renderTarget.clientWidth;
        const oldHeight = parseFloat(this.renderTarget.style.height)
            || this.renderTarget.innerHeight || this.renderTarget.offsetHeight || this.renderTarget.clientHeight;

        if (this.ratio !== ratio || (oldWidth * ratio) !== this.renderTarget.width
            || (oldHeight * ratio) !== this.renderTarget.height) {
            this.ratio = ratio;

            this.renderTarget.width = oldWidth * ratio;
            this.renderTarget.height = oldHeight * ratio;

            this.renderTarget.style.width = `${oldWidth}px`;
            this.renderTarget.style.height = `${oldHeight}px`;

            // now scale the context to counter
            // the fact that we've manually scaled
            // our canvas element
            context.scale(ratio, ratio);

            // this.renderTarget.setAttribute("style", "background: white");
            this.renderTarget.setAttribute("style", "");
            this.backingWidth = this.renderTarget.style.width || this.renderTarget.width;
            this.backingHeight = this.renderTarget.style.height || this.renderTarget.height;
            this.visibleWidth = this.backingWidth / ratio;
            this.visibleHeight = this.backingHeight / ratio;
        }
    }

    dragStarted() {
        if (!d3.event.active) this.currentSimulation.alphaTarget(0.3).restart();
        d3.event.subject.fx = d3.event.subject.x;
        d3.event.subject.fy = d3.event.subject.y;
    }

    dragEnded() {
        if (!d3.event.active) this.currentSimulation.alphaTarget(0);
        d3.event.subject.fx = null;
        d3.event.subject.fy = null;
    }

    dragSubject() {
        return this.currentSimulation.find(d3.event.x - (this.visibleWidth / 2), d3.event.y - (this.visibleHeight / 2));
    }

    drawNodeBig(context, d) {
        context.beginPath();
        context.moveTo(d.x + d.size, d.y);
        if (d.keywordList != null) {
            // draw big bubbles with words inside them
            context.arc(d.x, d.y, 50, 0, 2 * Math.PI);
        } else {
            context.arc(d.x, d.y, d.size, 0, 2 * Math.PI);
        }

        context.textAlign = "left";
        if (this.closestPoint != null && (d.title)) {
            if (d.title === this.closestPoint.title) {
                context.fillText(d.title, d.x + 10, d.y + 5);
            }
        }

        if (this.closestPoint != null && (d.index === this.closestPoint.index) && this.closestPointDistance < d.size) {
            if (this.leftMouseDown) {
                context.fillStyle = "#0f0";
            } else {
                context.fillStyle = "#f00";
            }
        } else {
            context.fillStyle = "#000";
        }

        context.textAlign = "center";
        context.fill();

        if (d.keywordList != null) {
            context.strokeStyle = "#000";
            context.fillStyle = "#ffffff";
            context.fillText(d.keywordList.slice(0, 1).map(k => k.word).join(" "), d.x, d.y, 100);
            context.stroke();
            context.strokeStyle = "#000";
        } else {
            context.strokeStyle = "#fff";
        }

        context.strokeStyle = "#fff";
        context.stroke();
    }

    drawNode(context, d) {
        const { selected } = this.props;

        context.beginPath();
        context.moveTo(d.x + d.size, d.y);
        context.arc(d.x, d.y, d.size, 0, 2 * Math.PI);

        context.fillStyle = "#000";
        if (this.parentTree.indexOf(d) > -1) {
            context.fillStyle = "#00B0F0";
        }
        if (selected === d || (Array.isArray(selected) && selected.includes(d))) {
            context.fillStyle = "#C33";
        }

        if (this.closestPoint != null && (d.title || d.level === 1)) {
            if (d.index === this.closestPoint.index) {
                const oldFillStyle = context.fillStyle;
                context.fillStyle = "#000";
                context.fillText(d.title
                    || d.keywordList.slice(0, 9).map(k => k.word).join(", ").replace(/_/g, " "), d.x + 10, d.y + 5);
                context.fillStyle = oldFillStyle;
                if (this.closestPointDistance < d.size) {
                    context.fillStyle = "#92D052";
                }
            }
        }

        context.fill();
        context.strokeStyle = "#fff";
        context.stroke();
    }

    ticked() {
        const context = this.renderTarget.getContext("2d");
        this.correctForZoom(context);
        this.renderTarget.width = this.backingWidth;
        this.renderTarget.height = this.backingHeight;
        context.clearRect(0, 0, this.backingWidth, this.backingHeight);
        context.save();
        // now scale the context to counter
        // the fact that we've manually scaled
        // our canvas element
        context.scale(this.ratio, this.ratio);

        context.translate(this.visibleWidth / 2, this.visibleHeight / 2);

        context.beginPath();
        this.links.forEach(d => NodeGraph.drawLink(context, d));
        context.strokeStyle = "#aaa";
        context.stroke();

        if (this.bigTopics) {
            this.nodes.forEach(this.drawNodeBig.bind(this, context));
        } else {
            this.nodes.forEach(this.drawNode.bind(this, context));
        }

        context.restore();
        this.lastTicked = Date.now();
    }

    createSimulation(nodes, links) {
        return d3.forceSimulation(nodes)
            .force("charge", d3.forceManyBody().strength(d => d.strength || -30))
            .force("link", d3.forceLink(links).distance(d => d.distance || 20)
                .strength(d => d.strength || 1))
            .force("x", d3.forceX())
            .force("y", d3.forceY())
            .on("tick", this.ticked);
    }

    stopSimulation() {
        if (this.currentSimulation) {
            this.currentSimulation.stop();
        }
        if (this.renderTarget) {
            this.renderTarget.removeEventListener("mousemove", this.onMouseMove);
            this.renderTarget.removeEventListener("click", this.onMouseClick);
        }
        window.removeEventListener("resize", this.throttledTick);
    }

    updateSelectedParents(newProps) {
        this.parentTree = [];
        if (newProps.selected) {
            console.log("props selected", newProps.selected);
            const getParentsArray = (node) => {
                let nodes;
                if (!Array.isArray(node)) {
                    nodes = [node];
                } else {
                    nodes = node;
                }

                return nodes.reduce((acc, nodeListEntry) => {
                    acc.push(...nodeListEntry.parents.reduce((accumulator, parent) => {
                        accumulator.push(...getParentsArray(parent));
                        return accumulator;
                    }, []));
                    return acc;
                }, [...nodes]);
            };
            this.parentTree = getParentsArray(newProps.selected);
            console.log(this.parentTree);
        }
    }

    newCanvasReceived(canvas) {
        if (canvas == null) return;
        this.stopSimulation();
        this.renderTarget = canvas;
        this.mounted.then(() => {
            this.renderWeb(this.renderTarget, { nodes: this.nodes, links: this.links });
        });
    }

    /**
     * Renders a web of nodes and links
     * @param canvas Canvas dom element
     * @param {Array} nodes
     * @param {Array} links
     */
    renderWeb(canvas, { nodes, links }) {
        this.closestPoint = null;
        this.closestPointDistance = Math.POSITIVE_INFINITY;
        this.lastTicked = Date.now();
        this.leftMouseDown = false;

        this.currentSimulation = this.createSimulation(nodes, links);

        this.context = canvas.getContext("2d");
        this.correctForZoom(this.context);

        const d3Canvas = d3.select(canvas);
        d3Canvas.call(d3.drag()
            .container(canvas)
            .subject(this.dragSubject)
            .on("start", this.dragStarted)
            .on("drag", NodeGraph.dragged)
            .on("end", this.dragEnded));

        window.addEventListener("resize", this.throttledTick);

        canvas.addEventListener("mousemove", this.onMouseMove);

        canvas.addEventListener("click", this.onMouseClick, false);

        d3Canvas.on("mousedown", () => { this.leftMouseDown = true; });

        d3Canvas.on("mouseup", () => { this.leftMouseDown = false; });
    }

    render() {
        return (<canvas className={styles.nodeGraphCanvas} ref={this.newCanvasReceived} />);
    }
}

NodeGraph.propTypes = {
    data: PropTypes.shape({
        submissions: PropTypes.array.isRequired,
        topics: PropTypes.array.isRequired,
    }).isRequired,
    nodesAndLinks: PropTypes.shape({
        nodes: PropTypes.array.isRequired,
        links: PropTypes.array.isRequired,
        bigTopics: PropTypes.bool,
        variableLength: PropTypes.bool,
        mutualLinks: PropTypes.bool,
    }).isRequired,
    selected: PropTypes.oneOfType([PropTypes.object, PropTypes.bool, PropTypes.arrayOf(PropTypes.object)]),
    onNodeClick: PropTypes.func,
};

NodeGraph.defaultProps = {
    onNodeClick: () => {
    },
    selected: false,
};

export default NodeGraph;
