/**
 * Created by macdja38 on 2017-05-31.
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import CSVExport from "./CSVExport";
import statBuilder, { getTopicDisplay, getTopicWords } from "./statBuilder";

import { lightenDarkenColor, round } from "../../utils";

import { cleanTable } from "./index.module.css";
import { buttonToLeftLink } from "../../Styles/buttons.module.css";

const extraColumns = 24;

/**
 * Component that creates the author audit table
 */
class StatisticsTable extends Component {
    constructor(props) {
        super(props);
        this.state = { keywordLimit: 3 };
        window.changeKeywordLimit = limit => this.setState({ keywordLimit: limit });

        this.getTopicDisplay = getTopicDisplay;
    }

    /**
     * Get Stats for articles in a set of themes
     * @param {[{topics: [number], articles: [Object}]} stats
     * @returns {Array<string>}
     */
    getTopicRows(stats, displayStrength) {
        const { keywordLimit } = this.state;

        console.log(stats);

        return stats.map((stat) => (
            this.getTableRow(
                this.getTopicDisplay(stat.topics, keywordLimit),
                stat.topics.join("-"),
                stat,
                displayStrength,
                stat.topics.length >= 1 ? stat.topics : null,
            ))).filter(r => r !== "");
    }

    /**
     * Get stats for articles in no theme
     * @param noThemeStats
     * @returns {string}
     */
    getNoThemeStats(noThemeStats) {
        return this.getTableRow(
            <td>{this.getNodeClickButton(-1, "Un-categorized")}</td>,
            "un-categorized",
            noThemeStats,
            false,
        );
    }

    /**
     * Get stats for articles in no theme
     * @param noThemeStats
     * @returns {string}
     */
    getAtLeastOneThemeStats(noThemeStats) {
        const { data } = this.props;
        const totalArticleCount = data.submissions.length;

        return (
            <tr key="categorized">
                <td>Categorized</td>
                <td>{totalArticleCount - noThemeStats.articles.length}</td>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
            </tr>
        );
    }

    /**
     * Creates a button that looks like a link that changes the display state to a specific topic index
     * @param {[number]} topicIndexes
     * @param text
     * @returns {XML}
     */
    getNodeClickButton(topicIndexes, text) {
        const { onNodeClick } = this.props;

        const func = () => {
            // const node = this.props.nodesAndLinks.nodes[topicIndex + 1];
            onNodeClick(topicIndexes, "topic");
        };
        return <button type="button" className={buttonToLeftLink} onClick={func}>{text}</button>;
    }


    /**
     * Get's a table row for a specific set of articles (eg a topic, or all of them)
     * @param {string} title
     * @param {string} id
     * @param {Object} stats
     * @param {boolean} displayStrength toggle for displaying the topic Strength or N/A in it's place
     * @param {[number]} [topicIndexes]
     * @returns {XML}
     */
    getTableRow(title, id, stats, displayStrength, topicIndexes) {
        const { selected } = this.props;
        const rowStyle = {};

        if (selected && Array.isArray(selected) && topicIndexes) {
            const degree = selected
                .map(selectedNode => selectedNode.index - 1)
                .filter(index => topicIndexes.includes(index))
                .length;
            const total = selected.length;
            if (degree > 0) {
                rowStyle.backgroundColor = lightenDarkenColor("#92D052", (1 - (degree / total)) * 100);
            }
        } else if (selected && selected.level === 1 && topicIndexes) {
            // lonely if makes more sense here based on context.
            // eslint-disable-next-line no-lonely-if
            if (topicIndexes.includes(selected.index - 1)) {
                rowStyle.backgroundColor = "#92D052";
            }
        }

        return (
            <tr key={id} style={rowStyle}>
                {typeof title === "string"
                    ? <td>{topicIndexes != null ? this.getNodeClickButton(topicIndexes, title) : title}</td>
                    : title}
                <td>{stats.articles.length}</td>
                <td>{displayStrength ? round(stats.normalizedRelationStrength, 2) : null}</td>
                <td>{displayStrength ? round(stats.relationStrength, 2) : null}</td>
                <td>
                    {
                        getTopicWords(stats.topicData, 10, "exclusivity", 0.75)
                    }
                </td>
                <td>
                    {
                        getTopicWords(stats.topicData, 100, "exclusivity", 0.75)
                    }
                </td>
                <td>
                    {
                        getTopicWords(stats.topicData, 10, "coherence", -0.25)
                    }
                </td>
                <td>
                    {
                        getTopicWords(stats.topicData, 100, "coherence", -0.25)
                    }
                </td>
            </tr>);
    }

    /**
     * two arrays of equal length each of which have a topicIndex property
     * @param {Array} arr1
     * @param {Array} arr2
     */
    recursiveCompare(arr1, arr2) {
        if (arr1.length === 0) return 0;
        if (arr1[0].topicIndex === arr2[0].topicIndex) {
            return this.recursiveCompare(arr1.slice(1), arr2.slice(1));
        }
        return arr1[0].topicIndex - arr2[0].topicIndex;
    }

    render() {
        const { keywordLimit } = this.state;
        const { data, displayOptions } = this.props;

        const bridgingArticles = data.query.mutualLinks || false;

        const stats = statBuilder(data, bridgingArticles);

        console.log("stats", stats);

        const singleTopicStats = stats.filter(stat => stat.topics.length === 1)
            .sort((a, b) => b.relationStrength - a.relationStrength);
        const multiTopicStats = stats.filter(stat => stat.topics.length > 1)
            .sort((a, b) => b.relationStrength - a.relationStrength);
        const noThemeStats = stats.filter(stat => stat.topics.length === 0)[0];

        return (
            <table className={cleanTable}>
                <colgroup />
                <thead>
                    <tr>
                        <th colSpan={7}>Topic Statistics</th>
                        <th colSpan={1}><CSVExport
                            bridgingArticles={bridgingArticles}
                            singleTopicStats={singleTopicStats}
                            multiTopicStats={multiTopicStats}
                            stats={stats}
                            keywordLimit={keywordLimit}
                            data={data}
                            noThemeStats={noThemeStats}
                        />
                        </th>
                    </tr>
                    <tr>
                        <th style={{ backgroundColor: displayOptions ? "#e0dbdb" : "#ffffff" }}>
                            {displayOptions || null}
                        </th>
                        <th>Count</th>
                        <th>Normalized Topic Strength</th>
                        <th>Topic Strength</th>
                        <th>Exclusive words from top 10 by count</th>
                        <th>Exclusive words</th>
                        <th>Coherent words from top 10 by count</th>
                        <th>Coherent words</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>Topics</th>
                        <td colSpan={extraColumns} />
                    </tr>
                    {this.getTopicRows(singleTopicStats, true)}
                    {bridgingArticles
                        ? (
                            <tr>
                                <th>Bridging Articles</th>
                                <td colSpan={extraColumns} />
                            </tr>)
                        : null}
                    {bridgingArticles
                        ? this.getTopicRows(multiTopicStats, false)
                        : null}
                    <tr>
                        <th>Other</th>
                        <td colSpan={extraColumns} />
                    </tr>
                    {this.getAtLeastOneThemeStats(noThemeStats)}
                    {this.getNoThemeStats(noThemeStats)}
                </tbody>
            </table>);
    }
}

StatisticsTable.propTypes = {
    data: PropTypes.object.isRequired,
    // eslint-disable-next-line react/no-typos
    selected: PropTypes.oneOfType([PropTypes.object, PropTypes.bool, PropTypes.arrayOf(PropTypes.object)]),
    displayOptions: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
    onNodeClick: PropTypes.func.isRequired,
    nodesAndLinks: PropTypes.shape({
        nodes: PropTypes.array.isRequired,
        links: PropTypes.array.isRequired,
    }).isRequired,
};

StatisticsTable.defaultProps = {
    selected: false,
    displayOptions: false,
};

export default StatisticsTable;
