import React, { Component } from "react";
import PropTypes from "prop-types";
import { genIDs } from "../../../../../utils";

class MonthBox extends Component {
    static propTypes = {
        value: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
    };

    constructor(props, context) {
        super(props, context);

        const { value } = props;

        this.state = {
            value: value || "N/A",
            uniqueIds: genIDs("monthBox"),
        };

        this.handleClick = this.handleClick.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.value || "N/A",
        });
    }

    handleClick(e) {
        const { onClick } = this.props;

        if (onClick) onClick(e);
    }

    render() {
        const { uniqueIds, value } = this.state;

        return (
            // eslint-disable-next-line jsx-a11y/label-has-for
            <label htmlFor={uniqueIds.monthBox}>
                <div
                    tabIndex="0"
                    id={uniqueIds.monthBox}
                    role="button"
                    className="box"
                    onClick={this.handleClick}
                    onKeyDown={this.handleClick}
                >
                    {value}
                </div>
            </label>
        );
    }
}

export default MonthBox;
