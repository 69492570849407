/**
 * Created by macdja38 on 2017-05-31.
 */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import queryString from "query-string";
import * as ReactGA from "react-ga";
import fetch from "isomorphic-fetch";
import SweetAlert from "sweetalert-react";
import "sweetalert/dist/sweetalert.css";
import consts, { issuesProp } from "../consts";
import HeaderParticles from "./Components/Header/HeaderParticles";
import SelectTopicCount from "./Components/SelectTopicCount";
import SelectDataSource from "./Components/SelectDataSource";
import SelectPassword from "./Components/SelectPassword";
import SelectFiles from "./Components/SelectFiles";
import SelectMonthYear from "./Components/SelectMonthYear/SelectMonthYear";
import SelectIssues from "./Components/SelectIssues";
import SelectSearchTerm from "./Components/SelectSearchTerm";
import SelectDocumentType from "./Components/SelectDocumentType";
import SelectLinks from "./Components/SelectLinks";
import SelectSheet from "./Components/SelectSheet";
import SelectStopWords from "./Components/SelectStopWords";
import SelectJournalQuality from "./Components/SelectJournalQuality";
import explorePapers from "./images/explore-papers.svg";
import exportCitation from "./images/export-citations.svg";
import topicAnalysis from "./images/topic-analysis.svg";
import trendOvertime from "./images/trend-overtime.svg";
import openIcon from "./images/baseline-add-24px.svg";
import closeIcon from "./images/baseline-close-24px.svg";
import { flat, genIDs, uploadArticlesAutoBatch } from "../utils";

import styles from "./index.module.css";
import mAndP from "../Styles/marginsAndPaddings.module.css";
import buttons from "../Styles/buttons.module.css";
import misc from "../Styles/misc.module.css";
import grid from "../Styles/grid.module.css";

import { advancedCheckbox50px } from "../Styles/checkboxes.module.css";


const defaults = {
    stopWords: "company,customer,entrepreneur,business,network,market,relationship,sale,resource,growth,value,"
        + "product,activity,analysis,approach,author,based,case,citation,coefficient,construct,context,control,data,"
        + "diffrence,dis,discriminant,doe,doi,dummy,editorial,effect,estimate,estimated,estimation,empirical,factor,"
        + "figure,finding,focus,formula,future,high,hypothesis,hypotheses,hypothesized,important,increase,information,"
        + "interview,item,journal,key,latent,lecture,level,literature,loading,log,low,made,make,measure,meta-analysis,"
        + "model,negative,number,period,perspective,phenomenon,positive,process,question,questionnaire,rate,regression,"
        + "respondent,research,researcher,result,results,review,sample,scholar,scholarly,scale,section,set,significant,"
        + "size,solution,statistic,statistically,study,survey,suggest,suggests,specific,table,term,test,theory,thing,"
        + "time,topic,total,type,understanding,variable,year,aaboen,adner,ahearne,alvarez,argyris,arun,arvika,"
        + "ballantyne,baraldi,barney,beckman,behrman,ben,bengtsson,bernard,bifocality,bourdieu,brouthers,buckley,"
        + "burt,burton,cardon,carlsson,casson,cavusgil,chery,chiesa,christopher,coad,corona,coviello,craven,cusumano,"
        + "dagnino,davy,dimitratos,dimov,dwyer,elfring,engeström,fiol,frattini,gabrielsson,gawer,gebauer,geberit,"
        + "geppetto,ghoshal,gielnik,gnyawali,glynn,granovetter,greenfield,grönroos,guarnizo,håkansson,hannan,healsana,"
        + "heide,helsinki,hennart,hillman,hite,hulsink,iansiti,j.indmarman,jaakkola,jack,jaffe,james,jaramillo,"
        + "järvensivu,jaworski,jelinek,jerry,johannisson,johanson,kapoor,keenan,kib,kirzner,kjellberg,knight,kohtamäki"
        + ",kowalkowski,landolt,lerner,loccioni,locke,lounsbury,ludvika,lumpkin,lusch,madsen,maxwell,mcdougall,"
        + "mcmullen,michael,mike,möller,nahapiet,nakos,nambisan,neslin,nordin,osterwalder,oviatt,paesbrugghe,payne,"
        + "penrose,perreault,petkova,plouffe,podsakoff,porte,reinartz,richard,rita,robert,rugman,sarasvathy,saxenian,"
        + "schön,schoonhoven,servais,sirius,snehota,solms,steel,stephen,storbacka,suchman,sujan,svahn,teece,tidström,"
        + "töytäri,tuli,ulaga,uzzi,vahlne,validity,vargo,venkataraman,verhoef,vorhies,waldinger,waluszewski,wärtsilä,"
        + "weitz,whitman,wiklund,wildenbeest,willy,woodruff,yli‐renko,zbaracki,zeithaml,zeitz,zimmerman,airtelco,alfa,"
        + "amazon,clorox,directv,facebook,jeep,kickstarter,linkedin,nike,oxeon,relicom,ricoh,softperf,tivo,twitter,"
        + "uber,unileaver,yahoo,steelco,e.g, e.g.,tion,n't,ing,’s,'s,al.,``,'',et,al,start&#8208,βct,σli,σni,inv/bgs,"
        + "inv/bg,invs/bgs,x-wiley,ºbº,up,1,one,2,two,also,ave,new,also,may,tie,and/or,goods/services,cnk,ccacampaign,"
        + "tomer,organiza,tive,mance,ments,perfor,ooll,tional,sion,rela,ence,ool,organi,oll,ganizational,ness,impor,"
        + "tor,experi,ture,tions,influ,tomers,ment,informa,infor,cus,ity,con,inter,mation,<U+25AA>,the\\u",
    topicCount: "6",
    topicThreshold: consts.defaultThreshold,
    bigTopics: false,
    variableLinks: false,
    mutualLinks: false,
    journalQuality: {
        "A*": true,
        A: true,
        B: true,
    },
    files: [],
};

/**
 * Convert api response to json and check status code
 * @param response
 * @returns {Promise<Object>}
 */
function responseToJson(response) {
    if (response.status !== 200) {
        console.log(`Looks like there was a problem. Status Code: ${response.status}`);
        return response.text().then(text => {
            throw text;
        });
    }

    // Examine the text in the response
    return response.json();
}

class SearchPage extends Component {
    static propTypes = {
        onData: PropTypes.func.isRequired,
        issues: issuesProp,
    };

    static defaultProps = {
        issues: [],
    };

    /**
     * Scrolls to a specified selector on tha page
     * @param {element}
     */
    static scrollHandler() {
        const targetElement = document.querySelector("#exploration");

        if (!targetElement) return;
        const originalTop = Math.floor(targetElement.getBoundingClientRect().top);
        window.scrollBy({ top: originalTop, left: 0, behavior: "smooth" });

        const checkIfScrolled = setInterval(() => {
            const atBottom = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;
            const gbcr = Math.floor(targetElement.getBoundingClientRect().top);
            if (gbcr === 0 || atBottom) {
                targetElement.tabIndex = "-1";
                targetElement.focus();
                clearInterval(checkIfScrolled);
            }
        });
    }

    constructor() {
        super();

        const stateQuery = {
            source: "gers",
        };
        const futureState = {
            uniqueIds: genIDs("advancedOptions", "topicThreshold", "mutualLinks", "bigTopics", "variableLinks"),
            advanced: false,
            authorAudit: false,
            showSubmitting: false,
            showError: false,
            allowSearchCancel: true,
            query: stateQuery,
            defaultsAndQuery: Object.assign(stateQuery, defaults),
        };

        this.toggleAdvanced = this.toggleAdvanced.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.startSearch = this.startSearch.bind(this);
        this.buildQuery = this.buildQuery.bind(this);
        this.runTopicModel = this.runTopicModel.bind(this);
        this.runRangeOfModels = this.runRangeOfModels.bind(this);
        this.confirmedRunRangeOfModels = this.confirmedRunRangeOfModels.bind(this);
        this.unMountSweetAlert = this.unMountSweetAlert.bind(this);
        this.showError = this.showError.bind(this);
        this.closeError = this.closeError.bind(this);
        this.updateQuery = this.updateQuery.bind(this);
        this.typeCheckBoxes = {};
        this.query = {};

        futureState.queryOverride = Object.entries(queryString.parse(window.location.hash))
            .map(([key, value]) => ((value === "true" || value === "false") ? [key, value === "true"] : [key, value]))
            .reduce((object, [key, value]) => {
                // modifying a function property like this is bad form but it also creates much less garbage.
                // eslint-disable-next-line no-param-reassign
                object[key] = value;
                return object;
            }, {});

        if (futureState.queryOverride.hasOwnProperty("cacheId")) {
            futureState.allowSearchCancel = false;
        }

        if (window.location.hash && futureState.queryOverride) {
            futureState.defaultsAndQuery = Object.assign({}, futureState.defaultsAndQuery, futureState.queryOverride);
            console.log("hashQuery", futureState.defaultsAndQuery);
        }

        /* if (window.location.hash && futureState.queryOverride) {
            // futureState.allowSearchCancel = false;
            // futureState.showSubmitting = true;
        } */
        futureState.drillDownMode = !!(window.location.hash && futureState.queryOverride);

        this.defaults = Object.assign({}, defaults, futureState.queryOverride);
        this.state = futureState;
    }

    componentDidMount() {
        const { queryOverride, drillDownMode } = this.state;

        if (window.location.hash && queryOverride && queryOverride.autoRun) {
            this.onSubmit();
        }
        // if drilldown mode, scroll page to article selection
        if (drillDownMode) {
            SearchPage.scrollHandler();
        }
    }

    /**
     * Show submitting dialog
     * @param event
     */
    onSubmit(event) {
        this.setState({ showSubmitting: true });
        if (event) {
            event.preventDefault();
        }
    }

    /**
     * Run a query against the topic model api
     * @param {Object} query
     * @returns {PromiseLike<{ query: Object }>}
     */
    getModelResults(query) {
        return fetch(`${consts.api}/analyze/`, {
            method: "POST",
            mode: "cors",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(query),
        })
            .then(this.unMountSweetAlert)
            .then(responseToJson)
            .catch(error => {
                console.error(error);
                throw error;
            });
    }

    /**
     * Updates the current query.
     * @param key
     * @param value
     */
    updateQuery(key, value) {
        const { query } = this.state;

        const newQuery = Object.assign({}, query, { [key]: value });
        const newDefaults = Object.assign({}, defaults, newQuery);
        this.setState({ query: newQuery, defaultsAndQuery: newDefaults });
    }

    /**
     * Toggle showing the advanced area
     */
    toggleAdvanced() {
        const { advanced } = this.state;

        this.setState({ advanced: !advanced });
    }

    async buildQuery() {
        const { queryOverride, query: stateQuery, drillDownMode } = this.state;


        try {
            let query;
            if (window.location.hash && queryOverride) {
                query = Object.assign({}, stateQuery, queryOverride);
            } else {
                query = Object.assign({}, stateQuery);
                if (this.authorSelect) {
                    const authors = this.authorSelect.value;
                    if (authors) {
                        query.authors = authors;
                    }
                }
            }

            if (query.source === "file" && !drillDownMode) {
                if (query.checkFiles == null) {
                    throw new Error("Please select a file");
                }
                const files = await query.checkFiles();
                delete query.checkFiles;
                if (files == null) {
                    throw new Error("Please select a file.");
                }
                query.articles = flat(files).map(f => f.id).join(",");
            }
            delete query.files;

            if (query.source === "link" && !drillDownMode) {
                if (!query.links || query.links.split("\n").length < 1) {
                    throw new Error("Please enter more links");
                }
                query.articles = (await uploadArticlesAutoBatch(query.links.split("\n").filter(link => link !== "")
                    .map(link => ({ link, title: link }))))
                    .map(article => article.id).join(",");
            }
            delete query.links;

            if (query.source === "sheet" && !drillDownMode) {
                if (!stateQuery.getFiles) {
                    throw new Error("Please select a file or a content column within a file");
                }
                query.articles = (await stateQuery.getFiles())
                    .map(fileMeta => fileMeta.id).join(",");
                console.log(await stateQuery.getFiles());
                console.log((await stateQuery.getFiles()).map(m => m.id));
                console.log(query.articles);
            }
            delete query.getFiles;

            if ((query.source === "gers" || query.source === "gers2") && query.journalQuality) {
                query.journalQuality = Object.entries(query.journalQuality)
                    .filter(([, value]) => value)
                    .map(([key]) => key);
            } else {
                delete query.journalQuality;
            }

            if (stateQuery.topicCount) {
                query.topicCount = stateQuery.topicCount;
            }

            if (this.topicThreshold && this.topicThreshold !== this.defaults.topicThreshold) {
                query.topicThreshold = this.topicThreshold.value;
            } else {
                query.topicThreshold = this.defaults.topicThreshold;
            }
            if (this.query.hasOwnProperty("stopWords") && this.query.stopWords.value === this.defaults) {
                delete this.query.stopWords;
            }
            if (this.mutualLinks && this.mutualLinks.checked !== this.defaults.mutualLinks) {
                query.mutualLinks = this.mutualLinks.checked;
            }
            if (this.bigTopics && this.bigTopics.checked !== this.defaults.bigTopics) {
                query.bigTopics = this.bigTopics.checked;
            }
            if (this.variableLinks && this.variableLinks.checked !== this.defaults.variableLinks) {
                query.variableLinks = this.variableLinks.checked;
            }

            if (!query.topicThreshold) {
                query.topicThreshold = this.defaults.topicThreshold;
            }

            return query;
        } catch (error) {
            throw error;
        }
    }

    /**
     * Start the topic model
     */
    startSearch(query) {
        console.log("querying api with", query);

        const startTime = Date.now();

        return this.getModelResults(query).then((data) => {
            ReactGA.timing({
                category: "API Requests",
                variable: "load topic model result",
                value: Date.now() - startTime, // in milliseconds
            });
            console.log("Data Returned", data);
            console.log(`Data was cached, cache id is ${data.cacheId}`);

            const reducedQueryProps = ["topicThreshold", "mutualLinks", "bigTopics", "variableLinks"];

            const reducedQuery = reducedQueryProps.reduce((acc, prop) => {
                if (query.hasOwnProperty(prop)) {
                    acc[prop] = query[prop];
                }
                return acc;
            }, {});

            this.setState({
                redirect: {
                    pathname: `/display/${data.cacheId}`,
                    search: queryString.stringify(reducedQuery),
                },
            });
        });
    }

    async runTopicModel() {
        try {
            await this.buildQuery().then(this.startSearch);
        } catch (error) {
            console.error(error);
            setTimeout(() => this.showError(error.toString()), 100);
        }
    }

    confirmedRunRangeOfModels() {
        const low = parseInt(document.getElementById("small-number").value, 10);
        const high = parseInt(document.getElementById("large-number").value, 10);
        this.runRangeOfModels(low, high);
    }

    async runRangeOfModels(low = 1, high = 30) {
        try {
            const query = await this.buildQuery();
            query.topicCount = new Array((high - low) + 1).fill(0).map((value, index) => index + low);
            const results = await this.getModelResults(query);
            this.setState({ showEvaluate: false });
            this.setState({
                redirect: {
                    pathname: `/topicchart/${results.cacheId}`,
                    search: queryString.stringify(results),
                },
            });
            console.log(results);
        } catch (error) {
            console.error(error);
            setTimeout(() => this.showError(error.toString()), 100);
        }
    }

    /**
     * Display an error
     * @param {string} text
     */
    showError(text) {
        this.setState({ errorText: text, showError: true });
    }

    /**
     * Hide error display
     */
    closeError() {
        this.setState({ errorText: null, showError: false });
    }

    /**
     * Checks if the current data source is one of a list of sources
     * @param {Array<string>} source
     */
    isOfSource(source) {
        const { query } = this.state;

        return source.includes(query.source);
    }

    /**
     * Unmount search dialog
     * @param {*} passThrough
     * @returns {*}
     */
    unMountSweetAlert(passThrough) {
        this.setState({ showSubmitting: false });
        return passThrough;
    }

    render() {
        const {
            redirect,
            uniqueIds,
            drillDownMode,
            defaultsAndQuery,
            advanced,
            query,
            showSubmitting,
            showEvaluate,
            showError,
            errorText,
            allowSearchCancel,
        } = this.state;
        const { issues } = this.props;

        if (redirect) {
            return (<Redirect to={redirect} push />);
        }

        return (
            <div className={[styles.wrapper, styles.noMarginNoPrefix].join(" ")}>
                {/* Header Particles */}
                <HeaderParticles scrollHandler={SearchPage.scrollHandler} />

                {/* Container body */}
                <div className={mAndP["mt-3"]}>
                    {/* Discover capabilities section */}
                    <div className={[styles.container, misc.textCenter].join(" ")}>
                        <h3 className={[styles.title, mAndP["mt-5"]].join(" ")}>Discover Capabilities</h3>
                        {/* J-tool 4 cores -- offers */}
                        <div className={["w-100", mAndP["px-2"], grid.grid, grid.gridCenter, styles.grid4].join(" ")}>
                            <div className={[styles.offer, mAndP["px-3"]].join(" ")}>
                                <div className={styles.offerSvg}>
                                    <img src={topicAnalysis} alt="Analyze textual data with topic modelling" />
                                </div>
                                <div className={styles.offerText}>
                                    Analyze textual data using topic modelling
                                </div>
                            </div>
                            <div className={[styles.offer, mAndP["px-3"]].join(" ")}>
                                <div className={styles.offerSvg}>
                                    <img src={explorePapers} alt="Explore relationships of papers" />
                                </div>
                                <div className={styles.offerText}>
                                    Visually explore relationships of these papers
                                </div>
                            </div>
                            <div className={[styles.offer, mAndP["px-3"]].join(" ")}>
                                <div className={styles.offerSvg}>
                                    <img src={trendOvertime} alt="Examine topic trends overtime" />
                                </div>
                                <div className={styles.offerText}>
                                    Examine topic trends over time
                                </div>
                            </div>

                            <div className={[styles.offer, mAndP["px-3"]].join(" ")}>
                                <div className={styles.offerSvg}>
                                    <img src={exportCitation} alt="Export citation in Bibtex or RIS format" />
                                </div>
                                <div className={styles.offerText}>
                                    Export citations in BibTex, RIS, CSV or text format
                                </div>
                            </div>
                        </div>
                        {/* End of J-tool 4 cores -- offers */}
                    </div>
                    {/* End of Discover Capabilities Section */}

                    <div className={[styles.exploration, "w-100", mAndP["py-4"], "bg-accent"].join(" ")}>
                        <div className={styles.container} id="exploration">
                            <h3 className={[
                                styles.title,
                                styles.titleSmall,
                                mAndP["pt-3"],
                                mAndP["mb-3"],
                                misc.textCenter,
                            ].join(" ")}
                            >
                                Exploration
                                Parameters
                            </h3>
                            <p className={[styles.title, styles.titleSub, misc.textCenter].join(" ")}>
                                Select a group of
                                documents to analyze
                            </p>

                            <form onSubmit={this.onSubmit}>
                                <div className={["w-100", grid.grid, styles.grid2, mAndP["mb-4"]].join(" ")}>
                                    <div className={styles.parameters}>
                                        <div className={styles.parametersBoxed}>
                                            <SelectTopicCount
                                                defaultsAndQuery={defaultsAndQuery}
                                                updateQuery={this.updateQuery}
                                            />
                                            <br />
                                            {drillDownMode
                                                ? "Drill into articles from previously selected topic."
                                                // eslint-disable-next-line react/jsx-indent
                                                : (
                                                    <div>
                                                        <SelectDataSource
                                                            defaultsAndQuery={defaultsAndQuery}
                                                            updateQuery={this.updateQuery}
                                                        />

                                                    </div>
                                                )

                                            }
                                            {/* End of DrillDownMode state */}

                                            {/* Advanced options */}
                                            {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                                            <label htmlFor={uniqueIds.advancedOptions}>
                                                <h6 className={[styles.boxedTitle, "b-600"].join(" ")}>
                                                    Advanced Options
                                                </h6>
                                                <button
                                                    id={uniqueIds.advancedOptions}
                                                    type="button"
                                                    className={buttons.antiButton}
                                                    onClick={this.toggleAdvanced}
                                                >
                                                    {advanced
                                                        ? (
                                                            <img
                                                                src={closeIcon}
                                                                alt="close advanced options"
                                                            />
                                                        )
                                                        : (
                                                            <img
                                                                src={openIcon}
                                                                alt="open advanced options"
                                                            />
                                                        )
                                                    }
                                                    {" "}
                                                    Visualization & Thresholds
                                                </button>
                                            </label>

                                            {advanced
                                                ? (
                                                    <div>
                                                        <label htmlFor={uniqueIds.topicThreshold}>
                                                            Topic threshold
                                                            <input
                                                                id={uniqueIds.topicThreshold}
                                                                type="number"
                                                                min="10"
                                                                max="99"
                                                                defaultValue={this.defaults.topicThreshold}
                                                                ref={input => {
                                                                    this.topicThreshold = input;
                                                                }}
                                                            />
                                                            %
                                                        </label>
                                                        <br />
                                                        <SelectStopWords
                                                            defaultsAndQuery={defaultsAndQuery}
                                                            updateQuery={this.updateQuery}
                                                        />
                                                        <label
                                                            htmlFor={uniqueIds.mutualLinks}
                                                        >
                                                            Bridging articles
                                                            <input
                                                                id={uniqueIds.mutualLinks}
                                                                className={advancedCheckbox50px}
                                                                type="checkbox"
                                                                title={"display articles that meet the criteria to be "
                                                                + "in 2 topics as linked to both topics"}
                                                                defaultChecked={this.defaults.mutualLinks}
                                                                ref={input => {
                                                                    this.mutualLinks = input;
                                                                }}
                                                            />
                                                        </label>
                                                        <br />
                                                        <label
                                                            htmlFor={uniqueIds.bigTopics}
                                                        >
                                                            Big Topics
                                                            <input
                                                                className={advancedCheckbox50px}
                                                                id={uniqueIds.bigTopics}
                                                                type="checkbox"
                                                                title={"display topics as large circles with first "
                                                                + "keyword inside topic circle"}
                                                                defaultChecked={this.defaults.bigTopics}
                                                                ref={input => {
                                                                    this.bigTopics = input;
                                                                }}
                                                            />
                                                        </label>
                                                        <br />
                                                        <label
                                                            htmlFor={uniqueIds.variableLinks}
                                                        >
                                                            Variable link length
                                                            <input
                                                                className={advancedCheckbox50px}
                                                                id={uniqueIds.variableLinks}
                                                                type="checkbox"
                                                                title="link length based on relation strength"
                                                                defaultChecked={this.defaults.variableLinks}
                                                                ref={input => {
                                                                    this.variableLinks = input;
                                                                }}
                                                            />
                                                        </label>
                                                    </div>
                                                )
                                                : ""}
                                            {/* End of Advanced options */}
                                        </div>
                                    </div>
                                    <div className={styles.parameters}>
                                        <div className={[styles.parametersBoxed, styles.parametersValues].join(" ")}>
                                            {/* Start of DrillDownMode on values */}
                                            {drillDownMode
                                                ? ""
                                                : (
                                                    <div>
                                                        {query.source === "file"
                                                            ? (
                                                                <SelectFiles
                                                                    defaultsAndQuery={defaultsAndQuery}
                                                                    updateQuery={this.updateQuery}
                                                                />
                                                            )
                                                            : ""}
                                                        {query.source === "sheet"
                                                            ? (
                                                                <SelectSheet
                                                                    defaultsAndQuery={defaultsAndQuery}
                                                                    updateQuery={this.updateQuery}
                                                                />
                                                            )
                                                            : ""}
                                                        {this.isOfSource(["obj", "tim", "gers", "gers2"])
                                                            ? (
                                                                <SelectMonthYear
                                                                    defaultsAndQuery={defaultsAndQuery}
                                                                    updateQuery={this.updateQuery}
                                                                />
                                                            )
                                                            : ""}
                                                        <br />
                                                        {query.source === "tim"
                                                            ? (
                                                                <SelectIssues
                                                                    issues={issues}
                                                                    defaultsAndQuery={defaultsAndQuery}
                                                                    updateQuery={this.updateQuery}
                                                                />
                                                            )
                                                            : ""}

                                                        {this.isOfSource(["obj", "tim", "gers", "gers2", "ispim"])
                                                            ? (
                                                                <SelectSearchTerm
                                                                    defaultsAndQuery={defaultsAndQuery}
                                                                    updateQuery={this.updateQuery}
                                                                />
                                                            )
                                                            : ""}
                                                        {this.isOfSource(["gers", "gers2"])
                                                            ? (
                                                                <SelectJournalQuality
                                                                    defaultsAndQuery={defaultsAndQuery}
                                                                    updateQuery={this.updateQuery}
                                                                />
                                                            )
                                                            : ""}
                                                        {this.isOfSource(["ispim"])
                                                            ? (
                                                                <SelectPassword
                                                                    defaultsAndQuery={defaultsAndQuery}
                                                                    updateQuery={this.updateQuery}
                                                                />
                                                            )
                                                            : ""}
                                                        {query.source === "tim"
                                                            ? (
                                                                <SelectDocumentType
                                                                    defaultsAndQuery={defaultsAndQuery}
                                                                    updateQuery={this.updateQuery}
                                                                />
                                                            )
                                                            : ""}
                                                        {query.source === "link"
                                                            ? (
                                                                <SelectLinks
                                                                    defaultsAndQuery={defaultsAndQuery}
                                                                    updateQuery={this.updateQuery}
                                                                />
                                                            )
                                                            : ""}
                                                    </div>
                                                )
                                            }
                                            {/* End of DrillDownMode of values */}
                                            <br />
                                        </div>
                                    </div>
                                </div>

                                <div className={["w-100", misc.textCenter].join(" ")}>
                                    <input
                                        type="submit"
                                        value="Submit"
                                        className={[buttons.btn, buttons.btnPrimary].join(" ")}
                                    />
                                    <input
                                        type="button"
                                        value="Evaluate"
                                        className={[buttons.btn, buttons.btnPrimary].join(" ")}
                                        onClick={() => this.setState({ showEvaluate: true })}
                                    />
                                </div>
                            </form>


                            <SweetAlert
                                show={showSubmitting}
                                title="Perform Analysis"
                                text="Submit to run topic modeling analysis on selected articles."
                                type="info"
                                showCancelButton={allowSearchCancel}
                                showLoaderOnConfirm
                                onCancel={() => this.setState({ showSubmitting: false })}
                                onConfirm={this.runTopicModel}
                            />
                            <SweetAlert
                                show={showEvaluate}
                                title="Evaluate topic counts"
                                text='<div>
                                Lowest:
                                <input
                                    style="display: block"
                                    id="small-number"
                                    type="number"
                                    placeholder="1"
                                    min="1"
                                    max="100"
                                    value="1"
                                />
                                <br />
                                Largest:
                                <input
                                    style="display: block"
                                    id="large-number"
                                    type="number"
                                    placeholder="1"
                                    min="2"
                                    max="100"
                                    value="30"
                                />
                                </div>'
                                html
                                type="info"
                                showLoaderOnConfirm
                                onCancel={() => this.setState({ showEvaluate: false })}
                                onConfirm={this.confirmedRunRangeOfModels}
                            />
                            <SweetAlert
                                show={showError}
                                title="Error"
                                text={errorText}
                                type="error"
                                onConfirm={this.closeError}
                            />
                        </div>
                    </div>
                    {/* End of Discover Parameters */}
                </div>
            </div>
        );
    }
}

export default SearchPage;
