/**
 * Created by macdja38 on 2017-05-31.
 */

import React, { Component } from "react";
import PropTypes from "prop-types";
import fetch from "isomorphic-fetch";

import DisplayTable from "../Components/DisplayTable";

import consts, { authorsProp } from "../consts";
import { parseQueryString } from "../utils";

const limit = 100;

/**
 * Increment the number of articles in a topic by 1
 * @param {number} index
 * @param {Array<number>} count
 */
function addTopicCount(index, count) {
    // eslint-disable-next-line no-param-reassign
    count[index] = (count[index] || 0) + 1;
}

/*
/**
 * Add meta data to a topic or article, modifies the source data, as well as returning the new format.
 * @param {Object} data
 * @returns {{}}

function addMeta(data) {
    const newData = Object.assign({}, data);
    newData.topics = data.topics.map(topic => {
        // we could create new objects instead of re-using but that would be slower with no benefit
        // eslint-disable-next-line no-param-reassign
        topic.level = 1;
        // eslint-disable-next-line no-param-reassign
        topic.type = "TOPIC";
        return topic;
    });
    newData.submissions = data.submissions.map(submission => {
        // eslint-disable-next-line no-param-reassign
        submission.level = 2;
        // eslint-disable-next-line no-param-reassign
        submission.type = "ARTICLE";
        return submission;
    });
    return newData;
}
*/

/**
 * Checks if the number of articles in a topic is over the limit to be displayed
 * @param {number} index
 * @param {Array<number>} count
 * @param {number} topicCountLimit
 * @returns {boolean}
 */
function checkTopicCountOverLimit(index, count, topicCountLimit) {
    return count[index] > topicCountLimit;
}

function filterSubmissions(percentCutoff, articlesInTopic, submission) {
    const topics = Object.entries(submission.correlations).filter(([, value]) => value >= percentCutoff);
    if (topics.length < 1) return false;
    if (!checkTopicCountOverLimit(topics[0][0], articlesInTopic, limit)) {
        addTopicCount(topics[0][0], articlesInTopic);
        return true;
    }
    return false;
}

/**
 * Builds node map and links between nodes for use by d3.js
 * Central node of Tim Review, surrounded by topics with one an article linked to a single article
 * @param {Object} options
 * @param {boolean} options.bigTopics determines if topic text is written inside the topic bubble or next to it.
 * @param {boolean} options.variableLinks determines if the length of
 * the links is constant or related to relation strength
 * @param {boolean} options.mutualLinks determines if articles that are in multiple topics will be linked to both topics
 * @param {number} percentCutoff
 * @param data
 * @returns {{nodes: [Object], links: [Object]}}
 */
function singleLinkedNodesAndLinks({ bigTopics, variableLinks, mutualLinks }, percentCutoff, data) {
    const articlesInTopic = [];

    const nodes = [{
        title: "Center Node",
        level: 0,
        type: "CENTER",
        parents: [],
        size: 8,
        data: null,
        index: 0,
    }];

    const topics = data.topics.map((topic, index) => {
        const node = {
            keywordList: topic.keywordList,
            submissions: [],
            level: 1,
            type: "TOPIC",
            size: 5,
            parents: [nodes[0]],
            data: topic,
            title: data.title,
            index: index + 1,
        };
        if (bigTopics) {
            node.size = 50;
            node.strength = -1000;
        }
        return node;
    });

    nodes.push(...topics);

    const links = topics.map((topic, index) => {
        const link = {
            source: index + 1,
            distance: 20,
            target: 0,
        };
        if (bigTopics) {
            link.distance = 100;
            link.strength = 2;
        }
        return link;
    });

    let indexOffset = nodes.length;
    data.submissions
        .filter(filterSubmissions.bind(this, percentCutoff, articlesInTopic))
        .forEach((submission, submissionIndex) => {
            if (mutualLinks) {
                nodes.push(Object.assign({}, submission, {
                    index: submissionIndex + indexOffset,
                    size: 3,
                    level: 2,
                    type: "ARTICLE",
                    data: submission,
                    parents: [],
                }));
            }
            submission.correlations.forEach((percent, index) => {
                if (percent >= percentCutoff) {
                    let node;
                    if (mutualLinks) {
                        node = nodes[nodes.length - 1];
                    } else {
                        node = {
                            index: indexOffset,
                            level: 2,
                            type: "ARTICLE",
                            size: 3,
                            parents: [],
                            data: submission,
                            id: submission.id,
                            title: submission.title,
                            summary: submission.summery,
                        };
                        nodes.push(node);
                        indexOffset += 1;
                    }
                    node.parents.push(nodes[index + 1]);
                    const link = {
                        source: node.index,
                        distance: 10,
                        target: index + 1,
                    };
                    if (variableLinks) {
                        link.distance = (100 - percent) + 10;
                    }
                    if (bigTopics) {
                        link.distance += 30;
                    }
                    links.push(link);
                }
            });
        });

    return {
        nodes,
        links,
        bigTopics,
        variableLinks,
    };
}

class DisplayPage extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        authors: authorsProp,
    };

    static defaultProps = {
        authors: [],
    };

    constructor(props) {
        super(props);

        this.state = {};
        this.changeDisplayOptions = this.changeDisplayOptions.bind(this);
    }

    componentDidMount() {
        const { id } = this.props;

        this.getData(id);
    }

    getData(id) {
        return fetch(`${consts.api}/analyze/?id=${encodeURIComponent(id)}`, {
            method: "GET",
            mode: "cors",
            headers: {
                Accept: "application/json",
            },
        }).then((response) => {
            if (response.status === 200) {
                return response.json();
            }
            throw response.error;
        }).then((json) => {
            // apply year and month field to each issue
            console.log(json);
            if (json.message) {
                this.setState({ message: json.message });
                setTimeout(() => this.getData(id), 2000);
            } else {
                console.log("queryString", parseQueryString(window.location.search));

                const jsonPlusQueryString = window.location.search
                    ? Object.assign({}, json, {
                        query: Object.assign({}, json.query, parseQueryString(window.location.search)),
                    })
                    : Object.assign({}, json, {
                        query: Object.assign({}, json.query, { topicThreshold: consts.defaultThreshold }),
                    });

                this.setState({ data: jsonPlusQueryString });
            }
        }).catch(console.error);
    }

    /**
     * Changes the display options of the query
     * @param {Object} options
     * @param {boolean} [options.bigTopics]
     * @param {boolean} [options.mutualLinks]
     * @param {boolean} [options.variableLinks]
     * @param {number} [options.topicThreshold]
     */
    changeDisplayOptions(options) {
        const { data } = this.state;

        this.setState(Object.assign(data.query, options));
    }

    render() {
        const { data, message } = this.state;

        if (data) {
            return (
                <DisplayTable
                    data={data}
                    changeDisplayOptions={this.changeDisplayOptions}
                    nodesAndLinks={singleLinkedNodesAndLinks({
                        bigTopics: data.query.hasOwnProperty("bigTopics")
                            ? data.query.bigTopics
                            : consts.bigTopics,
                        mutualLinks: data.query.hasOwnProperty("mutualLinks")
                            ? data.query.mutualLinks
                            : consts.mutualLinks,
                        variableLinks: data.query.hasOwnProperty("variableLinks")
                            ? data.query.variableLinks
                            : consts.variableLinks,
                        trendsOverTime: data.query.hasOwnProperty("trendsOverTime")
                            ? data.query.trendsOverTime
                            : consts.trendsOverTime,
                    }, data.query.topicThreshold, data)}
                />);
        }

        if (message) {
            return (<p>Loading... {message}</p>);
        }

        return (<p>Loading...</p>);
    }
}

export default DisplayPage;
