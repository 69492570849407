import React, { Component } from "react";
import PropTypes from "prop-types";

class AddFile extends Component {
    static propTypes = {
        addedFiles: PropTypes.func.isRequired,
        accept: PropTypes.string,
        multiple: PropTypes.bool,
    };

    static defaultProps = {
        accept: ".txt,.zip,.pdf",
        multiple: false,
    };

    constructor() {
        super();
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(evt) {
        const { addedFiles } = this.props;

        if (evt.target.files.length < 1) {
            alert("No Text file Selected!"); // TODO: possibly add error message
        } else {
            addedFiles(evt.target.files);
        }

        evt.preventDefault();
    }

    render() {
        const { multiple, accept } = this.props;

        return (
            <div>
                <input
                    type="file"
                    multiple={multiple ? "multiple" : false}
                    accept={accept}
                    onChange={this.handleChange}
                />
            </div>);
    }
}

export default AddFile;
