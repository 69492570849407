import React, { Component } from "react";
import PropTypes from "prop-types";
import { genIDs } from "../../utils";

import searchPageStyles from "../index.module.css";

export default class SelectPassword extends Component {
    static propTypes = {
        defaultsAndQuery: PropTypes.object.isRequired,
        updateQuery: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { uniqueIds: genIDs("selectPassword") };
    }

    render() {
        const { uniqueIds } = this.state;
        const { updateQuery } = this.props;

        return (
            <label htmlFor={uniqueIds.selectPassword}>
                <h6 className={searchPageStyles.boxedTitle}>Password</h6>
                <div>
                    This data source is locked down, please provide the password
                    <br />
                    <input
                        id={uniqueIds.selectPassword}
                        type="password"
                        onChange={event => updateQuery("password", event.target.value)}
                        placeholder="******"
                        style={{ marginTop: "10px", backgroundColor: "white" }}
                    />
                </div>
            </label>
        );
    }
}
