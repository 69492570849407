import React, { Component } from "react";
import Raven from "raven-js";
import { BrowserRouter as Router, Link, Redirect, Route } from "react-router-dom";
import ReactGA from "react-ga";
import fetch from "isomorphic-fetch";
import queryString from "query-string";
import SearchPage from "./SearchPage";
import DisplayPage from "./DisplayPage";
import TopicChart from "./TopicChart";
import ShareLinkButton from "./Components/ShareLinkButton";
import ChangeTitleButton from "./Components/ChangeTitleButton";
import mAndP from "./Styles/marginsAndPaddings.module.css";
import grid from "./Styles/grid.module.css";
import misc from "./Styles/misc.module.css";
import main from "./main.module.css";
import logo from "./logo.png";
import styles from "./App.module.css";

import consts from "./consts";

Raven.config(consts.sentryDSN, {
    release: process.env.REACT_APP_GIT_RELEASE,
    autoBreadcrumbs: {
        xhr: true, // XMLHttpRequest
        console: false, // console logging - does not work on objects created by Object.create(null) or in edge..." +
        dom: true, // DOM interactions, i.e. clicks/typing
        location: true, // url changes, including pushState/popState
    },
}).install();

/**
 * Log an exception to raven and console.
 * @param {Error} ex
 * @param {*} [context]
 */
function logException(ex, context) {
    Raven.captureException(ex, {
        extra: context,
    });

    /* eslint no-console:0 */
    if (window.console && console.error) {
        console.error(ex);
    }
}

class App extends Component {
    constructor() {
        super();

        this.state = { page: "search", data: false };
        if (window.location.pathname === "/") {
            const hash = queryString.parse(window.location.hash);
            if (hash.autoRun === "true" && Object.prototype.hasOwnProperty.call(hash, "cacheId")) {
                this.state.redirect = { pathname: `/display/${hash.cacheId}` };
            }
        }
        this.receivedData = this.receivedData.bind(this);
        this.backToSearch = this.backToSearch.bind(this);
        this.setState = this.setState.bind(this);

        this.historyArray = [];
        window.onpopstate = (event) => {
            if (event.state == null) return;
            this.setState(this.historyArray[event.state.index + 1]);
        };

        this.stateSet = false;
    }

    componentDidMount() {
        this.fetchData();
    }

    setStateAndHistory(state) {
        this.historyArray.push(Object.assign({}, this.state));

        let pushMethod = window.history.pushState;
        if (this.stateSet === false) {
            this.stateSet = true;
            pushMethod = window.history.replaceState;
        }
        pushMethod = pushMethod.bind(window.history);

        pushMethod({
            index: this.historyArray.length - 1,
        }, "", window.location);
        this.setState(state);

        ReactGA.pageview(`/${state.page}`);
    }

    /**
     * Go back to search page
     */
    backToSearch() {
        this.setStateAndHistory({ page: "search" });
    }

    /**
     * When data is received add data to state
     * @param {Object} data
     * @param {Object} authors
     */
    receivedData(data, authors) {
        if (authors) {
            this.setStateAndHistory({ page: "display", data, authors: authors.authors });
        } else {
            this.setStateAndHistory({ page: "display", data, authors: null });
        }
    }

    fetchData() {
        fetch(`${consts.api}/list/issues/`, {
            method: "GET",
            mode: "cors",
        }).then((response) => {
            if (response.status === 200) {
                return response.json();
            }
            throw response.error;
        }).then((json) => {
            // apply year and month field to each issue
            const data = json.issues.map(issue => {
                const date = new Date(issue.createdAt);
                return Object.assign({}, issue, {
                    year: date.getYear() + 1900,
                    month: date.getMonth(),
                });
            });

            // sort data into buckets based on year
            let dataBuckets = data.reduce((accumulator, currentItem) => {
                const bucket = accumulator[currentItem.year];
                if (Array.isArray(bucket)) {
                    bucket.push(currentItem);
                } else {
                    // cloning this array into a new array each iteration would be much more expensive.
                    // eslint-disable-next-line no-param-reassign
                    accumulator[currentItem.year] = [currentItem];
                }
                return accumulator;
            }, []);

            // sort bucket contents by month
            dataBuckets = dataBuckets.map(bucket => bucket.sort((a, b) => a.month - b.month));

            this.setState({
                issues: {
                    data,
                    dataBuckets,
                },
            });
        }).catch(console.error);
    }

    render() {
        const { issues, redirect } = this.state;

        if (!issues) {
            return (<p>Loading...</p>);
        }

        try {
            return (
                <Router>
                    <div>
                        {redirect ? <Redirect to={redirect} /> : ""}
                        <nav className={[main.navbar, mAndP["py-3"], mAndP["pl-2"]].join(" ")} key="nav-key">
                            <div className={[main.container, grid.grid, grid.gridNav].join(" ")}>
                                <a href="/" className={main.navbarBrand}>
                                    <img src={logo} alt="GCR J-tool" />
                                </a>

                                <div className={grid.navButtons}>
                                    <Route
                                        path="/display/:id"
                                        render={({ match: { params: { id } } }) => (
                                            <div className={grid.navButtons}>
                                                <Link className={styles.menuButton} type="button" to="/">
                                                    Change Exploration Parameters
                                                </Link>
                                                <ShareLinkButton
                                                    className={[styles.menuButton, styles.menuButtonRight].join(" ")}
                                                    id={id}
                                                />
                                                <ChangeTitleButton
                                                    className={[styles.menuButton, styles.menuButtonRight].join(" ")}
                                                />
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>

                        </nav>
                        <div className={styles.App} key="app-key">

                            <Route
                                exact
                                path="/display/:id"
                                render={({ match: { params: { id } } }) => (
                                    <div className={[styles.content, styles.bottomBox].join(" ")}>
                                        <DisplayPage id={id} />
                                    </div>
                                )}
                            />
                            <Route
                                exact
                                path="/topicchart/:id"
                                render={({ match: { params: { id } } }) => (
                                    <div className={[styles.content, styles.bottomBox].join(" ")}>
                                        <TopicChart ids={id.split(",")} />
                                    </div>
                                )}
                            />
                            <Route
                                exact
                                path="/"
                                render={() => (
                                    <div className={[styles.content, styles.search].join(" ")}>
                                        <SearchPage issues={issues} onData={this.receivedData} />
                                    </div>
                                )}
                            />

                        </div>

                        <Route
                            exact
                            path="/"
                            render={() => (
                                <footer
                                    className={main.displayBlock}
                                    key="footer-key"
                                >
                                    <div className={main.container}>
                                        <p className={misc.textLeft}>
                                            <strong>J-tool</strong> version: {process.env.REACT_APP_GIT_RELEASE_SHORT}
                                        </p>
                                    </div>

                                </footer>
                            )}
                        />
                    </div>
                </Router>

            );
        } catch (error) {
            logException(error, this.state);
            throw error;
        }
    }
}

export default App;
