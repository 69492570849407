import React, { Component } from "react";
import PropTypes from "prop-types";
import FileItem from "./FileItem";

class StudentFiles extends Component {
    static propTypes = {
        files: PropTypes.arrayOf(PropTypes.object),
        /* contents of promise passed in
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            size: PropTypes.number.isRequired,
        }) */
    };

    static defaultProps = {
        files: [],
    };

    constructor(props) {
        super(props);
        this.state = { resolvedFiles: [] };
    }

    componentDidMount() {
        this.update(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.update(nextProps);
    }

    update(props) {
        Promise.all(props.files).then(files => {
            this.setState({ resolvedFiles: files });
        });
    }

    render() {
        const { resolvedFiles } = this.state;

        const fileItems = resolvedFiles.map(file => (<FileItem key={file.id} file={file} />));

        return (
            <div>
                <strong>List of Files</strong>
                <div style={{ overflow: "auto", maxHeight: "200px", border: "black solid 1px" }}>
                    <table>
                        <tbody>
                            <tr>
                                <th>File Name</th>
                            </tr>
                            {fileItems}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default StudentFiles;
