/**
 * Created by macdja38 on 2017-06-02.
 */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { axisBottom, axisLeft, max, min, range, scaleLinear, scaleTime, select } from "d3";

import styles from "./index.module.css";

class ScatterPlot extends Component {
    static combineRelationStrength(articles, topicIndex) {
        const correlations = articles.map(a => a.correlations[topicIndex]);

        return correlations.reduce((prev, next) => ScatterPlot.relationPercentCalculator(prev, next), 0);
    }

    static add(x, y) {
        return x + y;
    }

    /**
     * Ads two numbers
     * @param {number} previous
     * @param {number} next
     * @returns {number}
     */
    static relationPercentCalculator(previous, next) {
        return previous + next;
    }

    // returns slope, intercept and r-square of the line
    static leastSquares(xSeries, ySeries) {
        const xBar = (xSeries.reduce(ScatterPlot.add) * 1.0) / xSeries.length;
        const yBar = (ySeries.reduce(ScatterPlot.add) * 1.0) / ySeries.length;

        const ssXX = xSeries.map(d => ((d - xBar) ** 2)).reduce(ScatterPlot.add);

        const ssYY = ySeries.map(d => ((d - yBar) ** 2)).reduce(ScatterPlot.add);

        const ssXY = xSeries.map((d, i) => (d - xBar) * (ySeries[i] - yBar)).reduce(ScatterPlot.add);

        const slope = ssXY / ssXX;
        const intercept = yBar - (xBar * slope);
        const rSquare = (ssXY ** 2) / (ssXX * ssYY);


        return [slope, intercept, rSquare];
    }

    static twistData(articles, topicIndex) {
        return articles.map(a => Object.assign({}, a, {
            correlation: a.correlations[topicIndex],
            createdAtDate: Date.parse(a.createdAt),
        }));
    }

    constructor(...args) {
        super(...args);
        this.drawChart = this.drawChart.bind(this);
    }

    componentDidMount() {
        this.drawChart();
        window.addEventListener("resize", this.drawChart);
    }

    componentDidUpdate() {
        this.drawChart();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.drawChart);
    }

    /**
     * When an article is selected bubble up selected article
     * @param article
     */
    onArticleSelected(article) {
        const { onNodeClick, nodesAndLinks } = this.props;

        onNodeClick(nodesAndLinks.nodes.find(nodes => nodes.data === article)
            || Object.assign({
                level: 2,
                parents: [nodesAndLinks.nodes[0]],
                data: article,
            }, article));
    }

    drawChart() {
        const { data, topicIndexToGraph } = this.props;

        Array.from(this.svg.children || this.svg.childNodes).forEach(child => {
            this.svg.removeChild(child);
        });
        this.renderToChart(ScatterPlot.twistData(
            data.submissions,
            topicIndexToGraph - 1,
        ));
    }

    /**
     * Renders a keyword list to an html string for use in popups
     * @param {Array<Object>} data
     */
    renderToChart(data) {
        const chartElement = this.svg;
        const xAxisKey = "createdAtDate";
        const xAxisLabel = "Issue Date";
        const yAxisKey = "correlation";
        const yAxisLabel = "Relation Strength";

        const boundingRect = chartElement.getBoundingClientRect();

        const margin = {
            top: 20, right: 15, bottom: 60, left: 60,
        };
        const width = boundingRect.width - margin.left - margin.right;
        const height = boundingRect.height - margin.top - margin.bottom;

        const x = scaleTime()
            .domain([min(data, d => d[xAxisKey]), max(data, d => d[xAxisKey])])
            .range([0, width]);

        const y = scaleLinear()
            .domain([min(data, d => parseInt(d[yAxisKey], 10)), max(data, d => parseInt(d[yAxisKey], 10))])
            .range([height, 1]);

        const chart = select(chartElement);

        const main = chart.append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`)
            .attr("width", width)
            .attr("height", height)
            .attr("class", "main");

        // draw the x axis
        const xAxis = axisBottom()
            .scale(x);

        main.append("g")
            .attr("transform", `translate(0,${height})`)
            .attr("class", "main axis date")
            .call(xAxis);

        // draw the y axis
        const yAxis = axisLeft()
            .scale(y);

        main.append("g")
            .attr("transform", "translate(0,0)")
            .attr("class", "main axis date")
            .call(yAxis);

        const g = main.append("svg:g");

        g.selectAll("scatter-dots")
            .data(data)
            .enter()
            .append("svg:circle")
            .on("click", (d) => this.onArticleSelected(d))
            .attr("cx", d => x(d[xAxisKey]))
            .attr("cy", d => y(d[yAxisKey]))
            .attr("r", 2)
            .append("svg:title")
            .text(d => {
                const xDate = new Date(d[xAxisKey]);

                return `topic percent: ${d.correlation}\n`
                    + `Issue date: ${xDate.getFullYear()}-${xDate.getMonth()}-${xDate.getDate()}\n`
                    + `Title: ${d.title}`;
            });

        // text label for the x axis
        g.append("text")
            .attr(
                "transform",
                `translate(${width / 2},${height + margin.top + 20})`,
            )
            .attr("fill", "black")
            .style("text-anchor", "middle")
            .text(xAxisLabel)
            .append("svg:title")
            .text("Date of the issue publication.");

        // text label for the y axis
        g.append("text")
            .attr("transform", "rotate(-90)")
            .attr("y", 0 - margin.left)
            .attr("x", 0 - (height / 2))
            .attr("dy", "1em")
            .attr("fill", "black")
            .style("text-anchor", "middle")
            .text(yAxisLabel)
            .append("svg:title")
            .text("Relationship to the category 100 is strongest.");

        const xLabels = data.map(d => d[xAxisKey]);

        // get the x and y values for least squares
        const xSeries = range(1, xLabels.length + 1);
        const ySeries = data.map(d => d[yAxisKey]);

        const leastSquaresCoeff = ScatterPlot.leastSquares(xSeries, ySeries);

        // apply the reults of the least squares regression
        const x1 = xLabels[0];
        const y1 = leastSquaresCoeff[0] + leastSquaresCoeff[1];
        const x2 = xLabels[xLabels.length - 1];
        const y2 = (leastSquaresCoeff[0] * xSeries.length) + leastSquaresCoeff[1];
        const trendData = [[x1, y1, x2, y2]];

        // console.log(leastSquaresCoeff[2]);

        const trendline = g.selectAll("scatter-dots")
            .data(trendData);

        trendline.enter()
            .append("line")
            .attr("class", "trendline")
            .attr("x1", d => x(d[0]))
            .attr("y1", d => y(d[1]))
            .attr("x2", d => x(d[2]))
            .attr("y2", d => y(d[3]))
            .attr("stroke", "black")
            .attr("stroke-width", 1);

        return chartElement;
    }

    render() {
        return (
            <div className={styles.chartBox}>
                <h6 className={styles.chartTitle}>Degree of association of documents to topic</h6>
                <svg
                    className={styles.chart}
                    ref={svg => {
                        this.svg = svg;
                    }}
                />
            </div>
        );
    }
}

ScatterPlot.propTypes = {
    data: PropTypes.shape({
        submissions: PropTypes.array.isRequired,
        topics: PropTypes.array.isRequired,
    }).isRequired,
    nodesAndLinks: PropTypes.shape({
        nodes: PropTypes.array.isRequired,
        links: PropTypes.array.isRequired,
    }).isRequired,
    selected: PropTypes.oneOfType([PropTypes.object, PropTypes.bool, PropTypes.arrayOf(PropTypes.object)]),
    onNodeClick: PropTypes.func,
    topicIndexToGraph: PropTypes.number,
};

ScatterPlot.defaultProps = {
    onNodeClick: () => {
    },
    topicIndexToGraph: 0,
    selected: false,
};

export default ScatterPlot;
