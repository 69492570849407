/**
 * Created by macdja38 on 2017-06-05.
 */

import React, { Component } from "react";
import PropTypes from "prop-types";

import SweetAlert from "sweetalert-react";

import { renderToStaticMarkup } from "react-dom/server";

import { toBiblio, toRis } from "citation-export";

import stringify from "csv-stringify/lib/es5/sync";

import fileDownload from "js-file-download";

import { articlesProp, topicsProp } from "../../consts";

function topicToKeywords(topic) {
    return topic.keywordList.slice(0, 3).map(word => word.word).join(" ");
}

const formatToFileNameMap = {
    bibtex: "Biblio-Bibtex.bib",
    rtf: "rtf.rtf",
    tagged: "Drupal-Biblio.enw",
    marc: "Biblio.mrc",
    xml: "Biblio-EndNote.xml",
    ris: "Drupal-Biblio.ris",
    text: "Text.txt",
    csv: "Spreadsheet.csv",
};

const formatToFormatMap = {
    bibtex: toBiblio,
    ris: toRis,
};

const formatToTypeMap = {
    bibtex: false,
    ris: false,
    text: false,
    csv: "application/octet-stream;charset=utf-8;",
};

class CitationExport extends Component {
    static propTypes = {
        articles: articlesProp.isRequired,
        topics: topicsProp.isRequired,
        show: PropTypes.bool.isRequired,
        done: PropTypes.func.isRequired,
        exportTopicIndex: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    };

    static defaultProps = {
        exportTopicIndex: false,
    };

    constructor(props) {
        super(props);
        this.getReferences = this.getReferences.bind(this);
        // chances of conflict are pretty much 0, ideally this would import a singleton that it increments each time
        this.selectId = Math.random();
        this.exportAuthorsId = Math.random();
        this.exportCorrelationsId = Math.random();
        this.exportYearId = Math.random();
        this.exportDOIId = Math.random();
        this.state = { format: "bibtex" };
        this.onFormatChange = this.onFormatChange.bind(this);

        formatToFormatMap.text = (articles) => this.articlesToListOfLists(articles).map(a => a.join(" ")).join("\n");
        formatToFormatMap.csv = (articles) => `\uFEFF${stringify(this.articlesToListOfLists(articles))}`;
    }

    componentDidUpdate(prevProps) {
        const { show } = this.props;

        if (show && !prevProps.show) {
            const format = document.getElementById(this.selectId);

            format.addEventListener("change", this.onFormatChange);
        }
    }

    componentWillUnmount() {
        const format = document.getElementById(this.selectId);

        if (format) {
            format.removeEventListener("change", this.onFormatChange);
        }
    }

    onFormatChange(event) {
        this.setState({ format: event.target.value });
    }

    /**
     * Starts a download for a random reference..
     */
    getReferences() {
        const { articles, done } = this.props;
        const format = document.getElementById(this.selectId).value;
        /*
        fetch(`${consts.api}/citation/?ids=${this.props.ids.join(",")}&simple=false`, {
            method: "GET",
            mode: "cors",
        }).then((response) => {
            if (response.status === 200) {
                return response.json();
            }
            throw response.error;
        }).then((json) => {
            const text = formatToFormatMap[format](json.citations);
            fileDownload(text, formatToFileNameMap[format]);
        }).catch(console.error);
        this.props.done();
        */
        const text = formatToFormatMap[format].call(this, articles);
        fileDownload(text, formatToFileNameMap[format], formatToTypeMap);
        done();
    }

    articlesToListOfLists(articles) {
        const { exportTopicIndex, topics } = this.props;

        const exportAuthors = document.getElementById(this.exportAuthorsId).checked;
        const exportCorrelations = document.getElementById(this.exportCorrelationsId).checked;
        const exportDOI = document.getElementById(this.exportDOIId).checked;
        const exportYear = document.getElementById(this.exportYearId).checked;

        const titles = [];

        if (exportAuthors) {
            titles.push("First Author Last name");
        }

        if (exportDOI) {
            titles.push("DOI");
        }

        if (exportYear) {
            titles.push("Year");
        }

        titles.push("title");

        if (exportCorrelations) {
            if (exportTopicIndex !== false) {
                titles.push(topicToKeywords(topics[exportTopicIndex]));
            }
            titles.push(...topics.map(topicToKeywords));
        }

        const rows = articles.map(article => {
            const info = [];

            if (exportAuthors) {
                if (article.authors && article.authors.length > 0) {
                    const author = article.authors[0];
                    if (author.includes(",")) {
                        info.push(author.split(",")[0].trim());
                    } else {
                        info.push("");
                    }
                } else {
                    info.push("");
                }
            }

            if (exportDOI) {
                if (article.doi) {
                    info.push(article.doi);
                } else {
                    info.push("");
                }
            }

            if (exportYear && article.createdAt) {
                const createdDate = new Date(article.createdAt);
                if (createdDate) {
                    const createdYear = createdDate.getFullYear();
                    info.push(createdYear);
                } else {
                    info.push("");
                }
            }

            info.push(article.title);

            if (exportCorrelations) {
                if (exportTopicIndex !== false) {
                    info.push(article.correlations[exportTopicIndex]);
                }
                info.push(...article.correlations);
            }

            return info;
        });

        return [titles, ...rows];
    }

    render() {
        const { format } = this.state;
        const { show, done } = this.props;

        const showCheckMarks = (format === "text" || format === "csv");

        return (
            <SweetAlert
                show={show}
                title="Export Citations"
                text={renderToStaticMarkup((
                    <div>
                        <p>Select an export format</p>
                        <br />
                        <select id={`${this.selectId}`} value={format}>
                            {/* can't target using refs because of the renderToStaticMarkup */}
                            <option value="bibtex">BibTex</option>
                            {/* <option value="rtf">RTF</option>
                            <option value="tagged">Tagged</option>
                            <option value="marc">MARC</option>
                             */}
                            <option value="ris">RIS</option>
                            <option value="text">Text List of Titles And Relation Strength</option>
                            <option value="csv">Spreadsheet List of Titles And Relation Strength</option>
                        </select>
                        {showCheckMarks
                            ? (
                                <div>
                                    <br />
                                    <label htmlFor={this.exportAuthorsId}>
                                        <input
                                            style={{ display: "block" }}
                                            type="checkbox"
                                            id={this.exportAuthorsId}
                                        />
                                        Export Authors
                                    </label>
                                    <br />
                                    <label htmlFor={this.exportDOIId}>
                                        <input
                                            style={{ display: "block" }}
                                            type="checkbox"
                                            id={this.exportDOIId}
                                        />
                                        Export DOI
                                    </label>
                                    <br />
                                    <label htmlFor={this.exportCorrelationsId}>
                                        <input
                                            style={{ display: "block" }}
                                            type="checkbox"
                                            id={this.exportCorrelationsId}
                                        />
                                        Export Correlations
                                    </label>
                                    <br />
                                    <label htmlFor={this.exportYearId}>
                                        <input
                                            style={{ display: "block" }}
                                            type="checkbox"
                                            id={this.exportYearId}
                                        />
                                        Export Year
                                    </label>
                                </div>)
                            : ""}
                    </div>))}
                html
                type="info"
                showCancelButton
                showLoaderOnConfirm
                onCancel={() => done()}
                onConfirm={this.getReferences}
            />);
    }
}

export default CitationExport;
