/* eslint-disable react/jsx-curly-brace-presence */
import React from "react";
import PropTypes from "prop-types";
import { flat } from "../../../utils";

import { chartTable } from "../index.module.css";

function makeAuthorRows(authorsAndCounts) {
    return authorsAndCounts
    // eslint-disable-next-line no-unused-vars
        .sort(([propertyEntryA, countA], [PropertyEntryB, countB]) => countB - countA)
        .map(([propertyEntry, count]) => (
            <tr key={propertyEntry}>
                <td>{propertyEntry}</td>
                <td>{count}</td>
            </tr>));
}

export default function Authors({ data }) {
    const authorsAndCounts = Object.entries(flat(data.submissions
        .map(article => article.authors))
        .reduce((acc, propertyEntry) => {
            acc[propertyEntry] = (acc[propertyEntry] || 0) + 1;
            return acc;
        }, {}));
    const authorsAndRank = Object.entries(flat(data.submissions
        .map(article => article.authors.map((author, index) => [author, index])))
        .reduce((acc, [author, rank]) => {
            const authorObject = acc[author] || {};
            authorObject[rank] = (authorObject[rank] || 0) + 1;
            acc[author] = authorObject;
            return acc;
        }, {}))
        .map(([author, ranks]) => [author, { ranks, total: Object.values(ranks).reduce((a, b) => a + b) }])
        .filter(([, meta]) => meta.total > 1)
        .sort(([, { total: totalA }], [, { total: totalB }]) => totalB - totalA)
        .map(([author, meta]) => (
            <React.Fragment key={author}>
                <tr>
                    <th>{author}</th>
                    <th>{meta.total}</th>
                </tr>
                {Object.entries(meta.ranks)
                    .sort(([indexA], [indexB]) => parseInt(indexA, 10) - parseInt(indexB, 10))
                    .map(([index, count]) => (
                        <tr key={index}>
                            <td>{parseInt(index, 10) + 1}st Author</td>
                            <td>{count}</td>
                        </tr>))}
            </React.Fragment>));

    const authorsAndYear = Object.entries(flat(data.submissions
        .map(article => {
            const year = new Date(article.createdAt).getFullYear();
            return article.authors.map((author) => [author, year]);
        }))
        .reduce((acc, [author, year]) => {
            const authorObject = acc[author] || {};
            authorObject[year] = (authorObject[year] || 0) + 1;
            acc[author] = authorObject;
            return acc;
        }, {}))
        .map(([author, years]) => [author, { years, total: Object.values(years).reduce((a, b) => a + b) }])
        .filter(([, meta]) => meta.total > 1)
        .sort(([, { total: totalA }], [, { total: totalB }]) => totalB - totalA)
        .map(([author, meta]) => (
            <React.Fragment key={author}>
                <tr>
                    <th>{author}</th>
                    <th>{meta.total}</th>
                </tr>
                {Object.entries(meta.years)
                    .sort(([indexA], [indexB]) => parseInt(indexB, 10) - parseInt(indexA, 10))
                    .map(([index, count]) => (
                        <tr key={index}>
                            <td>{index}</td>
                            <td>{count}</td>
                        </tr>))}
            </React.Fragment>));
    return (
        <div style={{ height: "90%", overflow: "auto", display: "flex" }}>
            <table className={chartTable}>
                <thead>
                    <tr>
                        <th>All Contributors</th>
                        <th>&nbsp;</th>
                    </tr>
                    <tr>
                        <th>Author</th>
                        <th>Contributions</th>
                    </tr>
                </thead>
                <tbody>
                    {makeAuthorRows(authorsAndCounts)}
                </tbody>
            </table>
            <table className={chartTable}>
                <thead>
                    <tr>
                        <th>Repeat Contributors with Rank</th>
                        <th>&nbsp;</th>
                    </tr>
                    <tr>
                        <th>Authors</th>
                        <th>Contributions</th>
                    </tr>
                </thead>
                <tbody>
                    {authorsAndRank}
                </tbody>
            </table>
            <table className={chartTable}>
                <thead>
                    <tr>
                        <th>Repeat Contributors by Year</th>
                        <th>&nbsp;</th>
                    </tr>
                    <tr>
                        <th>Authors</th>
                        <th>Contributions</th>
                    </tr>
                </thead>
                <tbody>
                    {authorsAndYear}
                </tbody>
            </table>
        </div>
    );
}

Authors.propTypes = {
    data: PropTypes.shape({
        submissions: PropTypes.array.isRequired,
        topics: PropTypes.array.isRequired,
        query: PropTypes.object.isRequired,
    }).isRequired,
};
