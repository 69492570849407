import React, { Component } from "react";
import PropTypes from "prop-types";
import IssueSelector from "../../Components/IssueSelector";
import { issuesProp } from "../../consts";
import { genIDs } from "../../utils";
import searchPageStyles from "../index.module.css";

export default class SelectIssue extends Component {
    static propTypes = {
        defaultsAndQuery: PropTypes.object.isRequired,
        updateQuery: PropTypes.func.isRequired,
        issues: issuesProp.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { uniqueIds: genIDs("selectIssue") };
    }

    render() {
        const { uniqueIds } = this.state;
        const { defaultsAndQuery, updateQuery, issues } = this.props;

        return (
            // eslint-disable-next-line jsx-a11y/label-has-for,jsx-a11y/label-has-associated-control
            <label htmlFor={uniqueIds.selectIssue}>
                <h6 className={searchPageStyles.boxedTitleSub}>By Issue
                    <button
                        type="button"
                        onClick={() => this.clearSelector()}
                    >
                        clear
                    </button>
                </h6>
                <div>
                    <small>Only include articles that were published in these issues</small>
                    <br />
                    <IssueSelector
                        id={uniqueIds.selectIssue}
                        issues={issues}
                        range={defaultsAndQuery.dateRange}
                        clearSelector={
                            clearSelector => {
                                this.clearSelector = clearSelector;
                            }
                        }
                        onChange={newIssues => {
                            updateQuery("issues", newIssues.join(","));
                        }}
                    />
                </div>
                <br />
            </label>
        );
    }
}
